import {
  Box,
  Flex,
  HStack,
  Image,
  Link as ChakraLink,
  Button,
  Spacer,
  useColorModeValue,
  StackDivider,
  Text,
  Avatar,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useMediaQuery,
  MenuDivider,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { logo } from '../../assets/images';
import { NavDropdown } from '../elements/NavDropdown';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import '../../allcss/header.css';
import { CustomNavLink } from '../elements/CustomNavLink ';
import { BsCart4 } from 'react-icons/bs';
import AuthContext from '../../context/AuthContext';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import CartContext from '../../context/CartContext';
import WishListContext from '../../context/WishListContext';
import CourseContext from '../../context/CourseListContext';

const categories = [
  {
    label: 'Programming',
    subCategories: [
      'Programming languages',
      'Web Development',
      'Mobile Development',
      'Game Development',
      'Data Science',
      'System Programming',
    ],
  },
  {
    label: 'Design',
    subCategories: ['Graphic Design', 'UI/UX Design', 'Web Design'],
  },
  {
    label: 'Business',
    subCategories: ['Management', 'Finance', 'Marketing'],
  },
  {
    label: 'Science',
    subCategories: ['Biology', 'Physics', 'Chemistry'],
  },
  {
    label: 'Arts',
    subCategories: ['Music', 'Photography', 'Writing'],
  },
  {
    label: 'Personal Development',
    subCategories: ['Leadership', 'Productivity', 'Career Development'],
  },
  {
    label: 'Health & Fitness',
    subCategories: ['Fitness', 'Nutrition', 'Mental Health'],
  },
  {
    label: 'Language Learning',
    subCategories: ['English', 'Spanish', 'Mandarin Chinese'],
  },
];

export const Header = ({ startTour }) => {
  const { getWishList } = useContext(WishListContext);
  const { getcart, unAuthCartItems, cartItems } = useContext(CartContext);
  const { courseId, setCourseId, setCourseData } = useContext(CourseContext);
  const user = 'user';
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isTab] = useMediaQuery('(max-width: 992px)');
  const location = useLocation();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  const unAuthItem = localStorage.getItem('unAuthCartItems');

  useEffect(() => {
    if (cartItems) {
      console.log('cartItems', cartItems);
    }
  }, [cartItems]);

  useEffect(() => {
    if (unAuthCartItems) {
      console.log('unAuthCartItems', unAuthCartItems);
    }
  }, [unAuthCartItems]);

  useEffect(() => {
    console.log(
      'token available, nothing in the auth cart local storage',
      unAuthItem
    );
    if (token && !unAuthItem) {
      console.log('token available, nothing in the auth cart local storage');
      getcart();
    }
  }, [token]);
  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const dp = localStorage.getItem('dp');
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const { handelSignOut, products } = useContext(AuthContext);
  // console.log(`${BACKEND_URL}${dp}`)
  const cartIconSize = '50px';
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const color = useColorModeValue('gray.800', 'white');
  const handleCart = () => {
    console.log('xxx', location.pathname);
    if (location.pathname != '/cart') {
      navigate('/cart');
    }
    getcart();
  };

  const handleWishList = () => {
    navigate('/wishList');
    getWishList();
  };
  const [groupedProducts, setGroupedProducts] = useState({});

  // useEffect(() => {
  //   if (products && products.length > 0) {
  //     const grouped = products.reduce((acc, product) => {
  //       if (!acc[product.category]) {
  //         acc[product.category] = {};
  //       }
  //       if (!acc[product.category][product.subCategory]) {
  //         acc[product.category][product.subCategory] = [];
  //       }
  //       acc[product.category][product.subCategory].push(product);
  //       return acc;
  //     }, {});
  //     setGroupedProducts(grouped);
  //   }
  // }, [products]);

  useEffect(() => {
    if (groupedProducts) console.log('groupedProducts', groupedProducts);
  }, [groupedProducts]);

  const handleCourseClick = id => {
    console.log(id);
    setCourseData(null);
    setCourseId(id);

    navigate('/indi_course');
  };

  // const isValidCategory = category => {
  //   return category && category !== '' && category !== 'undefined';
  // };
  // const [groupedProducts, setGroupedProducts] = useState({});
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubCategory, setActiveSubCategory] = useState(null);

  useEffect(() => {
    const grouped = categories.reduce((acc, category) => {
      acc[category.label] = category.subCategories.reduce(
        (subAcc, subCategory) => {
          subAcc[subCategory] = products?.filter(
            course => course.subCategory === subCategory
          );
          return subAcc;
        },
        {}
      );
      return acc;
    }, {});

    setGroupedProducts(grouped);
  }, [products]);

  const handleCategoryClick = category => {
    setActiveCategory(category);
    setActiveSubCategory(null);
  };

  const handleSubCategoryClick = subCategory => {
    setActiveSubCategory(subCategory);
  };

  const isValidCategory = category =>
    Object.keys(groupedProducts).includes(category);

  return (
    <Box
      color={color}
      px={{
        base: 2,
        sm: 2,
        md: 2,
        lg: 4,
      }}
      py={3}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <HStack
          spacing={{
            base: 1,
            sm: 1,
            md: 4,
            lg: 4,
          }}
        >
          <Image
            src={logo}
            alt="Snappify Logo"
            boxSize="50px"
            onClick={() => navigate('/')}
            cursor={'pointer'}
          />
          {isMobile ? (
            <Heading>SOLP</Heading>
          ) : (
            <Heading
              fontSize={{
                base: '15px',
                md: '20px',
                lg: '27px',
              }}
            >
              {isTab ? 'SOLP' : 'STEPS OF LEARNING PROCESS'}
            </Heading>
          )}
        </HStack>

        <Spacer />

        <HStack spacing={4}>
          {isMobile
            ? null
            : location.pathname !== '/' && <CustomNavLink to="/" text="Home" />}

          {isMobile ? null : (
            <>
              <Menu>
                <MenuButton className="category">Category</MenuButton>
                <MenuList
                  placement="right-start"
                  h={'80vh'}
                  borderRadius={0}
                  zIndex={999}
                >
                  {Object.keys(groupedProducts)
                    .filter(isValidCategory)
                    .map(category => (
                      <MenuItem key={category}>
                        <Menu className="headerMenu">
                          <MenuButton
                            onClick={e => e.stopPropagation()}
                            className="categoryMenuBtn"
                          >
                            {category} <ChevronRightIcon />
                          </MenuButton>
                          <MenuList
                            className="submenu"
                            h={'80vh'}
                            borderRadius={0}
                          >
                            {Object.keys(groupedProducts[category]).map(
                              subCategory => (
                                <MenuItem key={subCategory}>
                                  <Menu className="headerMenu">
                                    <MenuButton
                                      onClick={e => e.stopPropagation()}
                                      className="categoryMenuBtn"
                                    >
                                      {subCategory} <ChevronRightIcon />
                                    </MenuButton>
                                    <MenuList
                                      className="submenu"
                                      h={'80vh'}
                                      borderRadius={0}
                                    >
                                      {groupedProducts[category][subCategory]
                                        ?.length > 0 ? (
                                        groupedProducts[category][
                                          subCategory
                                        ].map(course => (
                                          <MenuItem
                                            key={course._id}
                                            onClick={() =>
                                              handleCourseClick(course._id)
                                            }
                                          >
                                            {course.courseName}
                                          </MenuItem>
                                        ))
                                      ) : (
                                        <MenuItem>Coming soon ...</MenuItem>
                                      )}
                                    </MenuList>
                                  </Menu>
                                </MenuItem>
                              )
                            )}
                          </MenuList>
                        </Menu>
                      </MenuItem>
                    ))}
                </MenuList>
              </Menu>
              <CustomNavLink to="/products" text="Products" />
              <CustomNavLink
                to="/subscription_plans"
                text="Subscription Plans"
              />
              {/* <CustomNavLink to="/" text="About Us" /> */}
            </>
          )}
        </HStack>
        <Spacer />
        <HStack
          justifyContent={'flex-end'}
          spacing={4}
          w={{
            base: '55%',
            sm: '55%',
            md: '30%',
            lg: '18%',
          }}
        >
          {/* Cart Icon */}
          <Box
            borderRadius="full"
            fontSize="40px"
            color="gray.500"
            cursor={'pointer'}
            onClick={handleCart}
            position="relative"
          >
            <BsCart4 />
            <Box
              position={'absolute'}
              top="0%"
              left="41%"
              width={'23px'}
              // bottom="20%"
              textAlign="center"
              color="white"
              fontWeight="bold"
              bg="red"
              borderRadius="50px"
              fontSize="16px" // Adjust the font size as needed
              cursor={'pointer'}
              onClick={handleCart}
            >
              {(cartItems && Array.isArray(cartItems)
                ? cartItems?.length
                : 0) ||
                (unAuthCartItems && Array.isArray(unAuthCartItems)
                  ? unAuthCartItems.length
                  : 0)}
            </Box>
          </Box>
          {isMobile
            ? null
            : isTab
            ? null
            : token && (
                <CustomNavLink
                  to={
                    role === 'admin' ? '/admin/home' : '/dashboard'
                    // location.pathname === '/dashboard'
                    //   ? '/dashboard'
                    //   : '/admin/home'
                  }
                  text="Dashboard"
                />
              )}
          {token ? (
            <Menu isOpen={isOpen} zIndex={1000} onClose={onClose}>
              <MenuButton onClick={isOpen ? onClose : onOpen}>
                {/* User avatar */}
                <Avatar
                  size="md"
                  name={localStorage.getItem('name')}
                  src={`${BACKEND_URL}${dp}`}
                />
              </MenuButton>
              <MenuList zIndex={999}>
                {isMobile && (
                  <>
                    <MenuItem onClick={onClose}>
                      <NavLink to={'/bioDetails'}>Profile</NavLink>
                    </MenuItem>
                    <MenuItem onClick={onClose}>
                      <NavLink to={'/products'}>Products</NavLink>
                    </MenuItem>
                    <MenuItem onClick={onClose}>
                      <NavLink to={'/subscription_plans'}>
                        Subscription Plans
                      </NavLink>
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={onClose}>
                      <NavLink to={'/dashboard'}>Dashboard</NavLink>
                    </MenuItem>
                    <MenuItem onClick={onClose}>
                      <NavLink to={'/all_transactions'}>Transactions</NavLink>
                    </MenuItem>
                    <MenuItem onClick={onClose}>
                      <NavLink to={'/bio'}>Bio Details</NavLink>
                    </MenuItem>
                  </>
                )}
                {isTab && !isMobile && (
                  <MenuItem onClick={onClose}>
                    <NavLink to={'/dashboard'}>Dashboard</NavLink>
                  </MenuItem>
                )}
                {role === 'admin' ? (
                  ' '
                ) : (
                  <>
                    <MenuItem onClick={handleWishList}>WishList</MenuItem>

                    {location.pathname === '/dashboard' && !isMobile ? (
                      <MenuItem onClick={startTour}>Take Tour</MenuItem>
                    ) : (
                      ''
                    )}
                    <MenuDivider />
                  </>
                )}
                <MenuItem _hover={{ bg: 'transparent' }}>
                  <Button colorScheme="blue" size="sm" onClick={handelSignOut}>
                    Logout
                  </Button>
                </MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <HStack>
              <Button
                colorScheme="teal"
                variant="outline"
                fontSize={'17px'}
                onClick={() => navigate('/login')}
                p={{
                  base: '7px',
                  sm: '7px',
                  md: '16px',
                  lg: '16px',
                }}
              >
                Login
              </Button>
              <Button
                colorScheme="teal"
                variant="solid"
                fontSize={'17px'}
                onClick={() => navigate('/sign_up')}
                p={{
                  base: '4px',
                  sm: '4px',
                  md: '16px',
                  lg: '16px',
                }}
              >
                Sign Up
              </Button>
            </HStack>
          )}
          {/* Number above the cart */}
        </HStack>
      </Flex>
    </Box>
  );
};
