import { Text, VStack,HStack, FormLabel,Divider } from '@chakra-ui/react'
import React, { useContext, useEffect } from 'react'
import CartContext from '../../../context/CartContext'

function AboutSubscription() {
  const {aboutSubscription} =useContext(CartContext)


  return (
    <>
      {

        aboutSubscription && aboutSubscription.name ? (
          <>
            <Text>
              <strong> Name : </strong> {aboutSubscription.name}
            </Text>
            <FormLabel mt={4}>Courses :</FormLabel>
            <Divider orientation="horizontal" w={"40%"} />
            {
              aboutSubscription.courses && Array.isArray(aboutSubscription.courses)
              &&
              aboutSubscription.courses.map((el, i) => (
                <>
                
                <HStack alignItems={'centers'} key={i} >
                <VStack alignItems={'flex-start'}>
                <Text>{i+1} .</Text>
                </VStack>
              <VStack alignItems={'flex-start'}>
              <Text><strong> courseName : </strong>{el.courseName}</Text>
                
                
                </VStack>
                </HStack>
                </>
              ))

            }
            <FormLabel mt={4}>Plans :</FormLabel>
            <Divider orientation="horizontal" w={"40%"} />
            {
              aboutSubscription.plan && Array.isArray(aboutSubscription.plan)
              &&
              aboutSubscription.plan.map((el, i) => (
                <>
                
                <HStack alignItems={'centers'} key={i} >
                <VStack alignItems={'flex-start'}>
                <Text>{i+1} .</Text>
                </VStack>
              <VStack alignItems={'flex-start'}>
              <Text><strong> duration : </strong>{el.duration}</Text>
                <Text><strong> offerPrice : </strong>{el.offerPrice}</Text>
                <Text><strong> originalPrice : </strong>{el.originalPrice}</Text>
                <Text><strong> renewalPrice : </strong>{el.renewalPrice}</Text>
                <Text><strong> bufferDays : </strong>{el.bufferDays}</Text>
                </VStack>
                </HStack>
                </>
              ))

            }
          </>
        ) : (
          <Text>select any Subscription</Text>
        )}
    </>
  )
}

export default AboutSubscription