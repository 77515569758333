import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Text, Link as ChakraLink } from '@chakra-ui/react';

export const CustomNavLink = ({ to, text }) => {
  return (
    <>
      <RouterNavLink
        to={to}
        as={ChakraLink} // Use Chakra UI Link with react-router-dom NavLink functionality
        _hover={{ textDecoration: 'none' }}
      >
        <Text
          fontWeight={600}
          fontSize="17px"
          position="relative"
          _hover={{
            ':after': {
              content: "''",
              position: 'absolute',
              bottom: -2,
              left: 0,
              width: '0%',
              height: 1,
              backgroundColor: '#000',
              animation: 'widthAnimation 0.6s ease-in-out forwards', // Use keyframes for smoother animation
            },
            ':hover:after': {
              animation: 'widthAnimationHover 0.6s ease-in-out forwards',
            },
          }}
        >
          {text}
        </Text>
      </RouterNavLink>
    </>
  );
};
