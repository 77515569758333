import React from 'react';
import {
  Box,
  Text,
  Heading,
  List,
  ListItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  VStack,
  Flex,
  ListIcon,
} from '@chakra-ui/react';
import { MdOutlineDoubleArrow } from 'react-icons/md';
import privacyPolicy, { tableOfContents } from '../../data/privacy_policy';

const PrivacyPolicy = () => {
  return (
    <Box p={5}>
      <Text mb={4}>
        Welcome to "Steps of Learning Process”! We esteem you believe and are
        committed to ensuring your individual data. This Data Privacy Policy
        explains how we collect, use, disclose, and safeguard your data when you
        use our online courses platform. Please read this policy carefully to
        understand how we handle your data.
      </Text>
      <Text mb={4}>
        By accessing or using "Steps of Learning Process," you consent to the
        practices described in this Data Privacy Policy. If you do not agree
        with these practices, please do not use our platform.
      </Text>



      <Box mx={{
        base: 0,
        sm: 0,
        md: '3rem',
        lg: '5rem'
      }}>
        {privacyPolicy &&
          privacyPolicy.map((el, i) => (
            <React.Fragment key={i}>
              <Heading as="h4" size="md" id={el.heading.id} mb={2}>
                {el.heading.text}
              </Heading>
              <List spacing={2} mb={4} ml={10}>
                {el.list.map((item, subIndex) =>
                  typeof item === 'string' ? (
                    <ListItem key={subIndex}>
                      <ListIcon as={MdOutlineDoubleArrow} color="blue.500" />
                      {item}
                    </ListItem>
                  ) : (
                    <ListItem key={subIndex}>
                      <Heading as="h5" size="sm" mb={2}>
                        {' '}
                        - {item.subHeading.text}
                      </Heading>
                      <List spacing={2} mb={4} ml={5}>
                        {item.list.map((subItem, listIndex) => (
                          <ListItem key={listIndex}>
                            <ListIcon
                              as={MdOutlineDoubleArrow}
                              color="blue.500"
                            />
                            {subItem}
                          </ListItem>
                        ))}
                      </List>
                    </ListItem>
                  )
                )}
              </List>
            </React.Fragment>
          ))}
      </Box>

      <Text>
        Thank you for trusting us with your personal information. Our commitment
        is steadfast to protecting your privacy and providing you with the best
        online learning experience.
      </Text>
    </Box>
  );
};

export default PrivacyPolicy;
