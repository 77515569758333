import {
  Container,
  Box,
  Flex,
  Input,
  Button,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  HStack,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import EnvContext from '../context/EnvContext';
import toast from 'react-hot-toast';
import axios from 'axios';

function ValidateOTP() {
  const genOtpEmail = localStorage.getItem('genOtpEmail');
  const navigate = useNavigate();
  const { backendUrl } = useContext(EnvContext);
  const handleSubmit = async values => {
    const postData = {
      email: genOtpEmail,
      otp: values.otp,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Checking OTP', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });

      await axios.post(`${backendUrl}/validate_otp`, postData).then(res => {
        console.log('res', res);
        toast.success(res.data.message, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
        navigate('/change_password');
      });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };
  const handleBack = ()=>{
    navigate('/generate_otp')
  }
  return (
    <Container
      maxW="lg"
      py={{
        base: '2',
        md: '5',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
    <Stack textAlign="center">
        <Heading fontSize={'3xl'}> Validate your OTP</Heading>
      </Stack>
      <Box
        w={'100%'}
        py={{
          base: '0',
          sm: '8',
        }}
        px={{
          base: '4',
          sm: '10',
        }}
        bg={{
          base: 'transparent',
          sm: 'bg.surface',
        }}
        boxShadow={{
          base: 'md',
          sm: 'md',
        }}
        borderRadius={{
          base: 'none',
          sm: 'xl',
        }}
      >
        <Stack spacing="6">
          <Stack spacing="5" width={'90%'}>
            <Formik
              initialValues={{
                otp: '',
              }}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Text> <strong>Email :</strong> {genOtpEmail}</Text>
                  <Field name="otp">
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.errors.otp && form.touched.otp}
                      >
                        <FormLabel>OTP</FormLabel>
                        <Input {...field} placeholder="OTP" />
                        <FormErrorMessage>{form.errors.otp}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Stack spacing="6" mt={4}>
                  <HStack>
                    <Button type="button" onClick={handleBack}>
                      Back
                    </Button>
                    <Button type="submit" colorScheme='blue'  isLoading={isSubmitting}>
                      Submit
                    </Button>
                    </HStack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
}

export default ValidateOTP;
