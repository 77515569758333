import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  VStack,
  Stack,
  List,
  ListItem,
  Box,
  HStack,
  Text,
  Divider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useEditable,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  useDisclosure,
  FocusLock,
  ChakraProvider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import CartContext from '../../../context/CartContext';
import { FaPlus } from 'react-icons/fa';
import AuthContext from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const HandleSubscription = () => {
  const {
    subscriptions,
    aboutSubscription,
    getAllSubscription,
    getAboutSubscription,
    ManuallyAddPlan,
  } = useContext(CartContext);
  const { userData,handleUserData } = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [openPopoverIndex, setOpenPopoverIndex] = useState(null);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = React.useRef(null);
  const [planDuration, setPlanDuration] = useState(null);
  useEffect(() => {
    if (userData) {
      console.log('userData', userData);
    }
  }, [userData]);
  const role = localStorage.getItem('role');

  const navigate = useNavigate();

  useEffect(() => {
    if (role !== "admin") navigate('/dashboard');
  }, [role]);

  useEffect(() => {
    if (subscriptionId) {
      console.log('subscriptionId', subscriptionId);
    } else {
      console.log('no subscription');
    }
  }, [subscriptionId]);

  useEffect(() => {
    getAllSubscription();
  }, []);

  useEffect(() => {
    if (subscriptionId) getAboutSubscription(subscriptionId);
  }, [subscriptionId]);
  const handleButtonClick = value => {
    setSelectedTab(value); // Index 2 corresponds to the third tab
  };

  const handlePopoverOpen = index => {
    setOpenPopoverIndex(index === openPopoverIndex ? null : index);
  };

  const handleSubmit = e => {
    e.preventDefault();
    // console.log("planDuration",planDuration ,'userData',userData._id ,'subscriptionId',subscriptionId)
    ManuallyAddPlan(subscriptionId, userData._id, planDuration).then(()=>{
      handleUserData(userData._id);
    })
  };
  const formatDate = dateString => {
    if (!dateString) return ''; // Return empty string if date string is not provided

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    // Get the date in YYYY-MM-DD format
    return date.toISOString().split('T')[0];
  };
  return (
    <>
      <HStack alignItems={'center'} p={5} minH={'100vh'} h={'100vh'}>
        <VStack
          width={'60%'}
          borderRight={'1px solid'}
          borderColor={'gray.100'}
          px={'1rem'}
        >
          <Text fontWeight={700} fontSize={'large'}>
            Active Subscriptions
          </Text>
          <TableContainer width={'100%'}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th w={'2rem'}>Sl No</Th>
                  <Th>Plan Name</Th>
                  <Th>Added date</Th>
                  <Th>Plan Duration</Th>
                </Tr>
              </Thead>
              <Tbody>
                {userData.subscriptions &&
                  Array.isArray(userData.subscriptions) &&
                  userData.subscriptions.length > 0 &&
                  userData.subscriptions.map((el, i) => {
                    const subscription = subscriptions.find(
                      sub => sub._id === el.plan
                    );
                    if (subscription) {
                      return (
                        <Tr key={i}>
                          <Td>{i + 1}</Td>
                          <Td>{subscription.name}</Td>
                          <Td>{formatDate(el.addedDate)}</Td>
                          <Td>{el.planDuration} months</Td>
                        </Tr>
                      );
                    } else {
                      return null; // If there's no matching subscription, don't display anything
                    }
                  })}
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>
        <VStack w={'40%'}>
          <Text fontWeight={700} fontSize={'large'}>
            Available Subscriptions
          </Text>

          {subscriptions &&
          Array.isArray(subscriptions) &&
          subscriptions.length > 0 ? (
            subscriptions.map((el, i) => (
              <>
                <Box
                  bg={el._id === subscriptionId && 'blue.100'}
                  p={1}
                  w={'100%'}
                  _hover={{ bg: 'blue.100' }}
                >
                  <HStack
                    justifyContent={'space-between'}
                    w={'95%'}
                    cursor={'pointer'}
                    key={i}
                    onClick={() => {
                      setSubscriptionId(el._id);
                      // getAboutCoupon(el.code)
                    }}
                  >
                    <HStack onClick={() => handleButtonClick(0)} w={'100%'}>
                      <Text>{i + 1}.</Text>
                      <Text>{el.name}</Text>
                    </HStack>
                    <Popover
                      isOpen={openPopoverIndex === i}
                      onOpen={() => handlePopoverOpen(i)}
                      onClose={() => handlePopoverOpen(i)}
                      initialFocusRef={firstFieldRef}
                      placement="right"
                      closeOnBlur={false}
                    >
                      <PopoverTrigger>
                        <IconButton
                          icon={<FaPlus />}
                          aria-label="Edit"
                          colorScheme="gray"
                          ml={2}
                          // onClick={()=>ManuallyAddPlan()}
                        />
                      </PopoverTrigger>
                      <PopoverContent p={5}>
                        <FocusLock returnFocus persistentFocus={false}>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <ChakraProvider>
                            <VStack spacing={4} width="300px">
                              <form onSubmit={handleSubmit} >
                                <FormLabel>Plan Duration :</FormLabel>
                                <HStack w={'100%'} mb={3}>
                                  <Menu>
                                    <MenuButton
                                      as={Button}
                                      rightIcon={<ChevronDownIcon />}
                                    >
                                      {planDuration
                                        ? `${planDuration} months`
                                        : 'Select Monthly Plan'}
                                    </MenuButton>
                                    <MenuList>
                                      {aboutSubscription.plan &&
                                        Array.isArray(aboutSubscription.plan) &&
                                        aboutSubscription.plan.map((el, i) => (
                                          <>
                                            <MenuItem
                                              key={i}
                                              onClick={() => {
                                                setPlanDuration(el.duration);
                                              }}
                                            >
                                              {el.duration}
                                            </MenuItem>
                                          </>
                                        ))}
                                    </MenuList>
                                  </Menu>
                                </HStack>

                                <Button type="submit" colorScheme="blue" mt={4}>
                                  Submit
                                </Button>
                              </form>
                            </VStack>
                          </ChakraProvider>
                        </FocusLock>
                      </PopoverContent>
                    </Popover>
                  </HStack>
                </Box>
              </>
            ))
          ) : (
            <Text> No Subscription plan </Text>
          )}
        </VStack>
      </HStack>
    </>
  );
};

export default HandleSubscription;
