import {Box, HStack, Heading, Text, VStack,List,ListItem,ListIcon } from '@chakra-ui/react'
import React from 'react'
import { dummyAbout } from '../assets/images'
import { MdOutlineDoubleArrow } from 'react-icons/md';
import courseFeatures from '../data/about_us';
import Lottie from 'react-lottie';
import aboutusannimation from '../assets/annimation/AnnimationAboutUs.json'

function AboutUs() {
  const getDefaultOptions = animationData => {
    let defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return defaultOptions;
  };
  return (
    <>
      <VStack justifyContent={'space-around'} w={'100%'} p={10} alignItems={'flex-start'} gap={'2.5rem'} 
  
       >
      
        <HStack   alignItems={'flex-start'} w={'100%'} gap={"2.5rem"} 
            flexDirection={{
        base:'column-reverse',
        sm:'column-reverse',
        md:'column-reverse',
        lg:'row',
      }}
         >

          <VStack gap={"1.5rem"} 
          w={{
            base:"100%",
            sm:"100%",
            md:"100%",
            lg:"55%",
          }}
          >
          <Heading>
            Building greater futures through innovation and collective knowledge
          </Heading>
          <Text textAlign={'justify'}>
          Welcome to "Steps of Learning Process" ! We are the premier platform for online learning, with a specialization in coding and programming. As a subsidiary of <a href="https://www.dayacs.com/" target="_blank" style={{color: "rgb(97, 97, 245)",fontWeight: 'bold'}}  >Daya Consultancy Services PVT. LTD.</a>, we benefit from the extensive expertise and experience of our parent company while maintaining our own unique identity and specialization with our following GST no - <a style={{color: "rgb(97, 97, 245)",fontWeight: 'bold'}}>21AAICD8098A1ZT</a>. Our unique approach to education includes real-time live project work, providing students with hands-on experience. With a diverse range of online courses, we empower learners to master the latest technologies and skills. Join us on this journey of continuous learning and skill development.
          </Text>
          </VStack>
          {/* <img src={dummyAbout}></img> */}
          <Box 
            w={{
            base:"100%",
            sm:"100%",
            md:"100%",
            lg:"45%",
          }}>
            <Lottie options={getDefaultOptions(aboutusannimation)} />
          </Box>
        </HStack>
        <HStack >
          <List lineHeight ={'1.8rem'} >
          {
            courseFeatures && courseFeatures.map((item,i)=>(
              <ListItem key={i} >
          <ListIcon as={MdOutlineDoubleArrow} color="blue.500" /> <strong>{item.title} - </strong>
          {item.description}
          </ListItem>
            ))
          }
          </List>
        </HStack>
      </VStack>
    </>
  )
}

export default AboutUs