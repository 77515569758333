import React, { useContext, useEffect, useState } from 'react';
import {
  VStack,
  HStack,
  Text,
  Box,
  Tooltip,
  Tag,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
} from '@chakra-ui/react';
import { TiTick } from 'react-icons/ti';
import { PiVideoLight } from 'react-icons/pi';
import { MdQuiz } from 'react-icons/md';
import ContentContext from '../../../../context/ContentContext';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { QuizModal } from '../../../../components/user/modals/QuizModal';
import AssignmentContext from '../../../../context/AssignmentContext';
import { CgNotes } from 'react-icons/cg';

export const CustomCard = React.forwardRef(({ children, ...rest }, ref) => (
  <Tag ref={ref} {...rest} p={0} marginRight={3} style={{ '--tag-bg': 'none' }}>
    {children}
  </Tag>
));
export const VideoList = ({ setShowLike, setNotesClicked }) => {
  const {
    contents,
    fetchEncryptedContent,
    setDecryptedText,
    decryptedText,
    setContentId,
    contentId,
    setVideoDuration,
    setApiCalled,
    scorecard,
    setDecryptedVideo,
    setIndex,
    sethasMarkAsRead,
  } = useContext(ContentContext);
  const { getAllQuestions, setQueResult, setPrevAttempts } =
    useContext(AssignmentContext);
  const [isTrue, setIsTrue] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [arrayOfIds, setArrayOfIds] = useState([]);
  useEffect(() => {
    if (scorecard && Array.isArray(scorecard.watchedContents))
      setArrayOfIds(scorecard.watchedContents.map(item => item._id));
  }, [scorecard]);

  // useEffect(() => {
  //   if (arrayOfIds) console.log('arrayOfIds', arrayOfIds);
  // }, [arrayOfIds]);
  useEffect(() => {
    if (contents) console.log('video list contents', contents);
  }, [contents]);
  return (
    <>
      <VStack
        alignItems="flex-start"
        width={'100%'}
        gap={'0.3rem'}
        borderTop={'1px solid'}
        borderColor={'gray.200'}
      >
        {contents && contents.length > 0 ? (
          contents.map((el, i) => (
            <VStack
              key={i}
              px={1}
              pb={2}
              gap={0}
              bg={
                (hoveredIndex === i && 'gray.100') ||
                (contentId === el._id && 'gray.100')
              }
              width={'100%'}
              alignItems="flex-start"
              onMouseEnter={() => setHoveredIndex(i)}
              onMouseLeave={() => setHoveredIndex(null)}
              // style={{
              //   position: 'relative',
              //   zIndex: '10000',
              // }}
            >
              <HStack
                alignContent={'center'}
                width={'100%'}
                justifyContent={'space-between'}
              >
                <HStack
                  cursor={'pointer'}
                  onClick={() => {
                    fetchEncryptedContent(el._id);
                    setIndex(i);
                    setContentId(el._id);
                    setVideoDuration(el.videoDuration);
                    setApiCalled(false);
                    setDecryptedText('');
                    setDecryptedVideo('');
                    setShowLike(false);
                    setNotesClicked(false);
                  }}
                >
                  <Box
                    w="12px"
                    h="12px"
                    borderRadius="50%"
                    bg={arrayOfIds.includes(el._id) ? 'green.400' : 'gray.400'}
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="center"
                  ></Box>
                  <Text fontSize={'large'}>
                    {i + 1}. {el.title}
                  </Text>
                </HStack>
              </HStack>
              {el.videoDuration ? (
                <HStack
                  justifyContent={'space-between'}
                  paddingLeft={'1.8rem'}
                  width={'100%'}
                >
                  <HStack
                    justifyContent={'flex-start'}
                    cursor={'pointer'}
                    onClick={() => {
                      fetchEncryptedContent(el._id);
                      setIndex(i);
                      setContentId(el._id);
                      setVideoDuration(el.videoDuration);
                      setApiCalled(false);
                      sethasMarkAsRead(false);
                      setDecryptedText('');
                      setDecryptedVideo('');
                      setShowLike(false);
                      setNotesClicked(false);
                    }}
                  >
                    <PiVideoLight size={17} />
                    <Text fontSize={'small'}>{el.videoDuration}</Text>
                  </HStack>
                  <HStack gap={2} alignItems={'flex-end'}>
                    {el.notes && (
                      <HStack
                        onClick={() => {
                          console.log('HStack clicked');
                          setDecryptedText(el.notes);
                          setDecryptedVideo('');
                          setIndex(i);
                          setContentId(el._id);
                          setNotesClicked(true);
                        }}
                        style={{ cursor: 'pointer' }} // Add cursor pointer to indicate clickability
                      >
                        <Button colorScheme="teal" variant="outline" size="xs">
                          Notes
                          <CgNotes size={17} style={{ marginLeft: '1px' }} />
                        </Button>
                      </HStack>
                    )}
                    {el.assignment && el.assignment.length > 0 && (
                      <HStack
                        onClick={() => {
                          getAllQuestions(el._id);
                          setContentId(el._id);
                          setQueResult({});
                          setPrevAttempts([]);
                        }}
                      >
                        <QuizModal courseContentId={el._id}>
                          <Button
                            colorScheme="teal"
                            variant="outline"
                            size="xs"
                          >
                            Assignment
                            <MdQuiz size={20} style={{ marginLeft: '1px' }} />
                          </Button>
                        </QuizModal>
                      </HStack>
                    )}
                  </HStack>
                </HStack>
              ) : (
                <HStack
                  justifyContent={'flex-start'}
                  paddingLeft={'1.8rem'}
                  cursor={'pointer'}
                  onClick={() => {
                    fetchEncryptedContent(el._id);
                    setIndex(i);
                    setContentId(el._id);
                    setVideoDuration(el.videoDuration);
                    setApiCalled(false);
                    setDecryptedText('');
                    setDecryptedVideo('');
                    sethasMarkAsRead(false);
                  }}
                >
                  <IoDocumentTextOutline size={16} />
                  <Text fontSize={'small'}>Open Doc</Text>
                </HStack>
              )}
            </VStack>
          ))
        ) : (
          <p>Nothing to show here</p>
        )}
      </VStack>
    </>
  );
};
