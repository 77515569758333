import React, { useContext, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Box,
} from '@chakra-ui/react';
import CouponContext from '../../context/CouponContext';

function AddCoupon() {
  const {addCoupon} =useContext(CouponContext)
  const formatDate = (date) => {
    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
    const day = String(formattedDate.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
};

  const handleSubmit = (values) => {
// const date=formatDate("17-04-2024");
// console.log("date",date)
// console.log('values',values,"expiry",formatDate(values.expiry));

const postData = {
  'code':values.code,
  'expiry':formatDate(values.expiry),
  'percentage':values.percentage,
  'maxDiscount':values.maxDiscount,
}
addCoupon(postData)
  };
  return (
    <Box width={'100%'} p={2}>
      <Formik
        initialValues={{
          code: '',
          expiry: '',
          percentage: '',
          maxDiscount: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Field name="code">
              {({ field }) => (
                <FormControl isInvalid={errors.code && touched.code}>
                  <FormLabel htmlFor="code">Code</FormLabel>
                  <Input {...field} id="code" placeholder="Enter code" />
                  <FormErrorMessage>{errors.code}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="expiry">
              {({ field }) => (
                <FormControl isInvalid={errors.expiry && touched.expiry}>
                  <FormLabel htmlFor="expiry">Expiry</FormLabel>
                  <Input
                    {...field}
                    id="expiry"
                    type="date"
                    placeholder="Select expiry date"
                  
                  />
                  <FormErrorMessage>{errors.expiry}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="percentage">
              {({ field }) => (
                <FormControl
                  isInvalid={errors.percentage && touched.percentage}
                >
                  <FormLabel htmlFor="percentage">Percentage</FormLabel>
                  <Input
                    {...field}
                    id="percentage"
                    type="number"
                    placeholder="Enter percentage"
                  />
                  <FormErrorMessage>{errors.percentage}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="maxDiscount">
              {({ field }) => (
                <FormControl
                  isInvalid={errors.maxDiscount && touched.maxDiscount}
                >
                  <FormLabel htmlFor="maxDiscount">Max Discount</FormLabel>
                  <Input
                    {...field}
                    id="maxDiscount"
                    type="number"
                    placeholder="Enter max discount"
                  />
                  <FormErrorMessage>{errors.maxDiscount}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Button
              mt={4}
              colorScheme="teal"
              // isLoading={isSubmitting}
              type="submit"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default AddCoupon;
