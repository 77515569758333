import {
  Box,
  Flex,
  Input,
  Button,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Container,
  HStack,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import EnvContext from '../context/EnvContext';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
});

function GenerateOTP() {
  const navigate = useNavigate();
  const { backendUrl } = useContext(EnvContext);
  const handleSubmit = async values => {
    localStorage.setItem('genOtpEmail', values.email);
    const postData = {
      email: values.email,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Generating OTP', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });

      await axios.post(`${backendUrl}/generate_otp`, postData).then(res => {
        toast.success(res.data.message, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
        navigate('/validate_otp');
      });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const handleBack = () => {
    navigate('/login');
  };
  return (
    <Container
      maxW="lg"
      py={{
        base: '2',
        md: '5',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
      <Stack textAlign="center">
        <Heading fontSize={'3xl'}> Generate your OTP</Heading>
      </Stack>
      <Box
        w={'100%'}
        py={{
          base: '0',
          sm: '8',
        }}
        px={{
          base: '4',
          sm: '10',
        }}
        bg={{
          base: 'transparent',
          sm: 'bg.surface',
        }}
        boxShadow={{
          base: 'md',
          sm: 'md',
        }}
        borderRadius={{
          base: 'none',
          sm: 'xl',
        }}
      >
        <Stack spacing="6">
          <Stack spacing="5" width={'90%'}>
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.errors.email && form.touched.email}
                      >
                        <FormLabel>Email</FormLabel>
                        <Input {...field} placeholder="Email" />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Stack spacing="6" mt={4}>
                    <HStack>
                      <Button type="button" onClick={handleBack}>
                        Back
                      </Button>
                      <Button
                        type="submit"
                        colorScheme="blue"
                        isLoading={isSubmitting}
                      >
                        Generate OTP
                      </Button>
                    </HStack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
}

export default GenerateOTP;
