import React, { useContext, useEffect, useState, useRef } from 'react';
import CourseContext from '../../context/CourseListContext';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Checkbox,
  Button,
  Stack,
  IconButton,
  InputGroup,
  InputRightElement,
  HStack,
  VStack,
  CircularProgress,
  CircularProgressLabel,
  Box,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Textarea,
  ChakraProvider,
  Container,
  Image,
  Flex,
  Text,
  useToast,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import axios from 'axios';
import * as Yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import { Formik, Form, Field, FieldArray } from 'formik';
import EnvContext from '../../context/EnvContext';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { GrAdd } from 'react-icons/gr';
import ProjectContext from '../../context/ProjectContext';

function AddProject() {
  const toast = useToast();
  const [notesFile, setNotesFile] = useState({});
  const [optionImages, setOptionImages] = useState([]);
  const { courseId } = useContext(CourseContext);
  const token = localStorage.getItem('token');
  const { backendUrl } = useContext(EnvContext);
  const { fetchAllProjects } = useContext(ProjectContext);
  const [image, setImage] = useState(null);
  const [imagePath, setImagePath] = useState({});
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };
  const [initialValues, setInitialValues] = useState({
    name: '',
    description: '',
    guidelines: '',
    task: '',
    submissionLinks: [],
  });
  const [inputValue, setInputValue] = useState('');
  const [items, setItems] = useState([]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    setOptionImages([...files]);
  };

  const handleAdd = () => {
    if (inputValue.trim() !== '') {
      const parts = inputValue.split(',').map(part => part.trim());
      setItems(prevItems => [...prevItems, ...parts]);
      setInputValue('');
    }
  };
  const handleRemove = (index) => {
    setItems(prevItems => prevItems.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (items) console.log("items", items)
  }, [items])

  // useEffect(() => {
  //     if (courseId) {
  //       console.log('addcourseId', courseId);
  //     }
  //     else{
  //       console.log("courseid not found")
  //     }
  //   }, [courseId]);

  const handleSubmit = async values => {
    const mergedSubmissionLinks = [...values.submissionLinks, ...items];
    console.log(values);
    console.log('notesFile', notesFile);
    console.log('addcourseId', courseId);
    console.log('fruits', optionImages);

    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('task', values.task);
    formData.append('guidelines', notesFile);

    optionImages.forEach((image) => formData.append("images", image));

    mergedSubmissionLinks.forEach((value, index) => {
      formData.append(`submissionLinks[${index}]`, value);
    });
    for (let entry of formData.entries()) {
      const key = entry[0];
      const value = entry[1];
      console.log(key, value);
    }
    const addProjectPromise = axios
      .post(`${backendUrl}/course/project?courseId=${courseId}`, formData, {
        headers: headers,

      });
    toast.promise(addProjectPromise, {
      loading: {
        title: 'Project is adding up ...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
      success: {
        title: 'Project added successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to add project!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
    });

    try {
      const res = await addProjectPromise;
      console.log(res);
      fetchAllProjects();
    } catch (err) {
      console.error(err);
    }

  };
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values }) => (
          <Form>
            <Field name="name">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.name && form.touched.name}>
                  <FormLabel>Project Name :</FormLabel>
                  <Input {...field} placeholder=" Project name" />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="description">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.description && form.touched.description
                  }
                >
                  <FormLabel>Description :</FormLabel>
                  <Textarea  {...field} placeholder="Description" />
                  <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="task">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.task && form.touched.task}>
                  <FormLabel>task :</FormLabel>
                  <Textarea  {...field} placeholder="task" />
                  <FormErrorMessage>{form.errors.task}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="guidelines">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.guidelines && form.touched.guidelines}
                >
                  <FormLabel>guidelines (PDF) :</FormLabel>
                  {/* Your file upload logic here */}
                  <input
                    type="file"
                    onChange={e => setNotesFile(e.target.files[0])}
                  />
                  <FormErrorMessage>{form.errors.guidelines}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="submissionLinks">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.submissionLinks && form.touched.submissionLinks
                  }
                >
                  <FormLabel>Submission Link :</FormLabel>

                  {/* Checkboxes for options */}
                  <CheckboxGroup
                    colorScheme="blue"
                    value={field.value || []}
                    onChange={values => {
                      form.setFieldValue('submissionLinks', values);
                      console.log('Checked Checkboxes:', values);
                    }}
                  >
                    <HStack spacing="24px" mt="4">
                      <Checkbox value="GitHub">GitHub</Checkbox>
                      <Checkbox value="Drive">Drive</Checkbox>
                      <Checkbox value="YouTube">YouTube</Checkbox>
                    </HStack>
                  </CheckboxGroup>

                  <FormErrorMessage>{form.errors.task}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex direction="row" flexWrap={'wrap'} mt={4} gap={5} >
              {items.map((item, index) => (

                <Flex key={index} bg={'gray.100'} flexDirection={'row'} flexWrap={'wrap'} alignItems={'center'} px={2} borderRadius={5} >
                  <Text>{item}</Text>
                  <IconButton
                    icon={<CloseIcon />}
                    variant="ghost"
                    colorScheme="red"
                    aria-label="Delete Item"
                    onClick={() => handleRemove(index)}
                  />
                </Flex>
              ))}
            </Flex>
            <ChakraProvider>
              <Container maxW="container.sm" p="4">
                <VStack spacing="4">
                  <Input
                    placeholder="Enter links separated by commas"
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                  />
                  <Button onClick={handleAdd}>Add</Button>
                </VStack>
              </Container>
            </ChakraProvider>
            <FormLabel>Supportive Images : </FormLabel>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              accept="image/*"
            />

            <Stack spacing="6" mt={4}>
              <Button type="submit">Submit</Button>
            </Stack>
          </Form>
        )}
      </Formik>

      <Toaster />
    </Box>
  );
}

export default AddProject;
