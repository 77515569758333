import {
  Box,
  Flex,
  Text,
  VStack,
  Heading,
  HStack,
  Link,
  Stack,
  useColorModeValue as mode,
  SkeletonCircle,
  SkeletonText,
  Skeleton,
  CloseButton,
  Button,
} from '@chakra-ui/react';
import { CartItem } from '../../components/others/CartItem';
import { CartOrderSummary } from '../../components/others/CartOrderSummary';
import { useNavigate } from 'react-router';
import { useState, useContext, useEffect } from 'react';
import CartContext from '../../context/CartContext';
import useSaveLastRoute from '../../hooks/useSaveLastRoute';
import { useLocation } from 'react-router-dom';

export const ShoppingCart = () => {
  const location = useLocation();
  useSaveLastRoute();
  const {
    cartItems,
    handelAllDelete,
    unAuthCartItems,
    handelAddGroupCart,
    getcart,
    setUnAuthCartItems,
  } = useContext(CartContext);
  const navigate = useNavigate();
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [courseIds, setCourseIds] = useState([]);
  const token = localStorage.getItem('token');
  const [indiCheckout, setIndiCheckout] = useState(false);
  const [subCheckout, setSubCheckout] = useState(false);
  const [cousrseData, setCourseData] = useState({});
  useEffect(() => {
    const storedValues = JSON.parse(localStorage.getItem('unAuthCartItems'));
    console.log('Stored Values:', storedValues); // Log storedValues
    if (storedValues) {
      setUnAuthCartItems(storedValues);
    }
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSkeleton(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (cartItems) {
      console.log('cartItems', cartItems);
    } else {
      setShowSkeleton(false);
    }
  }, [cartItems]);
  useEffect(() => {
    if (Array.isArray(unAuthCartItems)) {
      setCourseIds(unAuthCartItems.map(course => course.id));
    } else {
      setShowSkeleton(false);
    }
  }, [unAuthCartItems]);

  useEffect(() => {
    if (courseIds) console.log(courseIds);
  }, [courseIds]);

  useEffect(() => {
    if (token && courseIds.length > 0) {
      console.log('courseIds', courseIds);
      handelAddGroupCart(courseIds);
    }
  }, [courseIds]);

  useEffect(() => {
    console.log('datakdhsodna', location.state);
    if (location.pathname === '/indi_course/checkout') {
      setIndiCheckout(true);
      console.log('datakdhsodna', location.state);
      const courseData = location.state?.data?.courseData;
      setCourseData(courseData);
    }
    if (
      location.pathname === '/subscription_plans/checkout' ||
      location.pathname === '/renew/checkout'
    ) {
      setIndiCheckout(false);
      setSubCheckout(true);
      const courseData = location.state?.data;
      setCourseData(courseData);
    }
  }, [location]);
  return (
    <Box
      maxW={{
        base: '3xl',
        lg: '8xl',
      }}
      mx="auto"
      px={{
        base: '4',
        md: '8',
        lg: '12',
      }}
      py={{
        base: '6',
        md: '8',
        lg: '12',
      }}
      minH={{
        md: '100vh',
        lg: '100vh',
      }}
    >
      <Stack
        direction={{
          base: 'column',
          lg: 'row',
        }}
        align={{
          lg: 'flex-start',
        }}
        spacing={{
          base: '8',
          md: '16',
        }}
      >
        <Stack
          spacing={{
            base: '8',
            md: '10',
          }}
          flex="2"
        >
          {indiCheckout || subCheckout ? (
            ''
          ) : (
            <HStack justifyContent={'space-between'}>
              <Heading fontSize="2xl" fontWeight="extrabold">
                Shopping Cart (
                {(cartItems && Array.isArray(cartItems)
                  ? [
                      ...new Map(
                        cartItems.map(item => [item._id, item])
                      ).values(),
                    ].length
                  : 0) ||
                  (unAuthCartItems && Array.isArray(unAuthCartItems)
                    ? unAuthCartItems.length
                    : 0)}
                )
              </Heading>

              {token &&
              cartItems &&
              Array.isArray(cartItems) &&
              cartItems.length > 0 ? (
                <Text
                  textDecoration={'underline'}
                  color={'red.300'}
                  cursor={'pointer'}
                  onClick={handelAllDelete}
                >
                  Remove all items
                </Text>
              ) : (
                ' '
              )}
            </HStack>
          )}

          {indiCheckout || subCheckout ? (
            <CartItem {...cousrseData} />
          ) : (
            <Stack spacing="6">
              {cartItems && cartItems.length > 0 ? (
                [
                  ...new Map(cartItems.map(item => [item._id, item])).values(),
                ].map(item => (
                  <>
                    <CartItem key={item._id} {...item} />
                  </>
                ))
              ) : unAuthCartItems &&
                Array.isArray(unAuthCartItems) &&
                unAuthCartItems.length > 0 ? (
                unAuthCartItems.map((item, index) => (
                  <CartItem index={index} {...item} />
                ))
              ) : showSkeleton ? (
                <Box padding="6" boxShadow="lg" bg="white">
                  <Flex justify={'space-between'}>
                    <Box>
                      <Skeleton rounded="lg" width="220px" height="120px" />
                    </Box>
                    <Flex justify={'space-between'} direction={'column'}>
                      <Skeleton height="15px" width={'190px'} />
                      <Skeleton height="15px" width={'190px'} />
                      <Skeleton height="15px" width={'190px'} />
                    </Flex>
                  </Flex>
                </Box>
              ) : (
                <>
                  <Text>Nothing to show here </Text>
                  <Button
                    colorScheme="teal"
                    size="md"
                    onClick={() => {
                      navigate('/products');
                    }}
                  >
                    Keep Shopping
                  </Button>
                </>
              )}
            </Stack>
          )}
        </Stack>

        <Flex direction="column" align="center" flex="1">
          {location.pathname === '/subscription_plans/checkout' ? (
            <CartOrderSummary
              offerPrice={cousrseData?.offerPrice}
              id={cousrseData?._id}
              subData={cousrseData}
            />
          ) : (
            <CartOrderSummary
              offerPrice={
                location.pathname === '/renew/checkout'
                  ? cousrseData?.renewalPrice
                  : cousrseData?.offerPrice
              }
              id={cousrseData?._id}
            />
          )}

          <HStack mt="6" fontWeight="semibold">
            <p>or</p>
            <Link
              color={mode('blue.500', 'blue.200')}
              onClick={() => navigate('/products')}
            >
              Continue shopping
            </Link>
          </HStack>
        </Flex>
      </Stack>
    </Box>
  );
};
