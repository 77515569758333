import React, { useContext, useEffect, useState } from 'react';
import PaymentContext from '../../../context/PaymentContext';
import {
  ChakraProvider,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Box,
  Button,
  useMediaQuery,
  Heading,
} from '@chakra-ui/react';
import {
  encryptData,
  formatDate,
  formatTime,
  sortTransactionsByDate,
} from '../../../Utils/function';
import { useLocation, useNavigate } from 'react-router-dom';

const TransactionDetails = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const secretKey = process.env.REACT_APP_CRYPTO_KEY;
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const [isTab] = useMediaQuery('(max-width: 992px)');
  const { fetchAllTransactions, transactions } = useContext(PaymentContext);
  const location = useLocation();
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');

  const [searchTerm, setSearchTerm] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const filterTransactions = (status, success = true) => {
    const filteredTransactions = transactions.filter(transaction =>
      success
        ? transaction.payment_status === status &&
          transaction.merchantTransactionId.includes(searchTerm)
        : transaction.payment_status !== 'PAYMENT_SUCCESS' &&
          transaction.merchantTransactionId.includes(searchTerm)
    );
    return sortTransactionsByDate(filteredTransactions);
  };
  useEffect(() => {
    if (location.pathname === '/all_transactions') fetchAllTransactions(userId);
    else fetchAllTransactions();
    
  }, [location]);
 
  return (
    <Box p={{ base: 2, md: 4 }} width={{ base: '100%', md: '60vh', lg: '78%' }}>
      <Box position={'sticky'} top={0} bg={'#fff'} zIndex={4} mb={4}>
        <Heading
          pl={5}
          fontSize={'4xl'}
          lineHeight={{
            base: '2.2rem',
            lg: '1.2rem',
          }}
          bg={'white'}
          mb={2}
        >
          Your Transactions
        </Heading>
        <Input
          mt={2}
          placeholder="Search by Merchant Transaction ID"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </Box>
      <Tabs width={'100%'} index={tabIndex} onChange={(index) => setTabIndex(index)}>
        <TabList width={'100%'}>
          <Tab>Successful Transactions</Tab>
          <Tab>Failed Transactions</Tab>
        </TabList>

        <TabPanels width={'100%'}>
          <TabPanel width={'100%'}>
            {/* Render as cards if isMobile */}
            {isMobile || isTab ? (
              filterTransactions('PAYMENT_SUCCESS').map((transaction, i) => (
                <Box
                  key={transaction?._id}
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  mb={4}
                >
                  <Box p="6">
                    <Box d="flex" alignItems="baseline">
                      <Box fontWeight="semibold" as="h4" lineHeight="tight">
                        {transaction?.merchantTransactionId}
                      </Box>
                      <Box ml="2" fontSize="sm" color="gray.500">
                        {`${formatDate(transaction?.createdAt)} - ${formatTime(
                          transaction?.createdAt
                        )}`}
                      </Box>
                    </Box>
                    <Box
                      mt="1"
                      fontWeight="semibold"
                      as="h4"
                      lineHeight="tight"
                    >
                      Amount: {transaction?.amount}
                    </Box>
                    <Box
                      mt="1"
                      fontWeight="semibold"
                      as="h4"
                      lineHeight="tight"
                    >
                      Course Name:{' '}
                      {
                          transaction?.isSubscription ? transaction?.subscriptionPlanId?.name :
                          transaction?.course
                            .map(c => c.courseName)
                            .join(', ')
                        }
                    </Box>
                    <Box mt="2">
                      Payment Status: {transaction?.payment_status}
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              // Render as table if not isMobile
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Sl No</Th>
                    <Th>Merchant Transaction ID</Th>
                    <Th>Amount</Th>
                    <Th>Course Name</Th>
                    <Th>Payment Status</Th>
                    <Th>Created At</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filterTransactions('PAYMENT_SUCCESS').map(
                    (transaction, i) => (
                      <Tr key={transaction?._id}>
                        <Td>{i + 1}</Td>
                        <Td>{transaction?.merchantTransactionId}</Td>
                        <Td>{transaction?.amount}</Td>
                        <Td>
                        {
                          transaction?.isSubscription ? transaction?.subscriptionPlanId?.name :
                          transaction?.course
                            .map(c => c.courseName)
                            .join(', ')
                        }
                        </Td>
                        <Td>{transaction?.payment_status}</Td>
                        <Td>{`${formatDate(
                          transaction?.createdAt
                        )} - ${formatTime(transaction?.createdAt)}`}</Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            )}
          </TabPanel>

          <TabPanel width={'100%'}>
            {/* Render as cards if isMobile */}
            {isMobile || isTab ? (
              filterTransactions('PAYMENT_SUCCESS', false).map(
                (transaction, i) => (
                  <Box
                    key={transaction?._id}
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    mb={4}
                  >
                    <Box p="6">
                      <Box d="flex" alignItems="baseline">
                        <Box fontWeight="semibold" as="h4" lineHeight="tight">
                          {transaction?.merchantTransactionId}
                        </Box>
                        <Box ml="2" fontSize="sm" color="gray.500">
                          {`${formatDate(
                            transaction?.createdAt
                          )} - ${formatTime(transaction?.createdAt)}`}
                        </Box>
                      </Box>
                      <Box
                        mt="1"
                        fontWeight="semibold"
                        as="h4"
                        lineHeight="tight"
                      >
                        Amount: {transaction?.amount}
                      </Box>
                      <Box
                        mt="1"
                        fontWeight="semibold"
                        as="h4"
                        lineHeight="tight"
                      >
                        Course Name:{' '}
                        {
                          transaction?.isSubscription ? transaction?.subscriptionPlanId?.name :
                          transaction?.course
                            .map(c => c.courseName)
                            .join(', ')
                        }
                      </Box>
                      <Box mt="2">
                        Payment Status:{' '}
                        {transaction?.payment_status
                          ? transaction?.payment_status
                          : 'Failed'}
                      </Box>
                      {location.pathname === '/all_transactions' && (
                        <Box mt="2">
                          <Button
                            colorScheme="teal"
                            size="sm"
                            onClick={() => {
                              const encryptedMerchantID = encryptData(
                                transaction?.merchantId,
                                secretKey
                              );
                              localStorage.setItem(
                                'merchantID',
                                encryptedMerchantID
                              );
                              navigate(`/loading_page/${transaction?.merchantTransactionId}`);
                            }}
                          >
                            Check Status
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )
              )
            ) : (
              // Render as table if not isMobile
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Sl No</Th>
                    <Th>Merchant Transaction ID</Th>
                    <Th>Amount</Th>
                    <Th>Course Name</Th>
                    <Th>Payment Status</Th>
                    <Th>Created At</Th>
                    {location.pathname === '/all_transactions' && (
                      <Th>Check Status</Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {filterTransactions('PAYMENT_SUCCESS', false).map(
                    (transaction, i) => (
                      <Tr key={transaction?._id}>
                        <Td>{i + 1}</Td>
                        <Td>{transaction?.merchantTransactionId}</Td>
                        <Td>{transaction?.amount}</Td>
                        <Td>
                        {
                          transaction?.isSubscription ? transaction?.subscriptionPlanId?.name :
                          transaction?.course
                            .map(c => c.courseName)
                            .join(', ')
                        }
                          
                        </Td>
                        <Td>
                          {transaction?.payment_status
                            ? transaction?.payment_status
                            : 'Failed'}
                        </Td>
                        <Td>{`${formatDate(
                          transaction?.createdAt
                        )} - ${formatTime(transaction?.createdAt)}`}</Td>
                        {location.pathname === '/all_transactions' && (
                          <Td>
                            <Button
                              colorScheme="teal"
                              size="sm"
                              onClick={() => {
                                const encryptedMerchantID = encryptData(
                                  transaction?.merchantId,
                                  secretKey
                                );
                                localStorage.setItem(
                                  'merchantID',
                                  encryptedMerchantID
                                );
                                navigate(`/loading_page/${transaction?.merchantTransactionId}`);
                              }}
                            >
                              Check Status
                            </Button>
                          </Td>
                        )}
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default TransactionDetails;
