import React, { useContext, useEffect, useState, useMemo } from 'react';
import SubscriptionCard from './SubscriptionCard';
import SubscriptionCard1 from './SubscriptionCard1';
import SubscriptionCard2 from './SubscriptionPlane2';
import {
  Flex,
  HStack,
  VStack,
  Text,
  Stack,
  InputGroup,
  InputLeftElement,
  Input,
  Heading,
  Box,
} from '@chakra-ui/react';
import axios from 'axios';
import EnvContext from '../../../../context/EnvContext';
import { SearchIcon } from '@chakra-ui/icons';
import debounce from 'lodash.debounce';
import useSaveLastRoute from '../../../../hooks/useSaveLastRoute';

const SubscriptionPlans = () => {
  useSaveLastRoute();
  const { backendUrl } = useContext(EnvContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscription, setFilteredSubscription] = useState([]);
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const fetchAllSubscriptionPlans = async () => {
    try {
      await axios.get(`${backendUrl}/subscription/all`).then(res => {
        console.log('subscription', res.data);
        setSubscriptions(res.data.data);
      });
    } catch (err) {
      console.log('error', err);
    }
  };
  const handleSearch = value => {
    console.log(value);
    const filtered = subscriptions.filter(el =>
      el.name.toLowerCase().includes(value.toLowerCase())
    );
    console.log('filtered', filtered);
    setFilteredSubscription(filtered);
  };

  const debouncedHandleSearch = useMemo(
    () => debounce(handleSearch, 300),
    [subscriptions]
  );

  useEffect(() => {
    // Cleanup function to cancel debounce on unmount
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [debouncedHandleSearch]);
  useEffect(() => {
    console.log('cardValues', subscriptions);
    if (subscriptions && subscriptions.length > 0) {
      setFilteredSubscription(subscriptions);
    }
  }, [subscriptions]);

  const handleInputChange = event => {
    debouncedHandleSearch(event.target.value);
  };
  useEffect(() => {
    fetchAllSubscriptionPlans();
  }, []);
  useEffect(() => {
    if (filteredSubscription)
      console.log('filteredSubscription', filteredSubscription);
  }, [filteredSubscription]);
  return (
    <Box minHeight={'100vh'} maxHeight={'fit-content'} mb={10} p={2}>
      {/* <SubscriptionCard/>
        <SubscriptionCard1/> */}
      <HStack justifyContent={'space-around'} mb={'2%'}>
        <Heading as="h4" size="lg">
          All Subscription Plans
        </Heading>
        <Stack spacing={4}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Search Courses"
              bg="white"
              onChange={handleInputChange}
            />
          </InputGroup>
        </Stack>
      </HStack>
      <Flex
        flexWrap={'wrap'}
        gap={'40px'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {filteredSubscription &&
          Array.isArray(filteredSubscription) &&
          filteredSubscription.length > 0 &&
          filteredSubscription.map((el, i) => <SubscriptionCard2 el={el} />)}
      </Flex>
    </Box>
  );
};

export default SubscriptionPlans;
