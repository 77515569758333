import {
  CloseButton,
  Flex,
  Link,
  Select,
  useColorModeValue,
  Spacer,
  Box,
  VStack,
  Text,
  border,
} from '@chakra-ui/react';
import { PriceTag } from '../elements/PriceTag';
import { CartProductMeta } from '../elements/CartProductMeta';
import { useContext, useEffect } from 'react';
import CartContext from '../../context/CartContext';
import { useLocation } from 'react-router-dom';
import WishListContext from '../../context/WishListContext';

export const CartItem = ({ index, ...otherProps }) => {
  const {
    isGiftWrapping,
    name,
    description,
    quantity,
    imageUrl,
    currency,
    price,
    onChangeQuantity,
    salePrice,
    courseName,
    language,
    logo,
    offerPrice,
    originalPrice,
    _id,
    id,
    renewalPrice,
  } = otherProps;
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  const { handeCartlDelete, handelAddCart, adminDeleteCart } =
    useContext(CartContext);
  const { handleWishListlDelete, handelWishlist } = useContext(WishListContext);
  const location = useLocation();
  // useEffect(() => {
  //   if (location) console.log('location', location);
  // }, [location]);
  return (
    <Flex
      direction={{
        base: 'column',
        md: 'row',
      }}
      justify="space-between"
      align="center"
      borderRadius={{
        base: 10,
        md: 20,
        lg: 40,
      }}
      style={
        location.pathname === '/subscription_plans/checkout' ||
        location.pathname === '/renew/checkout'
          ? { border: '1px solid rgb(126 34 206)' }
          : {}
      }
      px={5}
      pb={{
        base: 4,
        md: 0,
        lg: 0,
      }}
      m={0}
    >
      {location.pathname === '/subscription_plans/checkout' ||
      location.pathname === '/renew/checkout' ? (
        <CartProductMeta {...otherProps} />
      ) : (
        <CartProductMeta
          name={courseName}
          description={language}
          logo={logo}
          isGiftWrapping={isGiftWrapping}
        />
      )}

      {/* Desktop */}
      <Flex
        width="full"
        justify="space-between"
        display={{
          base: 'none',
          md: 'flex',
        }}
      >
        <Spacer />
        {/* <Box>
      <PriceTag price={price} currency={currency} />
        <PriceTag price={price} currency={currency} />
      </Box> */}
        <VStack>
          {location.pathname === '/renew/checkout' ? (
            <PriceTag price={renewalPrice} currency={currency} />
          ) : (
            <PriceTag
              price={originalPrice}
              currency={currency}
              salePrice={offerPrice}
            />
          )}

          {token && location.pathname === '/cart' && (
            <Text
              cursor={'pointer'}
              onClick={() => {
                handelWishlist(_id);
                handeCartlDelete(_id);
              }}
            >
              Add to wishList
            </Text>
          )}

          {location.pathname === '/wishList' && (
            <Text
              cursor={'pointer'}
              onClick={() => {
                handelAddCart(_id);
                handleWishListlDelete(_id);
              }}
            >
              Add to Cart
            </Text>
          )}
        </VStack>

        {location.pathname === '/cart' && (
          <CloseButton
            aria-label={`Delete ${name} from cart`}
            onClick={() => {
              if (token) handeCartlDelete(_id);
              else handeCartlDelete(id);
            }}
            ml={2}
          />
        )}
        {location.pathname !== '/cart' && role === 'admin' && (
          <CloseButton
            aria-label={`Delete ${name} from cart`}
            onClick={() => adminDeleteCart(_id)}
            ml={2}
          />
        )}
        {location.pathname === '/wishList' && (
          <CloseButton
            aria-label={`Delete ${name} from cart`}
            onClick={() => handleWishListlDelete(_id)}
            ml={2}
          />
        )}
      </Flex>

      {/* Mobile */}
      <Flex
        mt="4"
        align="center"
        width="full"
        justify="space-between"
        display={{
          base: 'flex',
          md: 'none',
        }}
      >
        {location.pathname === '/cart' && (
          <Link
            fontSize="sm"
            textDecor="underline"
            onClick={() => {
              if (token) handeCartlDelete(_id);
              else handeCartlDelete(id);
            }}
          >
            Delete
          </Link>
        )}
        {location.pathname === '/wishList' && (
          <Link
            fontSize="sm"
            textDecor="underline"
            onClick={() => handleWishListlDelete(_id)}
          >
            Delete
          </Link>
        )}
        {location.pathname === '/renew/checkout' ? (
          <PriceTag price={renewalPrice} currency={currency} />
        ) : (
          <PriceTag
            price={originalPrice}
            currency={currency}
            salePrice={offerPrice}
          />
        )}
      </Flex>
    </Flex>
  );
};
