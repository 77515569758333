import {
  Button,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  FormErrorMessage,
  Spinner,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { formatPrice } from '../elements/PriceTag';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CartContext from '../../context/CartContext';
import CouponContext from '../../context/CouponContext';
import { MdDone } from 'react-icons/md';
import PaymentContext from '../../context/PaymentContext';
import { useLocation } from 'react-router-dom';
import { encryptData, generateTransactionID } from '../../Utils/function';
const OrderSummaryItem = props => {
  const { label, value, children } = props;
  return (
    <Flex justify="space-between" fontSize="sm">
      <Text fontWeight="medium" color={mode('gray.600', 'gray.400')}>
        {label}
      </Text>
      {value ? <Text fontWeight="medium">{value}</Text> : children}
    </Flex>
  );
};
const OrderDiscount = props => {
  const { label, value, children } = props;
  return (
    <Flex justify="space-between" fontSize="sm">
      <Text fontWeight="medium" color={mode('gray.600', 'gray.400')}>
        {label}
      </Text>
      {value ? (
        <Text fontWeight="medium" color={'green'}>
          {' '}
          -{value}
        </Text>
      ) : (
        children
      )}
    </Flex>
  );
};

export const CartOrderSummary = ({ offerPrice, id, subData }) => {
  const secretKey = process.env.REACT_APP_CRYPTO_KEY;
  const location = useLocation();
  const { totalCart, cartItems } = useContext(CartContext);
  const {
    getAboutCoupon,
    couponData,
    setCouponData,
    setShowSpinner,
    showSpinner,
    spninnerStatus,
  } = useContext(CouponContext);
  const token = localStorage.getItem('token');
  const { initiateThePayment } = useContext(PaymentContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [input, setInput] = useState('');
  const [discountRate, setDiscountRate] = useState(0);
  const [showForm, setShowForm] = useState(true);
  const navigate = useNavigate();

  const handleInputChange = e => setInput(e.target.value);

  useEffect(() => {
    console.log(
      'spninnerStatus',
      spninnerStatus,
      'showSpinner',
      showSpinner,
      'showForm',
      showForm
    );
  }, [spninnerStatus, showSpinner, showForm]);
  useEffect(() => {
    setCouponData({});
    setDiscountRate(0);
  }, [location]);
  const isError = input === '';

  const handleCheckout = async () => {
    if (token) {
      const ids = await cartItems?.map(cart => cart._id);
      const userId = localStorage.getItem('userId');
      const merchantID = generateTransactionID();
      const encryptedMerchantID = encryptData(merchantID, secretKey);

      // Save the encrypted merchant ID to localStorage
      localStorage.setItem('merchantID', encryptedMerchantID);

      if (location.pathname === '/indi_course/checkout') {
        const data = {
          courseIds: [id],
          userId: userId,
          couponCode: couponData.code,
          isSubscription: false,
          merchantTransactionId: merchantID,
        };
        initiateThePayment(data);
      } else if (location.pathname === '/subscription_plans/checkout') {
        initiateThePayment(subData);
      } else {
        const data = {
          courseIds: ids,
          userId: userId,
          couponCode: couponData.code,
          isSubscription: false,
          merchantTransactionId: merchantID,
        };
        initiateThePayment(data);
      }

      console.log('User avilable');
    } else {
      console.log('User is not avilable');
      navigate('/login');
    }
  };

  useEffect(() => {
    if (couponData) {
      console.log('couponDatacard', couponData);
      handleDiscound();
    }
  }, [couponData]);
  const handleDiscound = () => {
    if (couponData) {
      const discountPrice = totalCart * (couponData.percentage / 100);
      console.log('discountPrice', discountPrice);
      if (discountPrice <= couponData.maxDiscount)
        setDiscountRate(discountPrice);
      else setDiscountRate(couponData.maxDiscount);
    }
  };
  return (
    <Stack spacing="8" borderWidth="1px" rounded="lg" padding="8" width="full">
      <Heading size="md">Order Summary</Heading>
      {offerPrice ? (
        <Stack spacing="6">
          <OrderSummaryItem label="Subtotal" value={formatPrice(offerPrice)} />
          {discountRate && (
            <OrderDiscount label="Discount" value={formatPrice(discountRate)} />
          )}
          {/* <OrderSummaryItem label="Shipping + Tax">
         <Link href="#" textDecor="underline">
           Calculate shipping
         </Link>
       </OrderSummaryItem> */}
          <OrderSummaryItem label="Coupon Code">
            <Link textDecor="underline" onClick={onOpen}>
              Add coupon code
            </Link>
          </OrderSummaryItem>
          <Flex justify="space-between">
            <Text fontSize="lg" fontWeight="semibold">
              Total
            </Text>
            {discountRate ? (
              <Text fontSize="xl" fontWeight="extrabold">
                {formatPrice(offerPrice - discountRate)}
              </Text>
            ) : (
              <Text fontSize="xl" fontWeight="extrabold">
                {formatPrice(offerPrice)}
              </Text>
            )}
          </Flex>
        </Stack>
      ) : (
        <Stack spacing="6">
          <OrderSummaryItem label="Subtotal" value={formatPrice(totalCart)} />
          {discountRate && (
            <OrderDiscount label="Discount" value={formatPrice(discountRate)} />
          )}
          {/* <OrderSummaryItem label="Shipping + Tax">
         <Link href="#" textDecor="underline">
           Calculate shipping
         </Link>
       </OrderSummaryItem> */}
          <OrderSummaryItem label="Coupon Code">
            <Link textDecor="underline" onClick={onOpen}>
              Add coupon code
            </Link>
          </OrderSummaryItem>
          <Flex justify="space-between">
            <Text fontSize="lg" fontWeight="semibold">
              Total
            </Text>
            {discountRate ? (
              <Text fontSize="xl" fontWeight="extrabold">
                {formatPrice(totalCart - discountRate)}
              </Text>
            ) : (
              <Text fontSize="xl" fontWeight="extrabold">
                {formatPrice(totalCart)}
              </Text>
            )}
          </Flex>
        </Stack>
      )}

      <Button
        colorScheme="blue"
        size="lg"
        fontSize="md"
        rightIcon={<FaArrowRight />}
        onClick={handleCheckout}
      >
        Pay Now
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Validate Coupon</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setInput('');
              setShowSpinner(false);
              setShowForm(true);
            }}
          />
          <ModalBody>
            {showSpinner
              ? !showForm && <Spinner size="xl" />
              : spninnerStatus
              ? !showForm && (
                  <>
                    <CircularProgress
                      value={100}
                      color="green.400"
                      display={'flex'}
                      justifyContent={'center'}
                    >
                      <CircularProgressLabel
                        display={'flex'}
                        justifyContent={'center'}
                      >
                        {' '}
                        <MdDone size={28} color="green" />
                      </CircularProgressLabel>
                    </CircularProgress>
                    <Text size={10} display={'flex'} justifyContent={'center'}>
                      Coupon added sucessfully
                    </Text>
                  </>
                )
              : !showForm && (
                  <>
                    <CircularProgress
                      value={100}
                      color="red.400"
                      display={'flex'}
                      justifyContent={'center'}
                    >
                      <CircularProgressLabel
                        display={'flex'}
                        justifyContent={'center'}
                      >
                        <MdDone size={28} color="red" />
                      </CircularProgressLabel>
                    </CircularProgress>
                    <Text size={10} display={'flex'} justifyContent={'center'}>
                      Coupon failed
                    </Text>
                  </>
                )}

            {showForm && (
              <FormControl isInvalid={isError}>
                <FormLabel>Coupon Code</FormLabel>
                <Input type="text" value={input} onChange={handleInputChange} />
                {!isError ? (
                  <FormHelperText>Enter the coupon code</FormHelperText>
                ) : (
                  <FormErrorMessage>Coupon is required.</FormErrorMessage>
                )}
              </FormControl>
            )}
          </ModalBody>

          <ModalFooter>
            {!showForm ? (
              !spninnerStatus && (
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  Retry
                </Button>
              )
            ) : (
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => {
                  getAboutCoupon(input);
                  setShowSpinner(true);
                  setShowForm(false);
                }}
              >
                Validate Coupon
              </Button>
            )}

            <Button
              variant="ghost"
              onClick={() => {
                onClose();
                setInput(' ');
                setShowSpinner(false);
                setShowForm(true);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};
