import {
  Box,
  Image,
  Heading,
  Text,
  Flex,
  useStyleConfig,
  Button,
} from '@chakra-ui/react';
import React, { useState, useContext, useEffect } from 'react';
import '../../allcss/coursecard.css';
import { NavLink, useNavigate, useNavigation } from 'react-router-dom';
import { MdOutlinePlayCircle } from 'react-icons/md';
import EnvContext from '../../context/EnvContext';
import ContentContext from '../../context/ContentContext';
import CourseContext from '../../context/CourseListContext';
export const ShowCourseCard = ({
  heading,
  img,
  courseLevel,
  index,
  language,
  id,
}) => {
  const styles = useStyleConfig('Box', { variant: 'hoverBox' });
  const { backendUrl } = useContext(EnvContext);
  const { fetchAllContent } = useContext(ContentContext);
  const { setCourseId, courseId } = useContext(CourseContext);

  const navigate = useNavigate();
  const cardClick = () => {
    console.log('card Clicked');
    navigate('/indi_course');
  };
  const [showPlayButton, setShowPlayButton] = useState(false);
  const handlePlayButtonClick = id => {
    // Redirect the user to another page

    console.log('card Clicked', id);

    setCourseId(id);
    navigate('/video_page');
  };

  // Make sure to pass courseId as a dependency to useEffect
  // Also, make sure fetchAllContent and navigate are coming from props or context

  return (
    <Flex
      flexDirection={'column'}
      border="1px solid gray"
      w={{
        base: "285px",
        md: 300,
        // lg: 270,
      }}
      h={{
        base: '39vh',
        md: '26vh',
        lg: '45vh',
      }}
      m="4"
      borderRadius="10"
      // bg="white"

      key={index}
      onMouseEnter={() => setShowPlayButton(true)}
      onMouseLeave={() => setShowPlayButton(false)}
      onClick={() => handlePlayButtonClick(id)}
      cursor={'pointer'}
    >
      <Box
        position={'relative'}
        width={'100%'}
        height={{
          base: '21vh',
          md: '14vh',
          lg: '24vh',
        }}
      >
        <Image
          style={{ objectFit: 'cover' }}
          src={`${backendUrl}${img}`}
          width={'100%'}
          height={{
            base: '20vh',
            md: '14vh',
            lg: '24vh',
          }}
          alt="jsCourse"
          borderTopRadius={10}
        />
        {showPlayButton && (
          <Flex
            bg={'#00000099'}
            position="absolute"
            top="0"
            left="0"
            w={'100%'}
            h={'100%'}
            borderTopRadius={10}
            justifyContent={'center'}
          >
            <button>
              <MdOutlinePlayCircle size={'4rem'} color="white" />
            </button>
          </Flex>
        )}
      </Box>
      <Box px={2} py={6}>
        <Heading whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis" maxWidth="90%" fontSize="18px" fontWeight={700} textAlign={'left'}>
          {heading}
         
        </Heading>
        <Text marginTop={2}>
          Language : <strong>{language}</strong>
        </Text>
      </Box>
    </Flex>
  );
};
