import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  HStack,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import CourseContext from '../../../context/CourseListContext';
import SyllabusContext from '../../../context/SyllabusContext';
import { UpdateSyllabus } from './UpdateSyllabus';
import { useNavigate } from 'react-router-dom';
import SyllabusForm from '../../../components/forms/SyllabusForm';

export const ManageSyllabus = () => {
  const navigate = useNavigate();
  const { courseData } = useContext(CourseContext);
  const { getSyllabus, syllabusData, deleteSyllabus } =
    useContext(SyllabusContext);

  const role = localStorage.getItem('role');
  useEffect(() => {
    if (role !== "admin") navigate('/dashboard');
  }, [role]);
  useEffect(() => {
    if (courseData) {
      getSyllabus(courseData?.syllabus?._id);
    }
  }, [courseData]);

  const [inputValue, setInputValue] = useState('');
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [syllabusName, setSyllabusName] = useState(null);
  useEffect(() => {
    if (syllabusData) setSyllabusName(syllabusData.courseName);
  }, [syllabusData]);
  const handleInputChange = event => {
    const { value } = event.target;
    setInputValue(value);

    // Check if input value matches the syllabus name
    if (value.toUpperCase() === syllabusName.toUpperCase()) {
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
  };

  const handleDeleteClick = () => {
    // Handle delete action here
    // For demonstration, let's just clear the input value
    deleteSyllabus(courseData?.syllabus?._id).then(() =>
      navigate('/admin/courses')
    );
    setInputValue('');
    setShowDeleteButton(false);
  };
  return (
    <>
      {courseData && courseData.syllabus ? (
        <Box px={'5rem'}>
          <HStack w={'100%'} p={4} spacing={10} alignItems={'flex-start'}>
            <VStack
              width={'40%'}
              boxShadow={'lg'}
              rounded={'md'}
              alignItems={'center'}
              justifyContent={'center'}
              spacing={2}
            >
              {syllabusData && (
                <>
                  <Accordion allowToggle width={'95%'}>
                    <Text fontWeight={700}>
                      Chapters of {syllabusData?.courseName} :-
                    </Text>
                    {syllabusData?.chapters &&
                      syllabusData?.chapters.length > 0 &&
                      syllabusData?.chapters?.map((el, i) => (
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Box as="span" flex="1" textAlign="left">
                                {el?.chapterNumber}) {el?.chapterTitle}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4} pl={'15%'}>
                            {el?.topics &&
                              el?.topics.length > 0 &&
                              el?.topics.map((el, i) => (
                                <>
                                  <li key={i}>{el}</li>
                                </>
                              ))}
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                  </Accordion>
                </>
              )}
            </VStack>

            <VStack w={'50%'}>
              <Tabs w={'100%'}>
                <TabList>
                  <Tab>Update Syllabus</Tab>
                  <Tab>Permanent Delete</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <UpdateSyllabus />
                  </TabPanel>
                  <TabPanel>
                    <Box p={4}>
                      <FormControl>
                        <Text mb={2} color={'red'} fontWeight={700}>
                          {' '}
                          Are you sure? You can't undo this action afterwards.
                        </Text>
                        <FormLabel>
                          Type this "{syllabusData.courseName}" to confirm
                          deletion:
                        </FormLabel>

                        <Input
                          type="text"
                          value={inputValue}
                          onChange={handleInputChange}
                          placeholder="Enter syllabus name"
                        />
                        {showDeleteButton && (
                          <Button
                            mt={4}
                            colorScheme="red"
                            onClick={handleDeleteClick}
                          >
                            Delete
                          </Button>
                        )}
                      </FormControl>
                      {showDeleteButton && (
                        <Alert status="info" mt={4}>
                          <AlertIcon />
                          Syllabus name matches! Delete button is enabled.
                        </Alert>
                      )}
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </VStack>
          </HStack>
        </Box>
      ) : (
        <SyllabusForm />
      )}
    </>
  );
};
