import React, { useContext } from 'react';
import ProjectContext from '../../../context/ProjectContext';
import { Text, Button } from '@chakra-ui/react';
import EnvContext from '../../../context/EnvContext';
import { NotesOpener } from '../../../components/modals/NotesOpener';
function ProjectDetails() {
  const { backendUrl } = useContext(EnvContext);
  const { projectId, ProjectData, getProjectSubmission } =
    useContext(ProjectContext);
  return (
    <>
      {ProjectData.name ? (
        <>
          <Button
            colorScheme="orange"
            mb={5}
            onClick={() => {
              localStorage.removeItem('CourseID');
              localStorage.removeItem('UserId');
              localStorage.setItem('history', '/admin/manage_projects');
              localStorage.setItem('ProjectId', ProjectData._id);
              getProjectSubmission(ProjectData._id, undefined, undefined);
            }}
          >
            Project Submissions
          </Button>
          <Text>
            <strong> Name : </strong> {ProjectData.name}{' '}
          </Text>
          <Text>
            <strong> Description : </strong> {ProjectData.description}{' '}
          </Text>
          <Text>
            <strong> Task : </strong> {ProjectData.task}{' '}
          </Text>
          <Text>
            <strong> Full Score : </strong> {ProjectData.fullScore}{' '}
          </Text>
          <Text>
            <strong> Submission Link : </strong>{' '}
            {ProjectData.submissionLinks &&
              ProjectData.submissionLinks.length > 0 &&
              ProjectData.submissionLinks.map((el, i) => (
                <>
                  <li key={i}>{el}</li>
                </>
              ))}
          </Text>
          <Text>
            <strong> Guidelines : </strong>{' '}
          </Text>
          <NotesOpener notes={ProjectData.guidelines}>
            <Button colorScheme="teal" size={'xs'}>
              Open the pdf
            </Button>
          </NotesOpener>
          <Text>
            <strong> Images : </strong>{' '}
          </Text>
          {ProjectData.images.map((projectImage, i) => (
            <img
              width={'70%'}
              height={'50%'}
              key={i} // It's better if you have unique ids for your images and use those instead of index
              src={`${backendUrl}${projectImage}`}
              alt="Project visualization" // Consider a more descriptive alt text based on the image content or context
            />
          ))}
        </>
      ) : (
        <Text> Select any project </Text>
      )}
    </>
  );
}

export default ProjectDetails;
