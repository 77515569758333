import React, { useContext, useEffect, useState } from 'react';
import AssignmentContext from '../../../../context/AssignmentContext';
import { QuizCard } from './QuizCard';
import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { FaLongArrowAltLeft } from 'react-icons/fa';

export const Quizes = () => {
  const { questions, currentIndex, setQuesId } = useContext(AssignmentContext);

  useEffect(() => {
    if (Array.isArray(questions) && questions.length > 0) {
      setQuesId(questions[0]._id);
    }
  }, [questions]);

  return (
    <VStack
      height={'100%'}
      minWidth={'65vh'}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      flexDirection={{
        base: 'column',
        sm: 'column',
        lg: 'row',
      }}
    >
      {Array.isArray(questions) && questions.length > 0 && (
        <>
          <Box width={'100%'} height={'100%'}>
            <QuizCard quiz={questions[currentIndex]} index={currentIndex} />
          </Box>
        </>
      )}
    </VStack>
  );
};
