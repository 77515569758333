import React, { useContext, useEffect } from 'react';

import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  Text,
  IconButton,
  Divider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import AssignmentContext from '../../../context/AssignmentContext';
import { AllAssignments } from './AllAssignments';
import ContentContext from '../../../context/ContentContext';
import { AboutQnA } from './AboutQnA';
import AddQuestionForm from '../../../components/forms/AddQuestionForm';
import { useNavigate } from 'react-router-dom';

export const ManageAssignments = () => {
  const { setToken, fetchAllAssignments } = useContext(AssignmentContext);
  const { contentId } = useContext(ContentContext);
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (token) {
      setToken(token);
      fetchAllAssignments(contentId);
    }
  }, [token]);

  const role = localStorage.getItem('role');

  const navigate = useNavigate();

  useEffect(() => {
    if (role !== "admin") navigate('/dashboard');
  }, [role]);

  return (
    <Box px={'5rem'}>
      <HStack w={'100%'} p={4} spacing={10} alignItems={'flex-start'}>
        <VStack
          width={'40%'}
          boxShadow={'lg'}
          rounded={'md'}
          alignItems={'center'}
          justifyContent={'center'}
          spacing={2}
        >
          <AllAssignments />
        </VStack>

        <VStack w={'50%'}>
          <Tabs w={'100%'}>
            <TabList>
              <Tab>About</Tab>
              <Tab>Add Question</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <AboutQnA />
              </TabPanel>
              <TabPanel>
                <AddQuestionForm />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </HStack>
    </Box>
  );
};
