import {
  Box,
  Flex,
  Heading,
  HStack,
  Text,
  Link,
  Stack,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { CartItem } from '../../../components/others/CartItem';
import WishListContext from '../../../context/WishListContext';
import { useContext, useEffect } from 'react';
import useSaveLastRoute from '../../../hooks/useSaveLastRoute';

export const WishList = () => {
  useSaveLastRoute();
  const navigate = useNavigate();
  const { wishListData, handelAllWishListDelete, getWishList } =
    useContext(WishListContext);
  const userId = localStorage.getItem('userId');
  const role = localStorage.getItem('role');
  useEffect(() => {
    if (userId && role !== 'admin') {
      getWishList(userId);
    }
  }, []);
  return (
    <Box
      maxW={{
        base: '3xl',
        lg: '8xl',
      }}
      mx="auto"
      px={{
        base: '4',
        md: '8',
        lg: '12',
      }}
      py={{
        base: '6',
        md: '8',
        lg: '12',
      }}
      minH={'85vh'}
    >
      <Stack
        direction={{
          base: 'column',
          lg: 'row',
        }}
        align={{
          lg: 'flex-start',
        }}
        spacing={{
          base: '8',
          md: '16',
        }}
      >
        <Stack
          spacing={{
            base: '8',
            md: '10',
          }}
          flex="2"
        >
          <HStack justifyContent={'space-between'}>
            <Heading fontSize="2xl" fontWeight="extrabold">
              WishList ({wishListData.length})
            </Heading>

            {wishListData && wishListData.length > 0 ? (
              <Text
                textDecoration={'underline'}
                color={'red.300'}
                cursor={'pointer'}
                onClick={handelAllWishListDelete}
              >
                Remove all items
              </Text>
            ) : (
              ' '
            )}
          </HStack>

          <Stack spacing="6" width={'80%'}>
            {wishListData.map(item => (
              <CartItem key={item.id} {...item} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
