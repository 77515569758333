import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  Text,

} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Button } from 'react-scroll';
import SyllabusForm from '../forms/SyllabusForm';
import MentorForm from '../forms/MentorForm';

export const ReviewSyllabus = ({ children, chapters }) => {
  const [chaptersJSON, setChaptersJSON] = useState('');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const handleChaptersJSONChange = json => {
    setChaptersJSON(json);
  };
  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        isCentered
        isOpen={isOpen}
        size={'xl'}
        scrollBehavior={'inside'}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Review Syllabus</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={1} alignItems={"flex-start"}>
              {chapters &&
                chapters.length > 0 &&
                chapters.map((el, i) => (
                  <>
                    <Text fontWeight={700}>
                      {' '}
                      {el.chapterNumber}) {el.chapterTitle}
                    </Text>

                    <ul>
                      {el.topics &&
                        el.topics.length > 0 &&
                        el.topics.map((el, i) => (
                          <>
                            <li key={i}>{el}</li>
                          </>
                        ))}
                    </ul>
                  </>
                ))}


            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
