import React, { useContext } from 'react';
import {
  Box,
  Heading,
  IconButton,
  Avatar,
  VStack,
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import EnvContext from '../../../../context/EnvContext';
import CourseContext from '../../../../context/CourseListContext';
import { useNavigate } from 'react-router-dom';
import { encryptData, generateTransactionID } from '../../../../Utils/function';

const SubscriptionPlanCard = ({ plan }) => {
  const secretKey = process.env.REACT_APP_CRYPTO_KEY;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { backendUrl } = useContext(EnvContext);
  const { setCourseId, courseId } = useContext(CourseContext);
  console.log('SubscriptionPlanCard -----------------', plan);
  const navigate = useNavigate();
  const handlePlayButtonClick = id => {
    // Redirect the user to another page

    console.log('card Clicked', id);

    setCourseId(id);
    navigate('/video_page');
  };

  const handleRenew = async () => {
    onClose();
    const userId = localStorage.getItem('userId');
    const merchantID = generateTransactionID();
    const encryptedMerchantID = encryptData(merchantID, secretKey);

    // Save the encrypted merchant ID to localStorage
    localStorage.setItem('merchantID', encryptedMerchantID);

    const planDuration = plan?.planDuration;
    console.log('planDuration', planDuration);
    const plans = plan?.plan?.plan;
    console.log('plans', plans);
    const matchingPlan = plans?.find(plan => plan.duration === planDuration);
    console.log('Original Price:', matchingPlan);
    const data = {
      isSubscription: true,
      subscriptionPlanId: plan?.plan?._id,
      planDuration: plan?.planDuration,
      isRenewal: true,
      userId: userId,
      courseName: plan?.plan?.name,
      originalPrice: matchingPlan?.originalPrice,
      offerPrice: matchingPlan?.offerPrice,
      renewalPrice: matchingPlan?.renewalPrice,
      merchantTransactionId: merchantID,
    };
    // console.log('data-------------', data);
    navigate('/renew/checkout', { state: { data } });
  };
  return (
    <Box borderWidth="1px" borderRadius="lg" p={4} width={'100%'}>
      {/* Header */}
      <HStack justifyContent="space-between">
        <Heading fontSize="lg">{plan.plan?.name}</Heading>
        <IconButton icon={<InfoIcon />} aria-label="Info" onClick={onOpen} />
      </HStack>

      {/* Courses list */}

      <Flex
        justifyContent={'flex-start'}
        alignItems={'start'}
        gap={5}
        mt={2}
        spacing={4}
        flexWrap={'wrap'}
      >
        {plan.plan.courses?.map((course, index) => (
          <HStack
            key={index}
            p={2}
            borderWidth="1px"
            borderRadius="md"
            bg={'gray.100'}
            _hover={{ bg: 'gray.200' }}
            onClick={() => handlePlayButtonClick(course._id)}
            cursor={'pointer'}
          >
            <Avatar size={'sm'} src={backendUrl + course.logo} />
            <Box>
              <Heading fontSize="sm">{course.courseName}</Heading>
              <Text fontSize="xs">Language: {course.language}</Text>
            </Box>
          </HStack>
        ))}
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Subscription Plan Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              <strong>Plan Name:</strong> {plan.plan?.name}
            </Text>
            <Text>
              <strong>Plan Duration:</strong> {plan?.planDuration} months
            </Text>
            {/* <Text>
              <strong>Renewal Price:</strong> $
              {plan?.plan
                ?.reduce((sum, course) => sum + course.offerPrice, 0)
                .toFixed(2)}
            </Text> */}
            <Text>
              <strong>Expiry Date:</strong>{' '}
              {new Date(plan?.expiryDate).toLocaleDateString()}
            </Text>
            <Text>
              <strong>Purchased On:</strong>{' '}
              {new Date(plan?.addedDate).toLocaleDateString()}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleRenew}>
              Renew
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SubscriptionPlanCard;
