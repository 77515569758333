import React, { useState, useContext, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Checkbox,
  Button,
  Stack,
  IconButton,
  InputGroup,
  InputRightElement,
  HStack,
  VStack,
  CircularProgress,
  CircularProgressLabel,
  Box,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import axios from 'axios';
import EnvContext from '../../context/EnvContext';
import CourseListContext from '../../context/CourseListContext';
import ContentContext from '../../context/ContentContext';
import toast, { Toaster } from 'react-hot-toast';

// const validationSchema = Yup.object().shape({
//   video: Yup.string().required('Video is required'),
//   title: Yup.string().required('Title is required'),
//   description: Yup.string().required('Description is required'),
//   notes: Yup.string().required('Notes are required'),
//   sl_no: Yup.number().required('Serial Number is required'),
// });

const UpdateContentForm = () => {
  const { content, contentId, fetchAllContent } = useContext(ContentContext);
  const { backendUrl } = useContext(EnvContext);
  const token = localStorage.getItem('token');
  const { courseId } = useContext(CourseListContext);
  const [isDoc, setIsDoc] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoFile, setVideoFile] = useState({});
  const [notesFile, setNotesFile] = useState({});
  const [docFile, setDocFile] = useState({});

  const handleInputChange = value => {
    if (value === 'yes') setIsDoc(true);
    else setIsDoc(false);
  };
  const [initialValues, setInitialValues] = useState({
    video: '',
    title: '',
    description: '',
    notes: '',
    sl_no: '',
    docContent: '',
  });
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (content) {
      setInitialValues({
        ...initialValues,
        sl_no: content.sl_no,
        title: content.title,
        description: content.description,
      });
      // if(contentId) console.log("contentId",contentId)
      console.log('sgahgsag', content?.sl_no);
    }
  }, [content]);
  const handleSubmit = async (values, actions) => {
    // Your form submission logic here
    console.log(values, isDoc);
    console.log('videoFile', videoFile);
    // console.log('notesFile', notesFile);
    // console.log('docFile',docFile)
    actions.setSubmitting(false);
    const formData = new FormData();
    formData.append('sl_no', values.sl_no);
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('video', videoFile);
    formData.append('notes', notesFile);
    formData.append('isDoc', isDoc);
    formData.append('docContent', docFile);
    let loadingToast;
    try {
      loadingToast = toast.loading('Updating Content...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });

      await axios
        .put(`${backendUrl}/course/content?contentId=${contentId}`, formData, {
          headers: headers,
          onUploadProgress: progressEvent => {
            //  Calculate the upload percentage
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentage);
          },
        })
        .then(res => {
          console.log(res);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          setUploadProgress(0);
          fetchAllContent();
        });
    } catch (err) {
      setUploadProgress(0);

      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field name="sl_no">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.sl_no && form.touched.sl_no}
                >
                  <FormLabel>Serial Number</FormLabel>
                  <Input {...field} type="number" placeholder="Serial Number" />
                  <FormErrorMessage>{form.errors.sl_no}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="title">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.title && form.touched.title}
                >
                  <FormLabel>Title</FormLabel>
                  <Input {...field} placeholder="Title" />
                  <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="description">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.description && form.touched.description
                  }
                >
                  <FormLabel>Description</FormLabel>
                  <Input {...field} placeholder="Description" />
                  <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="notes">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.notes && form.touched.notes}
                >
                  <FormLabel>Notes (PDF)</FormLabel>
                  {/* Your file upload logic here */}
                  <input
                    type="file"
                    onChange={e => setNotesFile(e.target.files[0])}
                  />
                  <FormErrorMessage>{form.errors.notes}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <HStack justifyContent={'space-evenly'} width={'100%'} my={'12px'}>
              <VStack width={'100%'}>
                <HStack spacing={4} width={'100%'}>
                  <p>Do you want to upload a Document?</p>
                  <RadioGroup
                    onChange={handleInputChange}
                    value={isDoc ? 'yes' : 'no'}
                  >
                    <Stack direction="row">
                      <Radio value="yes">Yes</Radio>
                      <Radio value="no">No</Radio>
                    </Stack>
                  </RadioGroup>
                </HStack>
                <HStack width={'100%'}>
                  {isDoc ? (
                    <Field name="docContent">
                      {({ field, form }) => (
                        <FormControl
                          isRequired
                          isInvalid={
                            form.errors.docContent && form.touched.docContent
                          }
                        >
                          <FormLabel>Upload PDF</FormLabel>
                          {/* Your file upload logic here */}
                          <input
                            type="file"
                            onChange={e => setDocFile(e.target.files[0])}
                          />
                          <FormErrorMessage>
                            {form.errors.docContent}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  ) : (
                    <FormControl>
                      <HStack>
                        <FormLabel>Upload Video</FormLabel>
                        <Button
                          as="label"
                          htmlFor="videoUpload"
                          type="button"
                          cursor="pointer"
                        >
                          Upload
                          <Field
                            id="videoUpload"
                            name="video"
                            type="file"
                            accept="video/mp4, video/avi, video/mov"
                            style={{ display: 'none' }}
                            onChange={e => setVideoFile(e.target.files[0])}
                          />
                        </Button>
                      </HStack>
                    </FormControl>
                  )}
                  <CircularProgress value={uploadProgress} color="green.400">
                    <CircularProgressLabel>{`${Math.round(
                      uploadProgress
                    )}%`}</CircularProgressLabel>
                  </CircularProgress>
                </HStack>
              </VStack>
            </HStack>

            
            <Stack spacing="6">
              <Button type="submit" isLoading={isSubmitting}>
                Submit
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
      <Toaster />
    </Box>
  );
};

export default UpdateContentForm;
