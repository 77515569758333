import React, { useContext, useEffect, useState } from 'react';
import { FieldArray, Field, Form, Formik } from 'formik';
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Box,
  HStack,
  IconButton,
  Text,
} from '@chakra-ui/react';

import { TiDelete } from 'react-icons/ti';
import { MdAddBox } from 'react-icons/md';
import SyllabusContext from '../../../context/SyllabusContext';
import { ReviewSyllabus } from '../../../components/modals/ReviewSyllabus';

export const UpdateSyllabus = () => {
    const [clicked,setClicked]=useState(false);
  const { syllabusData, updateSyllabus } = useContext(SyllabusContext);
  const [initialValues, setInitialValues] = useState({
    chapters: [
      {
        chapterNumber: '',
        chapterTitle: '',
        topics: [''],
      },
    ],
  });

  useEffect(() => {
    if (initialValues) console.log('initialValues', initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (syllabusData) {
      setInitialValues(syllabusData);
    }
  }, [syllabusData]);
  const handleSubmit = (values, actions) => {
    console.log('update syllabus values', values);
    const postData = {
      courseName: syllabusData.courseName,
      chapters: values.chapters,
    };

    console.log(postData);

    updateSyllabus(syllabusData._id,postData);
    actions.resetForm();
    actions.setSubmitting(false);
  };

  return (
    <Box maxH={'80vh'} width={'100%'} p={3} overflowY="auto">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values }) => (
          <Form style={{ width: '90%' }}>
            <FieldArray name="chapters">
              {({ insert, remove, push }) => (
                <div>
                  {values.chapters &&
                    values.chapters.length > 0 &&
                    values.chapters.map((chapter, index) => (
                      <Box key={index} marginBottom="4">
                        {/* <Text mb={5}>
                          <strong>CourseName: </strong>
                          {courseName}
                        </Text> */}
                        <Field name={`chapters.${index}.chapterNumber`}>
                          {({ field, form }) => (
                            <FormControl
                              isRequired
                              isInvalid={
                                form.errors.chapters &&
                                form.errors.chapters[index] &&
                                form.touched.chapters &&
                                form.touched.chapters[index] &&
                                form.touched.chapters[index].chapterNumber
                              }
                            >
                              <FormLabel>Chapter Number</FormLabel>
                              <Input
                                {...field}
                                type="number"
                                placeholder="Enter chapter number"
                              />
                              <FormErrorMessage>
                                {form.errors.chapters &&
                                  form.errors.chapters[index] &&
                                  form.errors.chapters[index].chapterNumber}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name={`chapters.${index}.chapterTitle`}>
                          {({ field, form }) => (
                            <FormControl
                              isRequired
                              isInvalid={
                                form.errors.chapters &&
                                form.errors.chapters[index] &&
                                form.touched.chapters &&
                                form.touched.chapters[index] &&
                                form.touched.chapters[index].chapterTitle
                              }
                            >
                              <FormLabel>Chapter Title</FormLabel>
                              <Input
                                {...field}
                                type="text"
                                placeholder="Enter chapter title"
                              />
                              <FormErrorMessage>
                                {form.errors.chapters &&
                                  form.errors.chapters[index] &&
                                  form.errors.chapters[index].chapterTitle}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <FieldArray name={`chapters.${index}.topics`}>
                          {({ push: pushTopic, remove: removeTopic }) => (
                            <div>
                              {chapter.topics.map((topic, topicIndex) => (
                                <div key={topicIndex}>
                                  <Field
                                    name={`chapters.${index}.topics.${topicIndex}`}
                                  >
                                    {({ field, form }) => (
                                      <FormControl>
                                        <FormLabel>
                                          Topic {topicIndex + 1}
                                        </FormLabel>
                                        <HStack spacing={2}>
                                          <Input
                                            {...field}
                                            placeholder={`Enter topic ${
                                              topicIndex + 1
                                            }`}
                                          />
                                          {topicIndex > 0 && (
                                            <TiDelete
                                              style={{
                                                fontSize: '2rem',
                                                color: 'red',
                                                cursor: 'pointer',
                                              }}
                                              onClick={() =>
                                                removeTopic(topicIndex)
                                              }
                                            />
                                          )}
                                          <MdAddBox
                                            style={{
                                              fontSize: '2rem',
                                              color: '#00800094',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => pushTopic('')}
                                          />
                                        </HStack>
                                      </FormControl>
                                    )}
                                  </Field>
                                </div>
                              ))}
                            </div>
                          )}
                        </FieldArray>

                        <Button
                          mt="2"
                          variant="outline"
                          colorScheme="red"
                          size="sm"
                          onClick={() => remove(index)}
                        >
                          Remove Chapter
                        </Button>
                      </Box>
                    ))}
                  <HStack>
                    <Button
                      mt="2"
                      variant="solid"
                      colorScheme="green"
                      size="sm"
                      onClick={() => {
                        push({
                          chapterNumber: values.chapters.length + 1, // Incrementing chapter number
                          chapterTitle: '',
                          topics: [''],
                        });
                        const json = JSON.stringify(values.chapters, null, 2);
                        // setChaptersJSON(json);
                        // onChaptersJSONChange(json); // Passing JSON to parent component
                      }}
                    >
                      Add Chapter
                    </Button>
                  </HStack>
                </div>
              )}
            </FieldArray>
            <HStack>
            <ReviewSyllabus chapters={values.chapters}>
              <Button mt="4" colorScheme="orange" mb={6} onClick={()=>setClicked(true)}>
                Review Syllabus
              </Button>
            </ReviewSyllabus>
            {
                clicked &&  <Button mt="4" colorScheme="teal" type="submit" mb={6}>
              Submit
            </Button>
            }
            </HStack>
           


          </Form>
        )}
      </Formik>
    </Box>
  );
};
