import React, { useContext, useEffect } from 'react'
import EnvContext from '../context/EnvContext';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';

function DashboardTour({setShowPopup ,startTour}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { backendUrl } = useContext(EnvContext);
  const { handleUserData } = useContext(AuthContext);
  const handleDashboardTour = async ()=>{
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try{
      await axios.put(`${backendUrl}/user/dash_tour`, null ,{
        headers: headers
      })
      .then((res)=>{
        console.log("dash_tour:",res)
        handleUserData()
      })

    }catch(err){
      console.log(err)
    }
  }

  useEffect(()=>{
    onOpen()
  },[])


  return (
//     <div className='rock'>
//     <div className="body">
//         <p>Do You want to take a tour</p>
//         <div className="button">
//             <button class="bn54" onClick={()=>{
//                 handleDashboardTour()
//                 setShowPopup(false);
//                 startTour()
//             }}>
//                 <span class="bn54span">Yes</span>
//             </button>
//             <button class="bn54" style={{backgroundColor:"#d9a9a2"}} onClick={()=>{
//               handleDashboardTour()
//               setShowPopup(false)}}>
//                 <span class="bn54span">No</span>
//             </button>
//         </div>
//     </div>
// </div>

<Modal onClose={onClose}  isOpen={isOpen} isCentered >
<ModalOverlay   />
<ModalContent alignItems={'center'}>
  <ModalHeader> Do yu want to take a tour ?</ModalHeader>
  <ModalCloseButton />
  <ModalFooter gap={9}>
  <Button
  onClick={()=>{
                handleDashboardTour()
                setShowPopup(false);
                startTour()
            }}
  >Yes</Button>
    <Button
    onClick={()=>{
              handleDashboardTour()
              setShowPopup(false)}}
     >NO</Button>
  </ModalFooter>
</ModalContent>
</Modal>
  )
}

export default DashboardTour
