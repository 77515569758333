import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  keyframes,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useState, useEffect, useContext } from 'react';
import '../../../allcss/hero.css';
import Lottie from 'react-lottie';
import {
  annimation,
  livaClassAnnimation,
  projectanimation,
} from '../../../assets/annimation';
import { UserStories } from '../../../components/others/UserStories';
import { v4 as uuidv4 } from 'uuid';
import { HomeCarouselCard } from '../../../components/elements/HomeCarouselCard';
import { Carroussel } from '../../../components/others/Carroussel';

import {
  sql,
  python,
  java,
  js,
  html,
  mongo_db,
  css,
  node_js,
  my_sql,
} from '../../../assets/images';
import EnvContext from '../../../context/EnvContext';
import CourseContext from '../../../context/CourseListContext';
import axios from 'axios';
import useSaveLastRoute from '../../../hooks/useSaveLastRoute';
import homeBackground from '../../../assets/images/homeBackground.png';
import Home_content from '../../../components/user/Home_content';
import CryptoJS from 'crypto-js';
import ResponsiveCarrousel from '../../../components/others/ResponsiveCarrousel';
import ScrollingLogo from '../../../components/layouts/ScrollingLogo';

export const cryptokey = 'asdfghjkl';
export const Home = () => {
  useSaveLastRoute();
  const { setTopCourses } = useContext(CourseContext);
  const { backendUrl } = useContext(EnvContext);
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [course, setCourse] = useState([]);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isTab] = useMediaQuery('(max-width: 992px)');

  function renderCard(data) {
    console.log("datadatadatadatadata",data)
    return <HomeCarouselCard imagen={data.banner} title={data.courseName} />;
  }
  const [cards, setCards] = useState([]);

  useEffect(() => {
    if (course && course.length > 0) {
      const newCards = course
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by createdAt in descending order
        .slice(0, 9) // Take the first 4 items
        .map(data => ({
          key: uuidv4(),
          content: renderCard(data),
        }));

      setCards(newCards);
      const firstFive = course.slice(0, 5);
      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(firstFive),
        cryptokey
      ).toString();
      localStorage.setItem('firstFive', ciphertext);
      setTopCourses(firstFive);
    }
  }, [course]);

  useEffect(() => {
    if (cards && cards.length > 0) console.log(cards);
  }, [cards])

  const getDefaultOptions = animationData => {
    let defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return defaultOptions;
  };
  const keyframMobile = `
  @keyframes color2 {
    0% {transform: translateX(-10px);}
    100% {transform: translateX(-200px);}
}  `;
  const keyfram = `
  @keyframes color2 {
    0% {transform: translateX(-10px);}
    100% {transform: translateX(-580px);}
}
  `;

  useEffect(() => {
    try {
      const fetchData = async () => {
        await axios
          .get(`${backendUrl}/course/recent`, { headers: headers })
          .then(res => {
            console.log(res.data.courses);
            setCourse(res.data.courses);
          })
          .catch(err => {
            console.log(err);
          });
      };
      fetchData();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Box>
      <Box
        w="100%"
        minH={{
          base: '200px',
          md: 'calc(100vh - 3em)',
          lg: 'calc(100vh - 3em)',
        }}
        backgroundImage={`url(${homeBackground})`}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="white"
        fontSize="24px"
        position={'relative'}
      >
        <Flex
          paddingY={{
            base: '50px',
            md: '',
          }}
          h="100%"
          w="100%"
          flexDirection={'column'}
          alignItems={'center'}
          justify="center"
          gap={{
            base: '10px',
            md: '1rem',
            lg: '2rem',
          }}
          position={'absolute'}
          width={'100%'}
          style={{
            backgroundColor: 'rgb(255 255 255 / 80%)',
          }}
        >
          <Heading
            textShadow="0px 0px 10px white"
            textAlign="center"
            fontSize={{
              base: '2xl',
              md: '4xl',
              lg: '6xl',
            }}
            color="black"
          >
            Empower Your Future <br />
            <Text as="span">
              {' '}
              with Expert-Led{' '}
              <Text
                as="span"
                textShadow="none"
                style={{
                  background:
                    'linear-gradient(180deg, rgb(90 208 255) 0%, rgb(0 50 97) 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Self-Paced Courses
              </Text>
            </Text>
          </Heading>

          <Text
            color="black"
            fontSize={{
              base: 'sm',
              md: 'xl',
              lg: '2xl',
            }}
            style={{ fontWeight: 'bold', textAlign: 'center' }}
            textShadow="0px 0px 5px white"
            px={{
              base: '10px',
              lg: '200px',
            }}
          >
            "Unlock new skills, earn recognized certifications, and achieve
            excellence at your own pace with our industry-standard courses and
            projects."
          </Text>
        </Flex>
      </Box>
      <Home_content />

      <style>{isTab ? keyframMobile : keyfram}</style>

      <ScrollingLogo />

      {isMobile ? (
        <Box>
          <Heading
            as="h2"
            size="xl"
            lineHeight={'1.4em'}
            textAlign="center"
            mb={2}
          >
            Latest Courses
          </Heading>
          <ResponsiveCarrousel courseList={course} />
        </Box>
      ) : (
        cards &&
        cards.length > 4 && (
          <Flex
            alignContent={'center'}
            justifyContent={'flex-start'}
            p={7}
            flexDirection={'column'}
            gap={'3rem'}
          >
            <Heading as="h2" size="xl" lineHeight={'1.4em'} textAlign="center">
              Our Latest Courses
            </Heading>
            <Carroussel
              cards={cards}
              height="500px"
              width="100%"
              margin="0 auto"
              offset={2}
              showArrows={false}
            />
          </Flex>
        )
      )}

      <UserStories />
    </Box>
  );
};
