import React, { useContext, useEffect, useRef } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  Image,
  Flex,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
  IconButton,
  InputLeftAddon,
  InputLeftElement,
  FormErrorMessage,
  Modal,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';

import { useState } from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { logo } from '../../assets/images';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { GiCloudUpload } from 'react-icons/gi';
import { CiCamera } from 'react-icons/ci';
import { BsCameraFill } from 'react-icons/bs';
import AuthContext from '../../context/AuthContext';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  phone: Yup.string()
    .matches(/^\d{10}$/, 'Invalid phone number')
    .required('Phone number is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters long')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Please confirm your password'),
});

export const SignUp = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showPassword, setShowPassword] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePath, setImagePath] = useState({});
  const fileInputDocRef = useRef(null);
  const { userClean, setUser } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [tempUserId, setTempUserId] = useState(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  let loadingToast;
  const lastRoute = localStorage.getItem('lastRoute');
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (token && lastRoute) {
      navigate(lastRoute);
    }
  }, []);

  useEffect(() => {
    if (showModal) onOpen();
  }, [showModal]);

  const handleFileSelection = e => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      setImagePath(selectedImage);
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  const handleFlexClick = () => {
    fileInputDocRef.current.click();
  };
  const handleSubmit = async (values, { setSubmitting }) => {
    console.log(imagePath);
    const formData = new FormData();
    formData.append('name', values.firstName + ' ' + values.lastName);
    formData.append('email', values.email);
    formData.append('password', values.password);
    formData.append('confirmPassword', values.confirmPassword);
    formData.append('phone', values.phone);
    formData.append('dp', imagePath);

    // const formDataArray = Array.from(formData.entries());

    // // Log each key-value pair
    // formDataArray.forEach(([key, value]) => {
    //   console.log(`${key}: ${value}`);
    // });

    try {
      loadingToast = toast.loading('Registration is going on ....', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios.post(`${BACKEND_URL}/user/signup`, formData).then(res => {
        console.log('res.data.data', res.data.data);
        toast.success(res.data.message, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
        setSubmitting(false);
        const value = {
          email: values.email,
          password: values.password
        }
        setUser(value);
        // navigate('/login');
        setShowModal(res.data.data.isOldUser);
        setTempUserId(res.data.data.userId);
      });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };
  const handleButtonClick = () => {
    const userRetrieved = window.confirm(
      'User retrieved. Do you want to login?'
    );
    if (userRetrieved) {
      navigate('/login'); // Navigate to login page
    }
  };

  return (
    // TODO: Design need to be changed
    <Container
      maxW="lg"
      py={{
        base: '2',
        md: '5',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          {/* <Logo /> */}
          <Flex justifyContent={'center'}>
            <Image w={'4rem'} src={logo} alignItems={'center'}></Image>
          </Flex>

          <Stack
            spacing={{
              base: '2',
              md: '3',
            }}
            textAlign="center"
          >
            <Heading fontSize={'3xl'}>Registration Form</Heading>
            <Text color="fg.muted" fontSize={'xl'}>
              Already have an account?{' '}
              <Link color="blue" onClick={() => navigate('/login')}>
                Log In
              </Link>
            </Text>
          </Stack>
        </Stack>
        <Box
          py={{
            base: '0',
            sm: '8',
          }}
          px={{
            base: '4',
            sm: '10',
          }}
          bg={{
            base: 'transparent',
            sm: 'bg.surface',
          }}
          boxShadow={{
            base: 'md',
            sm: 'md',
          }}
          borderRadius={{
            base: 'none',
            sm: 'xl',
          }}
        >
          <Stack spacing="6">
            <Stack spacing="5">
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  phone: '',
                  password: '',
                  confirmPassword: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Flex
                      justifyContent={'space-between'}
                      flexDirection={'row-reverse'}
                    >
                      <Flex
                        flexDirection={'column'}
                        onClick={handleFlexClick}
                        position={'relative'}
                        boxSize="120px"
                        onMouseEnter={() => setShowUploadButton(true)}
                        onMouseLeave={() => setShowUploadButton(false)}
                      >
                        <Image
                          borderRadius="full"
                          boxSize="120px"
                          src={image || 'https://bit.ly/dan-abramov'}
                          alt="Dan Abramov"
                        />
                        <Button
                          type="button"
                        // onClick={() => handleFileInput(fileInputDocRef)}
                        >
                          Upload Pic
                          <input
                            ref={fileInputDocRef}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileSelection}
                          />
                        </Button>
                        {showUploadButton && (
                          <Flex
                            bg={'#00000099'}
                            position="absolute"
                            top="0"
                            left="0"
                            w={'100%'}
                            h={'100%'}
                            borderRadius="full"
                            justifyContent={'center'}
                          >
                            <button>
                              <BsCameraFill size={'4rem'} color="white" />
                            </button>
                          </Flex>
                        )}
                      </Flex>

                      <Flex flexDirection={'column'}>
                        <Field name="firstName">
                          {({ field, form }) => (
                            <FormControl
                              isRequired
                              isInvalid={
                                form.errors.firstName && form.touched.firstName
                              }
                            >
                              <FormLabel>First Name</FormLabel>
                              <Input {...field} placeholder="Your First Name" />
                              <FormErrorMessage>
                                {form.errors.firstName}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                        <Field name="lastName">
                          {({ field, form }) => (
                            <FormControl
                              isRequired
                              isInvalid={
                                form.errors.lastName && form.touched.lastName
                              }
                            >
                              <FormLabel>Last Name</FormLabel>
                              <Input {...field} placeholder="Your Last Name" />
                              <FormErrorMessage>
                                {form.errors.lastName}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Flex>
                    </Flex>

                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isRequired
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <FormLabel>Email</FormLabel>
                          <Input {...field} placeholder="Email" />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="phone">
                      {({ field, form }) => (
                        <FormControl
                          isRequired
                          isInvalid={form.errors.phone && form.touched.phone}
                        >
                          <FormLabel>Phone number</FormLabel>
                          <Input {...field} placeholder="Phone number" />
                          <FormErrorMessage>
                            {form.errors.phone}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="password">
                      {({ field, form }) => (
                        <FormControl
                          isRequired
                          isInvalid={
                            form.errors.password && form.touched.password
                          }
                        >
                          <FormLabel>Password</FormLabel>
                          <InputGroup>
                            <InputRightElement>
                              <IconButton
                                variant="text"
                                aria-label={
                                  showPassword
                                    ? 'Hide password'
                                    : 'Reveal password'
                                }
                                icon={showPassword ? <HiEyeOff /> : <HiEye />}
                                onClick={() => setShowPassword(!showPassword)}
                              />
                            </InputRightElement>
                            <Input
                              {...field}
                              type={showPassword ? 'text' : 'password'}
                              autoComplete="new-password"
                              placeholder="Password"
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.password}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="confirmPassword">
                      {({ field, form }) => (
                        <FormControl
                          isRequired
                          isInvalid={
                            form.errors.confirmPassword &&
                            form.touched.confirmPassword
                          }
                        >
                          <FormLabel>Confirm Password</FormLabel>
                          <InputGroup>
                            <InputRightElement>
                              <IconButton
                                variant="text"
                                aria-label={
                                  showConfirmPassword
                                    ? 'Hide password'
                                    : 'Reveal password'
                                }
                                icon={
                                  showConfirmPassword ? <HiEyeOff /> : <HiEye />
                                }
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                              />
                            </InputRightElement>
                            <Input
                              {...field}
                              type={showConfirmPassword ? 'text' : 'password'}
                              autoComplete="new-password"
                              placeholder="Confirm Password"
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.confirmPassword}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Stack spacing="6" marginTop={4}>
                      <Button type="submit" isLoading={isSubmitting}>
                        Sign Up
                      </Button>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <Modal onClose={onClose} size={'lg'} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Account Recovery</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Do you want to delete your old Data</Text>
          </ModalBody>
          <ModalFooter>
            {/* <Button onClick={onClose}>Close</Button> */}
            <HStack spacing={6}>
              <Button
                colorScheme="blue"
                onClick={() =>
                  userClean(tempUserId).then(() => handleButtonClick())
                }
              >
                Yes
              </Button>
              <Button colorScheme="red" onClick={handleButtonClick}>
                No
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};
