import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { Button } from 'react-scroll';
import SyllabusForm from '../forms/SyllabusForm';
import MentorForm from '../forms/MentorForm';
import EnvContext from '../../context/EnvContext';

export const AssignmentOpener = ({ children, questions }) => {
  const { backendUrl } = useContext(EnvContext);
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        isCentered
        isOpen={isOpen}
        size={'xl'}
        scrollBehavior={'inside'}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Attached Notes</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Accordion>
              {questions &&
                questions.length > 0 &&
                questions.map((question, i) => (
                  <AccordionItem key={i}>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          Q({i + 1}) : {question.question}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <VStack alignItems={"flex-start"}>
                        {question?.options && question?.options.length>0 &&
                          question?.options.map((option, i) => (
                            <Text key={i}>{i+1}. {option}</Text>
                          ))}
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
            </Accordion>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
