import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Button } from 'react-scroll';
import SyllabusForm from '../forms/SyllabusForm';

export const AddSyllabus = ({ children ,courseName }) => {
  const [chaptersJSON, setChaptersJSON] = useState('');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const handleChaptersJSONChange = json => {
    setChaptersJSON(json);
  };
  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        isCentered
        isOpen={isOpen}
        size={'full'}
        scrollBehavior={'inside'}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Syllabus</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack spacing={10}>
              <VStack width={'50%'} boxShadow={'lg'} rounded={'md'}>
                <SyllabusForm onChaptersJSONChange={handleChaptersJSONChange} courseName={courseName}  />
              </VStack>
              <VStack width={'50%'} maxH={"80vh"} overflowY="auto">
                <h2>Chapters JSON:</h2>
                <pre>{chaptersJSON}</pre>
              </VStack>
            </HStack>
          </ModalBody>

          
        </ModalContent>
      </Modal>
    </>
  );
};
