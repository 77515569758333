import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  useDisclosure,
  Heading,
  Grid,
  GridItem,
  Link,
  useConst,
  useMediaQuery,
} from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import { FiHome } from 'react-icons/fi';
import { CgProfile } from 'react-icons/cg';
import { IoBookSharp } from 'react-icons/io5';
import { IoSettingsOutline } from 'react-icons/io5';
import { UserSetting } from '../modals/UserSetting';
import { GrTransaction } from 'react-icons/gr';

const UserDashboardSidebar = ({ elementId, elementId1, elementId2,elementId3 }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <>
      {isMobile ? null : (
        <Flex
          position={'sticky'}
          flexDirection={'column'}
          top={3}
          boxShadow={
            '-2px 0px 6px rgba(0, 0, 0, 0.04),-2px 0px 15px rgba(0, 0, 0, 0.04)'
          }
          borderRadius={'15px'}
          padding={'13px 10px'}
          gap={'10px'}
        >
          <Flex flexDirection={'column'} gap={'10px'}>
            <NavLink
              id={elementId?.replace('#', '')}
              to={`/bioDetails`}
              textDecoration={'none'}
              className="dashboardNavlink"
              style={{ fontSize: '1rem', fontWeight: 600 }}
              onClick={() => window.scrollTo(0, 0)}
            >
              <CgProfile style={{ marginRight: '10px' }} />
              Profile
            </NavLink>

            <NavLink
              id={elementId1?.replace('#', '')}
              to={`/bio`}
              textDecoration={'none'}
              className="dashboardNavlink"
              style={{ fontSize: '1rem', fontWeight: 600 }}
              onClick={() => window.scrollTo(0, 0)}
            >
              <CgProfile style={{ marginRight: '10px' }} />
              Bio
            </NavLink>

            <Box minHeight={'150px'}>
              <NavLink
                to={'/dashboard'}
                textDecoration={'none'}
                className="dashboardNavlink"
                style={{ fontSize: '1rem', fontWeight: 600 }}
                onClick={() => window.scrollTo(0, 0)}
              >
                <FiHome style={{ marginRight: '10px' }} />
                My Learnings
              </NavLink>
            </Box>

            <NavLink
            id={elementId2?.replace('#', '')}
              to={`/all_transactions`}
              textDecoration={'none'}
              className="dashboardNavlink"
              style={{ fontSize: '1rem', fontWeight: 600 }}
              onClick={() => window.scrollTo(0, 0)}
            >
              <GrTransaction style={{ marginRight: '10px' }} />
              Transactions
            </NavLink>
            <UserSetting>
              <Link
                id={elementId3?.replace('#', '')}
                // to={`/dashboard/settings`}
                textDecoration={'none'}
                className="dashboardNavlink"
                style={{ fontSize: '1rem', fontWeight: 600 }}
                onClick={() => window.scrollTo(0, 0)}
              >
                <IoSettingsOutline style={{ marginRight: '10px' }} />
                Settings
              </Link>
            </UserSetting>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default UserDashboardSidebar;
