import React from 'react';
import { Box, Text, VStack, useMediaQuery } from '@chakra-ui/react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { useLocation } from 'react-router-dom';

const BarChartComponent = ({ datas }) => {
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');
  const formatYAxis = tickItem => {
    return `${tickItem}%`;
  };
  const formatTooltip = (value, name) => {
    return `${value}%`;
  };
  const location = useLocation();
  const text = 'Overall Scoring';
  return (
    <>
      {location.pathname === '/admin/home' ? (
        <Box maxW="500px" mx="auto">
          <BarChart width={500} height={300} data={datas}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="courseName" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="enrollmentCount" fill="#8884d8" />
          </BarChart>
        </Box>
      ) : (
        <VStack pt={0} mt={0}>
          {text && (
            <Text textAlign="center" fontSize="lg" fontWeight="bold" mb={4}>
              {text}
            </Text>
          )}
          <BarChart
            width={isLargerThan600 ? 600 : 350}
            height={isLargerThan600 ? 400 : 250}
            data={datas}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={formatYAxis} />
            <Tooltip formatter={formatTooltip} />
            <Legend />
            <Bar dataKey="Total" fill="#4CAF50" />
            <Bar dataKey="Completed" fill="#8080ff" />
          </BarChart>
        </VStack>
      )}
    </>
  );
};

export default BarChartComponent;
