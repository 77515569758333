import {
  Flex,
  Avatar,
  Box,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  IconButton,
  Stack,
  Select,
  VStack,
  Heading,
} from '@chakra-ui/react';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import AuthContext from '../../context/AuthContext';
import toast from 'react-hot-toast';
import UserDashboardSidebar from '../layouts/UserDashboardSidebar';
import useSaveLastRoute from '../../hooks/useSaveLastRoute';

const validationSchema = Yup.object().shape({
  collegeName: Yup.string().required('CollegeName is required'),
  motherName: Yup.string().required('MotherName is required'),
  fatherName: Yup.string().required('FatherName is required'),
  degree: Yup.string().required('Degree is required'),
  branch: Yup.string().required('Branch is required'),
  dob: Yup.string().required('dob is required'),
  yop: Yup.string().required('yop is required'),
  bloodGroup: Yup.string().required('bloodGroup is required'),
  aadhar: Yup.string()
    .matches(
      /^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$/,
      'Invalid Aadhar format'
    )
    .required('aadhar is required'),
  pan: Yup.string()
    .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Invalid PAN format')
    .required('pan is required'),
});
export const Bio = () => {
  useSaveLastRoute();
  const { userData, handleUserData } = useContext(AuthContext);
  const [bio, setBio] = useState(false);
  const [id, setId] = useState('');
  const [initialValues, setInitialValues] = useState({
    collegeName: '',
    motherName: '',
    fatherName: '',
    degree: '',
    branch: '',
    dob: '',
    yop: '',
    bloodGroup: '',
    aadhar: '',
    pan: '',
  });
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  let loadingToast;

  useEffect(() => {
    console.log('userData:', userData);
    if (userData.bio) {
      setId(userData.bio._id);
      setInitialValues({
        ...initialValues,
        collegeName: userData.bio.collegeName,
        motherName: userData.bio.motherName,
        fatherName: userData.bio.fatherName,
        degree: `${userData.bio.degree}`,
        branch: userData.bio.branch,
        yop: userData.bio.yop,
        bloodGroup: userData.bio.bloodGroup,
        pan: userData.bio.pan,
        aadhar: userData.bio.aadhar,
        dob: new Date(userData.bio.dob).toISOString().substr(0, 10),
      });

      setBio(true);
    }
  }, [userData]);

  useEffect(() => {
    handleUserData();
  }, []);

  const addBio = async values => {
    try {
      loadingToast = toast.loading('adding data is going on ....', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .post(`${BACKEND_URL}/user/bio/create`, values, { headers: headers })
        .then(res => {
          console.log('res.data', res.data);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };
  const updateBio = async values => {
    try {
      loadingToast = toast.loading('Update data is going on ....', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .put(`${BACKEND_URL}/user/bio/update?bioId=${id}`, values, {
          headers: headers,
        })
        .then(res => {
          console.log(res);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (!bio) {
      addBio(values);
      setSubmitting(false);
    } else {
      updateBio(values);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Flex
        className="userHome"
        flexDirection={'row'}
        // w={'92%'}
        alignItems={'flex-start'}
        margin={'auto'}
        marginTop={10}
        maxW={'1300px'}
        justifyContent={'flex-start'}
        gap={20}
      >
        <UserDashboardSidebar />
        <VStack>
          <Flex
            width={'100%'}
            position={'sticky'}
            bg={'white'}
            margin={{
              base: 0,
              lg: '0.75rem',
            }}
            padding={3}
            marginBottom={0}
            paddingBottom={7}
            paddingTop={4}
            top={0}
            zIndex={1}
          >
            <Heading
              pl={5}
              fontSize={'4xl'}
              lineHeight={{
                base: '2.2rem',
                lg: '1.2rem',
              }}
              bg={'white'}
            >
              {userData && userData.bio
                ? 'Update your bio details'
                : 'Add your bio details '}
            </Heading>
          </Flex>

          <Box
            width={'100%'}
            py={{
              base: '0',
              sm: '8',
            }}
            px={{
              base: '4',
              sm: '10',
            }}
            bg={{
              base: 'white',
              sm: 'bg.surface',
            }}
            boxShadow={{
              base: 'none',
              md: 'md',
            }}
            borderRadius={{
              base: 'none',
              sm: 'xl',
            }}

            // zIndex={'-1'}
          >
            <Formik
              initialValues={initialValues}
              // validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Field name="collegeName">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                      >
                        <FormLabel>CollegeName</FormLabel>
                        <Input {...field} placeholder="CollegeName" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="fatherName">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                      >
                        <FormLabel>FatherName</FormLabel>
                        <Input {...field} placeholder="fatherName" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="motherName">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                      >
                        <FormLabel>MotherName</FormLabel>
                        <Input {...field} placeholder="MotherName" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="degree">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                      >
                        <FormLabel>Degree</FormLabel>
                        <Input {...field} placeholder="Degree" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="branch">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                      >
                        <FormLabel>Branch</FormLabel>
                        <Input {...field} placeholder="Branch" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="dob">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                      >
                        <FormLabel>dob</FormLabel>
                        <Input type="date" {...field} placeholder="dob" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="yop">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                      >
                        <FormLabel>yop</FormLabel>
                        <Input type="number" {...field} placeholder="yop" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="bloodGroup">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                      >
                        <FormLabel>bloodGroup</FormLabel>
                        <Select {...field} placeholder="bloodGroup">
                          <option value="A+">A+</option>
                          <option value="A-">A-</option>
                          <option value="B+">B+</option>
                          <option value="B-">B-</option>
                          <option value="AB+">AB+</option>
                          <option value="AB-">AB-</option>
                          <option value="O+">O+</option>
                          <option value="O-">O-</option>
                        </Select>
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="aadhar">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                      >
                        <FormLabel>aadhar</FormLabel>
                        <Input type="number" {...field} placeholder="aadhar" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="pan">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                      >
                        <FormLabel>pan</FormLabel>
                        <Input {...field} placeholder="pan" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Stack spacing="6" mt={3}>
                    <Button type="submit" isLoading={isSubmitting}>
                      Submit
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>
        </VStack>
      </Flex>
    </>
  );
};
