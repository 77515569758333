import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import EnvContext from '../../../../context/EnvContext';
import axios from 'axios';
import toast from 'react-hot-toast';

export const ReadingStreak = () => {
  const [weekDatas, setWeekDatas] = useState([]);
  const { backendUrl } = useContext(EnvContext);
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');

  function getDayInitial(i) {
    switch (i) {
      case 0:
        return 'S';
      case 1:
        return 'M';
      case 2:
        return 'T';
      case 3:
        return 'W';
      case 4:
        return 'T';
      case 5:
        return 'F';
      case 6:
        return 'S';
      default:
        return '';
    }
  }

  function formatDate(date) {
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  function getWeekDatas(records, i) {
    const today = new Date();
    const currentDay = today.getDay(); // 0 for Sunday, 1 for Monday, etc.
    const startDate = new Date(today);
    startDate.setDate(startDate.getDate() - currentDay - i * 7); // Start of the current week
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6); // End of the current week

    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const dateString = formatDate(new Date(currentDate));
      const rtnobj = filterByDate(records, dateString);
      dates.push({ date: dateString, record: rtnobj });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  function filterByDate(data, dateString) {
    const targetDate = new Date(dateString);
    const isoDateString = new Date(
      Date.UTC(
        targetDate.getFullYear(),
        targetDate.getMonth(),
        targetDate.getDate()
      )
    )
      .toISOString()
      .split('T')[0]; // Extracting only the date part
    return data.find(obj => obj.date.startsWith(isoDateString));
  }

  function getDates(noOfWeeks) {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedToday = `${year}-${month}-${day}`;

    const xDaysAgo = new Date();
    xDaysAgo.setDate(xDaysAgo.getDate() - noOfWeeks * 7 - 5);
    const xDaysAgoYear = xDaysAgo.getFullYear();
    const xDaysAgoMonth = String(xDaysAgo.getMonth() + 1).padStart(2, '0');
    const xDaysAgoDay = String(xDaysAgo.getDate()).padStart(2, '0');
    const FormattedxDaysAgo = `${xDaysAgoYear}-${xDaysAgoMonth}-${xDaysAgoDay}`;

    return {
      today: formattedToday,
      xDaysAgo: FormattedxDaysAgo,
    };
  }

  useEffect(() => {
    const getData = (records, noOfWeeks) => {
      const data = [];
      for (let i = 0; i < noOfWeeks; i++) {
        data.push(getWeekDatas(records, i));
      }
      setWeekDatas(data);
    };

    const fetchRecords = async noOfWeeks => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const { today, xDaysAgo } = getDates(noOfWeeks);

        const response = await axios.get(
          `${backendUrl}/user/rs/records?userId=${userId}&startDate=${xDaysAgo}&endDate=${today}`,
          config
        );

        getData(response.data.data, noOfWeeks);
      } catch (error) {
        toast.error(
          error.response ? error.response.data.message : 'Something Went Wrong',
          {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          }
        );
      }
    };

    fetchRecords(7);
  }, []);

  return (
    <>
      {weekDatas.map((weekData, index) => (
        <div key={index} style={{ margin: '10px 0px 10px 0px' }}>
          <Flex justify={'space-between'}>
            <Text mb={'5px'}>{weekData[0].date}</Text>
            <Text mb={'5px'}>-</Text>
            <Text mb={'5px'}>{weekData[6].date}</Text>
          </Flex>
          <Flex justify={'space-between'}>
            {weekData.map((data, dataIndex) => (
              <Box key={dataIndex}>
                <Box
                  width={'25px'}
                  height={'25px'}
                  display="flex"
                  alignItems={'center'}
                  justifyContent={'center'}
                  borderRadius={'50%'}
                  bg={data.record ? 'blue' : 'lightgray'}
                  color={data.record ? 'white' : 'black'}
                  fontWeight={600}
                >
                  {getDayInitial(dataIndex)}
                </Box>
                {!data.record && <Box mt="3px" height={'5px'}></Box>}
                {data.record && (
                  <Box
                    display="flex"
                    flexGrow={1}
                    justifyContent={'space-around'}
                    mt="3px"
                  >
                    {data.record.contentWatched.length > 0 && (
                      <div
                        style={{
                          height: '5px',
                          width: '5px',
                          background: 'red',
                          borderRadius: '50%',
                        }}
                      ></div>
                    )}
                    {data.record.assignmentCompleted.length > 0 && (
                      <div
                        style={{
                          height: '5px',
                          width: '5px',
                          background: 'green',
                          borderRadius: '50%',
                        }}
                      ></div>
                    )}
                    {data.record.projectCompleted.length > 0 && (
                      <div
                        style={{
                          height: '5px',
                          width: '5px',
                          background: 'blue',
                          borderRadius: '50%',
                        }}
                      ></div>
                    )}
                  </Box>
                )}
              </Box>
            ))}
          </Flex>
        </div>
      ))}
    </>
  );
};
