const courseFeatures = [
    {
        id:6,
        title:"Enrollment",
        description:"  Students begin their journey by enrolling in our platform, gaining access to our extensive library of courses."
    },
    {
      id: 1,
      title: "Course Selection",
      description: "Learners choose from a variety of specialized coding courses tailored to their interests and career goals."
    },
    {
      id: 2,
      title: "Real-Time Learning",
      description: "Our instructors engage students in live, interactive lessons, ensuring a dynamic and immersive learning experience."
    },
    {
      id: 3,
      title: "Project Integration",
      description: "Practical knowledge is gained through real-time Live Project work, where students apply what they've learned in a real-world context."
    },
    {
      id: 4,
      title: "Skill Mastery",
      description: "Through consistent practice, students master coding skills that empower them in their careers."
    },
    {
      id: 5,
      title: "Certification",
      description: "Upon successful completion, students receive certifications to validate their expertise."
    }
  ];

  export default courseFeatures