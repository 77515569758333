import {
  Button,
  Drawer,
  DrawerBody,
  useDisclosure,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Box,
  VStack,
  Text,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../App.css';
import AssignmentContext from '../../context/AssignmentContext';

export const AdminPreviewAnswer = ({ children, optionImages, filteredTO }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { answers, setAnswers, selectedItems, setSelectedItems } =
    useContext(AssignmentContext);
  const btnRef = React.useRef();
  useEffect(() => {
    if (filteredTO) {
      console.log('filteredTO', filteredTO);
    }
  }, [filteredTO]);
  useEffect(() => {
    if (optionImages) {
      console.log('optionImages', optionImages);
    }
  }, [optionImages]);

  const handleCheckboxImageChange = item => {
    const fileName = item.name; // Assuming item has a 'name' property containing the file name
    if (selectedItems?.includes(fileName)) {
      setSelectedItems(
        selectedItems?.filter(selectedItem => selectedItem !== fileName)
      );
    } else {
      // setSelectedItems([...selectedItems, fileName]);
      if (selectedItems?.length > 0)
        setSelectedItems([...selectedItems, fileName]);
      else setSelectedItems([fileName]);
    }
  };
  const handleCheckboxChange = item => {
    if (selectedItems?.includes(item)) {
      setSelectedItems(
        selectedItems?.filter(selectedItem => selectedItem !== item)
      );
    } else {
      if (selectedItems?.length > 0) setSelectedItems([...selectedItems, item]);
      else setSelectedItems([item]);
    }
  };

  useEffect(() => {
    console.log('Selected Checkboxes:', selectedItems);
  }, [selectedItems]);
  return (
    <Box className="admin-sidebar">
      <span onClick={onOpen}>{children}</span>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={'lg'}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader marginBottom={5}></DrawerHeader>

          <DrawerBody>
            {filteredTO.map((item, index) => (
              <div key={index}>
                <input
                  type="checkbox"
                  id={`checkbox-${index}`}
                  checked={selectedItems?.includes(item)}
                  onChange={() => handleCheckboxChange(item)}
                />
                <label htmlFor={`checkbox-${index}`}>{item}</label>
              </div>
            ))}
            {
              <>
                {optionImages &&
                  optionImages.map((item, index) => (
                    <div key={index}>
                      <input
                        type="checkbox"
                        id={`image-checkbox-${index}`}
                        checked={selectedItems?.includes(item.name)} // Adjust index to avoid conflict
                        onChange={() => handleCheckboxImageChange(item)} // Adjust index to avoid conflict
                      />
                      <label htmlFor={`image-checkbox-${index}`}>
                        Option {index + 1}
                      </label>
                      <img
                        src={URL.createObjectURL(item)}
                        alt={`Option ${index + 1}`}
                      />
                      <p>{item.description}</p>
                    </div>
                  ))}
              </>
            }
            {selectedItems &&
            (selectedItems?.length > 0 ||
              optionImages.length > 0 ||
              filteredTO.length > 0) ? (
              <Button
                colorScheme="teal"
                onClick={() => {
                  onClose();
                  setAnswers(selectedItems);
                }}
              >
                Submit Answer
              </Button>
            ) : (
              <Text fontWeight={600}>First add some options to review</Text>
            )}
          </DrawerBody>

          {/* <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue">Save</Button>
            </DrawerFooter> */}
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
