import {
  Box,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  InputGroup,
  InputRightElement,
  IconButton,
  Button,
} from '@chakra-ui/react';
import { Form, Formik, Field } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import toast from 'react-hot-toast';
import axios from 'axios';
import * as Yup from 'yup';
import AuthContext from '../../context/AuthContext';

const validationSchema = Yup.object().shape({

  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Please confirm your password'),
});

function UserPassword() {
  const { userData, handleUserData } = useContext(AuthContext);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [showForm, setShowForm] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const token = localStorage.getItem('token');

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(()=>{
    console.log('userpassworduserData',userData)
  },[userData])
let loadingToast; 
  const handleSubmit = async (values, { setSubmitting }) => {
    console.log(values)
    try {
      loadingToast = toast.loading("Updating Profile ...", {
        position: 'top-right',
        style: {
          marginTop: '62px',
        }
      });

      axios
        .put(`${BACKEND_URL}/user/update`, values, {
          headers: headers,
        })
        .then(res => {
          console.log('res', res.data.message);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            }
          })
          setShowForm(false);
          handleUserData().then(() => {
            setShowForm(true);
          });
        }).catch((err) =>
        toast.error(`${err.response.data.message}`,{
          position: 'top-right',
          style: {
            marginTop: '62px',
          }
        })

        )
    } catch (err) {
      toast.error(`${err.response.data.message}`,{
        position: 'top-right',
        style: {
          marginTop: '62px',
        }
      })

    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }

  };
  return (
    <Box>
      <Heading size={'lg'} mb={5}>
        Change Password
      </Heading>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form className="userupdateprofileform">
            <Field name="password">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.password && form.touched.password}
                >
                  <FormLabel>Password</FormLabel>
                  <InputGroup>
                    <InputRightElement>
                      <IconButton
                        variant="text"
                        aria-label={
                          showPassword ? 'Hide password' : 'Reveal password'
                        }
                        icon={showPassword ? <HiEyeOff /> : <HiEye />}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </InputRightElement>
                    <Input
                      {...field}
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="new-password"
                      placeholder="Password"
                    />
                  </InputGroup>
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage> 
                </FormControl>
              )}
            </Field>

            <Field name="confirmPassword">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.confirmPassword && form.touched.confirmPassword
                  }
                >
                  <FormLabel>Confirm Password</FormLabel>
                  <InputGroup>
                    <InputRightElement>
                      <IconButton
                        variant="text"
                        aria-label={
                          showConfirmPassword
                            ? 'Hide password'
                            : 'Reveal password'
                        }
                        icon={showConfirmPassword ? <HiEyeOff /> : <HiEye />}
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                    </InputRightElement>
                    <Input
                      {...field}
                      type={showConfirmPassword ? 'text' : 'password'}
                      autoComplete="new-password"
                      placeholder="Confirm Password"
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    {form.errors.confirmPassword}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Stack spacing="6" mt={5}>
              <Button type="submit" isLoading={isSubmitting}>
                Submit
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default UserPassword;
