import React, { useContext } from 'react';
import {
  Box,
  Text,
  Button,
  VStack,
  HStack,
  Icon,
  Divider,
  Stack,
  List,
  ListItem,
  ListIcon,
  Tooltip,
  Flex,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import CourseContext from '../../../../context/CourseListContext';
import { CustomCard } from '../../authentication/video/VideoList';
import { useNavigate } from 'react-router-dom';
import { encryptData, generateTransactionID } from '../../../../Utils/function';

const SubscriptionCard2 = ({ el, plan }) => {
  const secretKey = process.env.REACT_APP_CRYPTO_KEY;

  const { setCourseId } = useContext(CourseContext);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const handleAddSuscription = plan => {
    if (!token) {
      const confirm = window.confirm(
        'You are not logged in, you will re-directed to Login/Sign Up page'
      );
      if (confirm) {
        navigate('/login');
        localStorage.setItem('savedLocation', '/subscription_plans');
      }
    } else {
      const userId = localStorage.getItem('userId');
      const merchantID = generateTransactionID();
      const encryptedMerchantID = encryptData(merchantID, secretKey);

      // Save the encrypted merchant ID to localStorage
      localStorage.setItem('merchantID', encryptedMerchantID);
      const data = {
        isSubscription: true,
        subscriptionPlanId: el._id,
        planDuration: plan.duration,
        isRenewal: false,
        userId: userId,
        courseName: el.name,
        originalPrice: plan?.originalPrice,
        offerPrice: plan?.offerPrice,
        merchantTransactionId: merchantID,
      };
      navigate('/subscription_plans/checkout', { state: { data } });
    }
  };
  return (
    <VStack
      // maxW="lg"
      width={{
        base: '25rem',
        md: '23rem',
        lg: '25rem',
      }}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="xl"
      bg="gray.50"
      height={{
        base: '77vh',
        md: '50vh',
        lg: '77vh',
      }}
      borderColor={'purple.200'}
    >
      {el && (
        <Box p="6" width={'100%'} h={'100%'}>
          <VStack
            spacing={4}
            align="stretch"
            h={'100%'}
            justifyContent={'space-between'}
          >
            <Box>
              <Text fontSize="3xl" fontWeight="bold" color="purple.600">
                {el.name}
              </Text>
              <Stack spacing={0} align="start">
                <HStack>
                  <Text fontWeight={'bold'} mt={2}>
                    Included Courses:
                  </Text>
                </HStack>
                <Flex
                  wrap={'wrap'}
                  spacing={3}
                  maxH={'38vh'}
                  overflowY={'auto'}
                >
                  {/* {el?.courses &&
                  Array.isArray(el?.courses) &&
                  el?.courses.map((el, i) => (
                    <Tooltip
                      label="Click to explore"
                      key={i}
                      aria-label="A tooltip"
                    >
                      <ListItem
                        onClick={() => {
                          setCourseId(el?._id);
                          navigate('/indi_course');
                          console.log('course Id', el?._id);
                        }}
                        cursor={'pointer'}
                      >
                        <ListIcon as={CheckIcon} color="purple.500" />
                        {el.courseName}
                      </ListItem>
                    </Tooltip>
                  ))} */}
                  {el?.courses &&
                    Array.isArray(el?.courses) &&
                    el?.courses.map((el, i) => (
                      <React.Fragment key={i}>
                        <Tooltip
                          label="Click to explore"
                          key={i}
                          aria-label="A tooltip"
                        >
                          <Text
                            as={'u'}
                            color={'blue'}
                            cursor={'pointer'}
                            onClick={() => {
                              setCourseId(el?._id);
                              navigate('/indi_course');
                            }}
                          >
                            {el.courseName}
                          </Text>
                        </Tooltip>
                        {i !== el.length - 1 && ', '}
                      </React.Fragment>
                    ))}
                </Flex>
              </Stack>
            </Box>
            <VStack gap={'10px'} align="start">
              {el?.plan?.map((el, i) => (
                <HStack
                  key={i}
                  border="1px"
                  borderColor={`purple.500`}
                  borderRadius="md"
                  padding="2"
                  width={'100%'}
                >
                  <HStack
                    spacing="20px"
                    justifyContent={'space-between'}
                    width={'100%'}
                  >
                    <VStack align="start" gap={'0.2rem'}>
                      <HStack spacing="8px">
                        <Text
                          as={'u'}
                          fontSize="xl"
                          fontWeight="bold"
                          color="purple.600"
                        >
                          Plan {i + 1}:
                        </Text>
                        <Text
                          className="strikethroughSub"
                          fontSize="lg"
                        >{`₹${el?.originalPrice}`}</Text>
                        <Text
                          fontSize="lg"
                          color={`purple.500`}
                        >{`₹${el?.offerPrice}`}</Text>
                      </HStack>
                      <Text fontSize="md">{`For ${el?.duration} months`}</Text>
                      <Text fontSize="md">{`Renewal Price: ₹${el?.renewalPrice}`}</Text>
                    </VStack>
                    <Button
                      colorScheme={'purple'}
                      onClick={() => handleAddSuscription(el)}
                    >
                      Buy Now
                    </Button>
                  </HStack>
                </HStack>
              ))}
            </VStack>
            {/* <Divider borderColor="purple.200" />
            <Text fontSize="xl" fontWeight="bold" color="purple.600">
              Starting at{' '}
              <Text className="strikethroughSub">₹{plan?.originalPrice}</Text> ₹
              {plan?.offerPrice} per month
            </Text>
            <Text
              fontSize="sm"
              fontWeight="bold"
              color="purple.600"
              mt={'-17px'}
            >
              Renewal Price :- ₹{plan?.renewalPrice}
            </Text>
            <Button
              colorScheme="purple"
              size="lg"
              width="full"
              onClick={handleAddSuscription}
            >
              Start subscription
            </Button> */}
          </VStack>
        </Box>
      )}
    </VStack>
  );
};

export default SubscriptionCard2;
