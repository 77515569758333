import React, { useState, useContext, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Checkbox,
  Button,
  Stack,
  IconButton,
  InputGroup,
  InputRightElement,
  HStack,
  VStack,
  CircularProgress,
  CircularProgressLabel,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import axios from 'axios';
import EnvContext from '../../context/EnvContext';
import CourseContext from '../../context/CourseListContext';
import toast, { Toaster } from 'react-hot-toast';
import ContentContext from '../../context/ContentContext';

// const validationSchema = Yup.object().shape({
//   video: Yup.string().required('Video is required'),
//   title: Yup.string().required('Title is required'),
//   description: Yup.string().required('Description is required'),
//   notes: Yup.string().required('Notes are required'),
//   sl_no: Yup.number().required('Serial Number is required'),
// });

const AddContentForm = () => {
  const { backendUrl } = useContext(EnvContext);
  const token = localStorage.getItem('token');
  const { courseId } = useContext(CourseContext);
  const { latestSlNo, fetchAllContent } = useContext(ContentContext);
  const [isDoc, setIsDoc] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoFile, setVideoFile] = useState({});
  const [notesFile, setNotesFile] = useState({});
  const [docFile, setDocFile] = useState({});
  const handleInputChange = value => {
    if (value === 'yes') setIsDoc(true);
    else setIsDoc(false);
  };
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [initialValues, setInitialValues] = useState({
    video: '',
    title: '',
    description: '',
    notes: '',
    sl_no: '',
    docContent: '',
  });
  useEffect(() => {
    if (latestSlNo)
      setInitialValues({
        ...initialValues,
        sl_no: latestSlNo,
      });
  }, [latestSlNo]);

  const handleSubmit = async (values, actions) => {
    // Your form submission logic here
    console.log(values, isDoc);
    console.log('videoFile', videoFile);
    // console.log('notesFile', notesFile);
    // console.log('docFile',docFile)
    actions.setSubmitting(false);
    const formData = new FormData();
    formData.append('sl_no', values.sl_no);
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('video', videoFile);
    formData.append('notes', notesFile);
    formData.append('isDoc', isDoc);
    formData.append('docContent', docFile);
    let loadingToast;
    try {
      loadingToast = toast.loading('Adding Content...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });

      await axios
        .post(
          `${backendUrl}/course/content/add?courseId=${courseId}`,
          formData,
          {
            headers: headers,
            onUploadProgress: progressEvent => {
              //  Calculate the upload percentage
              const percentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentage);
            },
          }
        )
        .then(res => {
          console.log(res);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          fetchAllContent();
          setUploadProgress(0);
        });
    } catch (err) {
      setUploadProgress(0);

      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const HandleVideoUpload = (e) => {
    console.log('File input event:', e);
    const file = e.target.files[0];
    console.log('Selected Video File:', file);
    if (file) {
      setVideoFile(file);
    } else {
      console.error('No file selected');
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting }) => (
        <Form>
          <Field name="sl_no">
            {({ field, form }) => (
              <FormControl isInvalid={form.errors.sl_no && form.touched.sl_no}>
                <FormLabel>Serial Number</FormLabel>
                <Input {...field} type="number" placeholder="Serial Number" />
                <FormErrorMessage>{form.errors.sl_no}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="title">
            {({ field, form }) => (
              <FormControl isInvalid={form.errors.title && form.touched.title}>
                <FormLabel>Title</FormLabel>
                <Input {...field} placeholder="Title" />
                <FormErrorMessage>{form.errors.title}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="description">
            {({ field, form }) => (
              <FormControl
                isInvalid={form.errors.description && form.touched.description}
              >
                <FormLabel>Description</FormLabel>
                <Input {...field} placeholder="Description" />
                <FormErrorMessage>{form.errors.description}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="notes">
            {({ field, form }) => (
              <FormControl
                isInvalid={form.errors.notes && form.touched.notes}
              >
                <FormLabel>Notes (PDF)</FormLabel>
                {/* Your file upload logic here */}
                <input
                  type="file"
                  onChange={e => setNotesFile(e.target.files[0])}
                />
                <FormErrorMessage>{form.errors.notes}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <HStack justifyContent={'space-evenly'} width={'100%'} my={'12px'}>
            <VStack width={'100%'}>
              <HStack spacing={4} width={'100%'}>
                <p>Do you want to upload a Document?</p>
                <RadioGroup
                  onChange={handleInputChange}
                  value={isDoc ? 'yes' : 'no'}
                >
                  <Stack direction="row">
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Stack>
                </RadioGroup>
              </HStack>
              <HStack width={'100%'}>
                {isDoc ? (
                  <Field name="docContent">
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={
                          form.errors.docContent && form.touched.docContent
                        }
                      >
                        <FormLabel>Upload PDF</FormLabel>
                        {/* Your file upload logic here */}
                        <input
                          type="file"
                          onChange={e => setDocFile(e.target.files[0])}
                        />
                        <FormErrorMessage>
                          {form.errors.docContent}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                ) : (
                  <FormControl>
                    <HStack>
                      <FormLabel>Upload Video</FormLabel>
                   
                        <input
                          type="file"
                          accept="video/mp4, video/avi, video/mov"
                          onChange={e => setVideoFile(e.target.files[0])}
                        />
                     
                    </HStack>
                  </FormControl>
                )}
                <CircularProgress value={uploadProgress} color="green.400">
                  <CircularProgressLabel>{`${Math.round(
                    uploadProgress
                  )}%`}</CircularProgressLabel>
                </CircularProgress>
              </HStack>
            </VStack>
          </HStack>

          <Stack spacing="6">
            <Button type="submit" isLoading={isSubmitting}>
              Submit
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default AddContentForm;
