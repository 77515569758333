import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  Image,
  Divider,
  Spinner,
  Link as ChakraLink,
  Button,
  Spacer,
  useColorModeValue,
  StackDivider,
  Text,
  Avatar,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useMediaQuery,
  MenuDivider,
  CircularProgress,
  CircularProgressLabel,
  useDisclosure,
} from '@chakra-ui/react';
import { logo } from '../../assets/images';
import { GiTrophyCup } from 'react-icons/gi';
import ContentContext from '../../context/ContentContext';
import CourseContext from '../../context/CourseListContext';
import AuthContext from '../../context/AuthContext';
import { CustomNavLink } from '../elements/CustomNavLink ';
import { NavLink, useNavigate, useRouteLoaderData } from 'react-router-dom';
import EnvContext from '../../context/EnvContext';
import WishListContext from '../../context/WishListContext';

export const UserHeader = ({ startTour, courseData }) => {
  const { userData } = useContext(AuthContext);
  const { getWishList } = useContext(WishListContext);
  const { handelSignOut } = useContext(AuthContext);
  const { backendUrl } = useContext(EnvContext);
  const color = useColorModeValue('gray.800', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { contents, scorecard } = useContext(ContentContext);
  const { getComplitionCertificate, courseId } = useContext(CourseContext);
  const userId = localStorage.getItem('userId');
  const user = 'user';
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isTab] = useMediaQuery('(max-width: 992px)');
  const dp = localStorage.getItem('dp');
  const navigate = useNavigate();
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    if (contents && scorecard) {
      if (
        Array.isArray(contents) &&
        contents.length > 0 &&
        Array.isArray(scorecard.watchedContents) &&
        scorecard.watchedContents.length > 0
      ) {
        const contentsLength = contents.length;
        const watchedContentsLength = scorecard.watchedContents.length;
        setPercentage((watchedContentsLength / contentsLength) * 100);
      }
    }
  }, [contents, scorecard]);

  useEffect(() => {
    if (courseId) console.log('courseId', courseId);
  }, [courseId]);

  const handleWishList = () => {
    navigate('/wishList');
    getWishList();
  };
  return (
    <>
      <Box color={color} py={3} px={10}>
        <Flex justifyContent="space-between" alignItems="center">
          <HStack>
            <HStack
              onClick={() => navigate('/')}
              cursor={'pointer'}
              flexDirection={{
                base: 'column',
                lg: 'row',
              }}
            >
              <HStack>
                <Image src={logo} alt="Snappify Logo" boxSize="40px" />

                <Heading
                  fontSize={{
                    base: '15px',
                    md: '20px',
                    lg: '27px',
                  }}
                >
                  SOLP
                </Heading>
              </HStack>
            </HStack>
            <Divider orientation="vertical" />
            {isMobile ? '' : <Text>{courseData?.courseName}</Text>}
          </HStack>
          <HStack gap={5}>
            <Button colorScheme="teal" variant="ghost">
              <NavLink to={'/dashboard'}>Dashboard</NavLink>
            </Button>

            <Menu isOpen={isOpen} zIndex={1000} onClose={onClose}>
              <MenuButton onClick={isOpen ? onClose : onOpen}>
                {/* User avatar */}
                <Avatar
                  size="md"
                  name={localStorage.getItem('name')}
                  src={`${backendUrl}${dp}`}
                />
              </MenuButton>
              <MenuList zIndex={999}>
                {isMobile && (
                  <>
                    <MenuItem onClick={onClose}>
                      <NavLink to={'/products'}>Products</NavLink>
                    </MenuItem>
                    <MenuItem onClick={onClose}>
                      <NavLink to={'/subscription_plans'}>
                        Subscription Plans
                      </NavLink>
                    </MenuItem>
                    <MenuItem>Contact Us</MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={onClose}>
                      <NavLink to={'/dashboard'}>Dashboard</NavLink>
                    </MenuItem>
                    <MenuItem onClick={onClose}>
                      <NavLink to={`/${user}/dashboard/bio`}>
                        Bio Details
                      </NavLink>
                    </MenuItem>
                  </>
                )}
                <MenuItem> Hi {userData?.name}</MenuItem>
                <MenuItem onClick={handleWishList}>WishList</MenuItem>

                <MenuItem onClick={startTour}>Take Tour</MenuItem>
                <MenuDivider />
                <MenuItem _hover={{ bg: 'transparent' }}>
                  <Button colorScheme="blue" size="sm" onClick={handelSignOut}>
                    Logout
                  </Button>
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Flex>
      </Box>
    </>
  );
};
