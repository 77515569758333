import React, { useContext, useEffect, useMemo, useState } from 'react';
import ProjectContext from '../../../context/ProjectContext';
import {
  Table,
  Thead,
  Tbody,
  Box,
  Text,
  Tr,
  Th,
  Td,
  IconButton,
  Stack,
  ChakraProvider,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Menu,
  MenuButton,
  MenuList,
  PopoverArrow,
  PopoverCloseButton,
  MenuItem,
  useDisclosure,
  FocusLock,
  Textarea,
  HStack,
} from '@chakra-ui/react';
import { BiEdit } from 'react-icons/bi';
import * as XLSX from 'xlsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { getUniqueUserProjects } from '../../../Utils/function';
import { ChevronDownIcon } from '@chakra-ui/icons';

const validationSchema = Yup.object({
  score: Yup.number()
    .min(0, 'Score must be at least 0')
    .max(100, 'Score must be at most 100')
    .required('Score is required'),
  review: Yup.string().required('Review is required'),
  result: Yup.string().required('Result is required'),
});
const SubmittedProject = () => {
  const location = useLocation();
  const { submittedProjects, giveProjectScores, getProjectSubmission } =
    useContext(ProjectContext);
  const [sortBy, setSortBy] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [sortedProjects, setSortedProjects] = useState([]);
  const [formData, setFormData] = useState({
    score: 0,
    review: '',
    result: '',
  });
  const initialFormData = {
    score: 0,
    review: '',
    result: '',
  };
  const UserId = localStorage.getItem('UserId');
  const ProjectId = localStorage.getItem('ProjectId');
  const CourseID = localStorage.getItem('CourseID');
  useEffect(() => {
    if (UserId) {
      getProjectSubmission(undefined, undefined, UserId);
    } else if (ProjectId) {
      getProjectSubmission(ProjectId, undefined, undefined);
    } else if (CourseID) {
      getProjectSubmission(undefined, CourseID, undefined);
    }
  }, [refresh]);
  const role = localStorage.getItem('role');

  const navigate = useNavigate();

  useEffect(() => {
    console.log('submittedProjectsvalue', submittedProjects);
    if (showAll) setSortedProjects(submittedProjects);
    else setSortedProjects(getUniqueUserProjects(submittedProjects));
  }, [submittedProjects, showAll]);

  useEffect(() => {
    if (role !== 'admin') navigate('/dashboard');
  }, [role]);

  const resetFormData = () => {
    setFormData(initialFormData);
  };
  const [openPopoverIndex, setOpenPopoverIndex] = useState(null);

  const handlePopoverOpen = index => {
    console.log('handlePopoverOpen', index);
    setOpenPopoverIndex(index === openPopoverIndex ? null : index);
  };
  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = values => {
    console.log('submitedprojectValues', values);
    const postData = {
      score: values.score,
      review: values.review,
      result: values.result,
    };
    if (postData) {
      giveProjectScores(sortedProjects[openPopoverIndex]._id, postData).then(
        () => {
          resetFormData();
          setRefresh(refresh + 1);
        }
      );
    }

    console.log(formData); // You can handle form submission here
  };
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = React.useRef(null);

  const handleSort = key => {
    if (key === sortBy) {
      // If already sorted by the selected key, reverse the order
      setSortBy(null);
    } else {
      setSortBy(key);
    }
  };
  const formatDate = dateString => {
    if (!dateString) return ''; // Return empty string if date string is not provided

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    // Get the date in YYYY-MM-DD format
    return date.toISOString().split('T')[0];
  };

  const exportToExcel = () => {
    const data = sortedProjects.flatMap(el => {
      if (!el.submissionLinks || el.submissionLinks.length === 0) {
        return [
          {
            'Project Name': el.project?.name || '',
            'Required Links': 'No Required Links',
            'Submission Link': 'No Submission Links',
            'Start Time': formatDate(el.startTime),
            'Completion Time': formatDate(el.completionTime),
            Result: el.result || 'No Result',
          },
        ];
      } else {
        return el.submissionLinks.map((link, index) => ({
          'Project Name': index === 0 ? el.project?.name || '' : '',
          'Required Links': el.project?.submissionLinks?.[index] || '',
          'Submission Link': {
            t: 's',
            v: link,
            l: {
              Target: link,
              Tooltip: 'Click to open link',
            },
          },
          'Start Time': index === 0 ? formatDate(el.startTime) : '',
          'Completion Time': index === 0 ? formatDate(el.completionTime) : '',
          Result: index === 0 ? el.result || 'No Result' : '',
        }));
      }
    });

    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(data);

    // Change font color of the 'Submission Link' column to blue
    const range = XLSX.utils.decode_range(sheet['!ref']);
    for (let row = range.s.r; row <= range.e.r; row++) {
      const cellAddr = XLSX.utils.encode_cell({ r: row, c: 2 }); // Assuming 'Submission Link' is in the third column (index 2)
      if (sheet[cellAddr]) {
        sheet[cellAddr].s = {
          font: {
            color: {
              rgb: '0000FF',
            },
          },
        }; // Set font color to blue
      }
    }

    XLSX.utils.book_append_sheet(workbook, sheet, 'Projects');
    XLSX.writeFile(workbook, 'projects.xlsx');
  };

  useEffect(() => {
    const history = localStorage.getItem('history');
    if (submittedProjects.length == 0) {
      if (history === '/admin/home') {
        const UserId = localStorage.getItem('UserId');
        console.log('ApiUserId', UserId);
        getProjectSubmission(undefined, undefined, UserId);
      } else if (history === '/admin/courses') {
        const courseId = localStorage.getItem('CourseID');
        getProjectSubmission(undefined, courseId, undefined);
      } else if (history === '/admin/manage_projects') {
        const ProjectId = localStorage.getItem('ProjectId');
        getProjectSubmission(ProjectId, undefined, undefined);
      }
    }
  }, [submittedProjects]);

  return (
    <Stack alignItems={'center'}>
      <HStack justifyContent={'space-evenly'} width={'100%'} mb={3}>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Handle Projects
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => setShowAll(true)}>All Projects</MenuItem>
            <MenuItem onClick={() => setShowAll(false)}>
              Submitted Projects
            </MenuItem>
          </MenuList>
        </Menu>
        <Button colorScheme="blue" onClick={exportToExcel} width={'10rem'}>
          Export to Excel
        </Button>
      </HStack>

      <Table size="sm" width={'80%'}>
        <Thead>
          <Tr>
            <Th>Serial No</Th>
            <Th onClick={() => handleSort('name')}>
              Project Name {sortBy === 'name' ? '🔼' : '🔽'}
            </Th>
            <Th>Required Links</Th>
            <Th>Submitted Links</Th>
            <Th onClick={() => handleSort('startTime')}>
              Start Time {sortBy === 'startTime' ? '🔼' : '🔽'}
            </Th>
            <Th onClick={() => handleSort('completionTime')}>
              Completion Time {sortBy === 'completionTime' ? '🔼' : '🔽'}
            </Th>
            <Th onClick={() => handleSort('result')}>
              Result {sortBy === 'completionTime' ? '🔼' : '🔽'}
            </Th>
            <Th>Score</Th>
            <Th>Add Score</Th>
          </Tr>
        </Thead>
        <Tbody>
          {Array.isArray(sortedProjects) &&
            sortedProjects.length > 0 &&
            sortedProjects.map((el, i) => (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td>{el.project?.name}</Td>
                <Td>
                  {el.project?.submissionLinks &&
                  Array.isArray(el.project?.submissionLinks) &&
                  el.project?.submissionLinks.length > 0 ? (
                    el.project?.submissionLinks.map((el, i) => (
                      <li key={i}>{el}</li>
                    ))
                  ) : (
                    <p>Nothing</p>
                  )}
                </Td>
                <Td>
                  {el.submissionLinks &&
                  Array.isArray(el.submissionLinks) &&
                  el.submissionLinks.length > 0 ? (
                    el.submissionLinks.map((el, i) => (
                      <li key={i}>
                        <a href={el} key={i} target="_blank" rel="noreferrer">
                          {el}
                        </a>
                      </li>
                    ))
                  ) : (
                    <p>No Submission Links</p>
                  )}
                </Td>
                <Td>{formatDate(el.startTime)}</Td>
                <Td>{formatDate(el.completionTime)}</Td>
                <Td>
                  {el.result ? (
                    <Text
                      fontWeight={700}
                      color={el.result === 'Fail' ? 'red' : 'green'}
                    >
                      {el.result}
                    </Text>
                  ) : (
                    <p> No Result</p>
                  )}
                </Td>
                <Td>{el.score ? el.score : 0}</Td>
                <Td>
                  {el.completionTime && el.result ? (
                    ''
                  ) : (
                    <Popover
                      isOpen={openPopoverIndex === i}
                      onOpen={() => handlePopoverOpen(i)}
                      onClose={() => handlePopoverOpen(i)}
                      initialFocusRef={firstFieldRef}
                      placement="right"
                      closeOnBlur={false}
                    >
                      <PopoverTrigger>
                        <IconButton size="sm" icon={<BiEdit size={25} />} />
                      </PopoverTrigger>
                      <PopoverContent p={5}>
                        <FocusLock returnFocus persistentFocus={false}>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <ChakraProvider>
                            <VStack spacing={4} width="300px">
                              <Formik
                                initialValues={initialFormData}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                              >
                                {({ errors, touched }) => (
                                  <Form>
                                    <FormControl
                                      id="score"
                                      isInvalid={errors.score && touched.score}
                                    >
                                      <FormLabel>Score</FormLabel>
                                      <Field
                                        as={Input}
                                        type="number"
                                        name="score"
                                      />
                                      {errors.score && touched.score && (
                                        <div>{errors.score}</div>
                                      )}
                                    </FormControl>

                                    <FormControl
                                      id="review"
                                      isInvalid={
                                        errors.review && touched.review
                                      }
                                    >
                                      <FormLabel>Review</FormLabel>
                                      <Field
                                        as={Textarea}
                                        type="text"
                                        name="review"
                                      />
                                      {errors.review && touched.review && (
                                        <div>{errors.review}</div>
                                      )}
                                    </FormControl>

                                    <FormControl
                                      id="result"
                                      isInvalid={
                                        errors.result && touched.result
                                      }
                                    >
                                      <FormLabel>Result</FormLabel>
                                      <Field as={Select} name="result">
                                        <option value="">Select Result</option>
                                        <option value="Pass">Pass</option>
                                        <option value="Fail">Fail</option>
                                      </Field>
                                      {errors.result && touched.result && (
                                        <div>{errors.result}</div>
                                      )}
                                    </FormControl>

                                    <Button
                                      type="submit"
                                      colorScheme="blue"
                                      mt={4}
                                    >
                                      Submit
                                    </Button>
                                  </Form>
                                )}
                              </Formik>
                            </VStack>
                          </ChakraProvider>
                        </FocusLock>
                      </PopoverContent>
                    </Popover>
                  )}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Stack>
  );
};

export default SubmittedProject;
