import React, { useState, useRef, useContext } from 'react';
import {
  Box,
  Image,
  Button,
  Text,
  HStack,
  Center,
  Flex,
  FormControl,
  Input,
  FormLabel,
  keyframes,
  useBreakpointValue,
} from '@chakra-ui/react';
import { contactUs } from '../../assets/images';
import toast from 'react-hot-toast';
import { Formik, Form, Field } from 'formik';
import { CiMail } from 'react-icons/ci';
import * as Yup from 'yup';
import { FaArrowRight, FaPhoneAlt } from 'react-icons/fa';
import ChatBot1 from '../user/contactUs/ChatBot1';
import EnvContext from '../../context/EnvContext';
import axios from 'axios';

const moveRight = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(10%);
  }
`;

const ContactUsPage = () => {
  const [showArrowCard, setArrowCard] = useState(false);
  const [display, setDisplay] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const { backendUrl } = useContext(EnvContext);

  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const messageRef = useRef(null);
  const nameRef = useRef(null);

  const handleShowCard = () => {
    setDisplay(true);
    setArrowCard(false);
    if (window.innerWidth < 768) {
      setMobileView(true);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    phone: Yup.string().required('Phone number is required'),
    message: Yup.string().required('Message is required'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axios.post(`${backendUrl}/contact`, values);
      toast.success('Form submitted successfully');
      resetForm();
    } catch (error) {
      toast.error('Failed to submit the form');
    } finally {
      setSubmitting(false);
    }
  };

  const handleKeyDown = (e, ref) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      ref.current?.focus();
    }
  };

  // Responsive values for text size and spacing
  const fontSize = useBreakpointValue({ base: '1.5rem', md: '2rem' });
  const marginTop = useBreakpointValue({ base: '4rem', md: '8rem' });
  const imageHeight = useBreakpointValue({ base: '40vh', md: '70vh' });
  const imageWidth = useBreakpointValue({ base: '100%', md: '100%' });
  const formWidth = useBreakpointValue({ base: '20rem', md: '28rem' });
  const formHeight = useBreakpointValue({ base: '25rem', md: '25rem' });
  const boxShadow = useBreakpointValue({ base: 'md', md: 'dark-lg' });
  const marginLeft = useBreakpointValue({ base: '4rem', md: '8rem' });

  return (
    <Box>
      <HStack
        height={imageHeight}
        width={imageWidth}
        justifyContent="space-between"
        position="relative"
      >
        <Box height={imageHeight} width={imageWidth} position="relative">
          <Image height="100%" width="100%" src={contactUs} alt="Contact Us" />
          <Box
            position="absolute"
            top="50px"
            left="10px"
            width="100%"
            height="50vh"
            textAlign="center"
          >
            <Text
              color="white"
              fontSize={fontSize}
              fontWeight={700}
              textAlign="center"
              mt={marginTop}
              letterSpacing="wide"
              fontFamily="Raddit Mono"
            >
              Get in touch <br /> with us!
            </Text>
            <Box
              position="absolute"
              left="50%"
              transform="translateX(-50%)"
              mt="2rem"
              w="20rem"
              fontSize="1.2rem"
              color="white"
            >
              <Flex justifyContent="center" gap="1rem" alignItems="center">
                <CiMail style={{ fontSize: '1.5rem' }} />
                <Text fontStyle="italic">info@Dayacs.com</Text>
              </Flex>
              <Flex
                justifyContent="center"
                alignItems="center"
                gap="1rem"
                mt="1rem"
              >
                <FaPhoneAlt style={{ fontSize: '1.3rem' }} />
                <Text fontStyle="italic">+91 8144802704</Text>
              </Flex>
            </Box>
          </Box>
        </Box>
      </HStack>

      <Flex width="100%" bg="white" justifyContent="center" p={4}>
        <Flex
          alignItems="flex-start"
          gap="2rem"
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
        >
          {!mobileView && (
            <Box
              color="black"
              w={formWidth}
              h={formHeight}
              mt="3rem"
              fontSize="2xl"
              fontFamily="Courier New"
              boxShadow={boxShadow}
              borderWidth="1px"
              borderRadius="lg"
              position="relative"
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                onMouseEnter={() => !display && setArrowCard(true)}
                onMouseLeave={() => setArrowCard(false)}
                cursor="pointer"
                position="relative"
              >
                <Box
                  bg="white"
                  w={formWidth}
                  h={formHeight}
                  p={4}
                  border="1px solid grey"
                  borderRadius="8px"
                  cursor="pointer"
                  pointerEvents={display ? 'auto' : 'none'}
                  opacity={display ? 1 : 0.5}
                >
                  <Formik
                    initialValues={{
                      name: '',
                      email: '',
                      phone: '',
                      message: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <FormControl w="100%" m="5px">
                          <FormLabel>Name:</FormLabel>
                          <Field
                            name="name"
                            type="text"
                            as={Input}
                            innerRef={nameRef}
                            onKeyDown={e => handleKeyDown(e, emailRef)}
                          />
                        </FormControl>
                        <FormControl w="100%" m="5px">
                          <FormLabel>Email:</FormLabel>
                          <Field
                            name="email"
                            type="email"
                            as={Input}
                            innerRef={emailRef}
                            onKeyDown={e => handleKeyDown(e, phoneRef)}
                          />
                        </FormControl>
                        <FormControl w="100%" m="5px">
                          <FormLabel>Phone:</FormLabel>
                          <Field
                            name="phone"
                            type="text"
                            as={Input}
                            innerRef={phoneRef}
                            onKeyDown={e => handleKeyDown(e, messageRef)}
                          />
                        </FormControl>
                        <FormControl w="100%" m="5px">
                          <FormLabel>Message:</FormLabel>
                          <Field
                            name="message"
                            type="text"
                            as={Input}
                            innerRef={messageRef}
                          />
                        </FormControl>
                        <Center gap="3rem">
                          <Button
                            padding="20px"
                            color="black"
                            mt="10px"
                            colorScheme="blue"
                            isLoading={isSubmitting}
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Center>
                      </Form>
                    )}
                  </Formik>
                </Box>
                {!display && (
                  <Flex
                    justifyItems="center"
                    alignItems="center"
                    onClick={handleShowCard}
                    position="absolute"
                    top={0}
                    left={0}
                    w={formWidth}
                    h={formHeight}
                    bg="#00000099"
                    borderRadius="10px"
                    zIndex={1}
                  >
                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      animation={`${moveRight} 1s forwards`}
                      position={'absolute'}
                      left={marginLeft}
                    >
                      <FaArrowRight size="4rem" color="white" />
                      <Text
                        mt="1rem"
                        color="white"
                        fontFamily="times new roman"
                        textAlign="center"
                      >
                        Try our new
                      </Text>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Box>
          )}
          {display && <ChatBot1 />}
          {mobileView && (
            <Box
              color="black"
              w={formWidth}
              h={formHeight}
              mt="3rem"
              fontSize="2xl"
              fontFamily="Courier New"
              boxShadow={boxShadow}
              borderWidth="1px"
              borderRadius="lg"
              position="relative"
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                onMouseEnter={() => !display && setArrowCard(true)}
                onMouseLeave={() => setArrowCard(false)}
                cursor="pointer"
                position="relative"
              >
                <Box
                  bg="white"
                  w={formWidth}
                  h={formHeight}
                  p={4}
                  border="1px solid grey"
                  borderRadius="8px"
                  cursor="pointer"
                  pointerEvents={display ? 'auto' : 'none'}
                  opacity={display ? 1 : 0.5}
                >
                  <Formik
                    initialValues={{
                      name: '',
                      email: '',
                      phone: '',
                      message: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <FormControl w="100%" m="5px">
                          <FormLabel>Name:</FormLabel>
                          <Field
                            name="name"
                            type="text"
                            as={Input}
                            innerRef={nameRef}
                            onKeyDown={e => handleKeyDown(e, emailRef)}
                          />
                        </FormControl>
                        <FormControl w="100%" m="5px">
                          <FormLabel>Email:</FormLabel>
                          <Field
                            name="email"
                            type="email"
                            as={Input}
                            innerRef={emailRef}
                            onKeyDown={e => handleKeyDown(e, phoneRef)}
                          />
                        </FormControl>
                        <FormControl w="100%" m="5px">
                          <FormLabel>Phone:</FormLabel>
                          <Field
                            name="phone"
                            type="text"
                            as={Input}
                            innerRef={phoneRef}
                            onKeyDown={e => handleKeyDown(e, messageRef)}
                          />
                        </FormControl>
                        <FormControl w="100%" m="5px">
                          <FormLabel>Message:</FormLabel>
                          <Field
                            name="message"
                            type="text"
                            as={Input}
                            innerRef={messageRef}
                          />
                        </FormControl>
                        <Center gap="3rem">
                          <Button
                            padding="20px"
                            color="black"
                            mt="10px"
                            colorScheme="blue"
                            isLoading={isSubmitting}
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Center>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Flex>
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default ContactUsPage;
