import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useContext } from 'react';
import EnvContext from '../../context/EnvContext';
import toast from 'react-hot-toast';
import ContentContext from '../../context/ContentContext';

export const DeleteContent = ({ children, contentId }) => {
  const { backendUrl } = useContext(EnvContext);
  const {fetchAllContent} =useContext(ContentContext)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  let loadingToast;
  const handleContentDelete = async () => {
    try {
      loadingToast = toast.loading('Deleting Mentor...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });

      await axios
      .delete(`${backendUrl}/course/content?contentId=${contentId}`, {
        headers: headers,
      })
      .then(res => {
        console.log(res);
        toast.success(res.data.message,{
          position: 'top-right',
          style: {
            marginTop: '62px',
          }
        })

        fetchAllContent();
      })
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };
  return (
    <>
      <span onClick={onOpen}>{children}</span>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Content
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  handleContentDelete();
                  onClose();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
