import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { About, Course, Home, IndiCourse } from '../pages/user/regular';
import { Header } from '../components/layouts/Header';
import { ShoppingCart, SignUp } from '../pages/user';
import { SignIn } from '../pages';
import { ManageContent } from '../pages/admin/content/ManageContent';
import { AdminSubHeader } from '../components/layouts/AdminSubHeader';
import CourseForm from '../pages/admin/course/CourseForm';
import UpdateMentorList from '../pages/admin/users/UpdateMentorList';
import { ManageProjects } from '../pages/admin/project/ManageProjects';
import { ManageAssignments } from '../pages/admin/assignment/ManageAssignments';
import GenerateOTP from '../pages/GenerateOTP';
import ValidateOTP from '../pages/ValidateOTP';
import ChangePassword from '../pages/ChangePassword';
import { CourseHome } from '../pages/admin/course/CourseHome';
import { ManageSyllabus } from '../pages/admin/syllabus/ManageSyllabus';
import UpdateCourse from '../pages/admin/course/UpdateCourse';
import { WishList } from '../pages/user/authentication/WishList';
import { UserDashboard } from '../pages/user/authentication/user/UserDashboard';
import { Bio } from '../components/others/Bio';
import BioDetails from '../pages/user/authentication/BioDetails';
import VideoPage from '../pages/user/authentication/video/VideoPage';
import CartContext from '../context/CartContext';
import Coupon from '../pages/admin/coupon/Coupon';
import AuthContext from '../context/AuthContext';
import SubscriptionPlan from '../pages/admin/subscription/SubscriptionPlan';
import SubmittedProject from '../pages/admin/project/SubmittedProject';
import { AdminHome } from '../pages/admin/AdminHome';
// import { Review } from '../pages/user/authentication/video/Review';
import CourseContext from '../context/CourseListContext';
import HandleCourse from '../pages/admin/course/HandleCourse';
import HandleSubscription from '../pages/admin/subscription/HandleSubscription';
import SiteManager from '../pages/admin/siteManager/SiteManager';
import SubscriptionPlans from '../pages/user/regular/Subscription plan/SubscriptionPlans';
import ProtectedRoute from './ProtectedRoutes';
import Footer from '../components/layouts/Footer';
import TermsAndConditions from '../pages/policies/TermsAndConditions';
import PrivacyPolicy from '../pages/policies/PrivacyPolicy';
import Cancellation_n_refund from '../pages/policies/Cancellation&refund';
import AboutUs from '../pages/AboutUs';
import SubHeader from '../components/layouts/SubHeader';
import Services from '../pages/Services';
import ContactUsPage from '../pages/SupportivePages/ContactUs';
import { cryptokey } from '../pages/user/regular/Home';
import CryptoJS from 'crypto-js';
import Review from '../pages/user/authentication/video/Review';
import AllMessages from '../pages/admin/messages/AllMessages';
import TransactionDetails from '../pages/admin/transactions/TransactionDetails';
import LoadingPage from '../pages/payment/LoadingPage';
import SuccessfulPaymentPage from '../pages/payment/SuccessfulPaymentPage';
import PaymentFailedPage from '../pages/payment/PaymentFailedPage';
import AllTransactions from '../pages/user/authentication/AllTransactions';
import ProcessinngPayment from '../pages/payment/ProcessinngPayment';

export const AllRoutes = () => {
  const location = useLocation();
  const { handleUserData, getAllProducts, userData } = useContext(AuthContext);
  const { courseData, setTopCourses } = useContext(CourseContext);
  const {
    getcart,
    setCartItems,
    cartItems,
    setUnAuthCartItems,
    handeCartlDelete,
  } = React.useContext(CartContext);
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  const unAuthItem = localStorage.getItem('unAuthCartItems');
  const [includedCourses, setIncludedCourses] = useState([]);
  useEffect(() => {
    // console.log('get cart api hit');

    const storedValues = JSON.parse(localStorage.getItem('unAuthCartItems'));
    // console.log('Stored Values:', storedValues); // Log storedValues
    if (storedValues) {
      setUnAuthCartItems(storedValues);
    }
    const ciphertext = localStorage.getItem('firstFive');

    if (ciphertext) {
      // Decrypt the data
      const bytes = CryptoJS.AES.decrypt(ciphertext, cryptokey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setTopCourses(decryptedData);
    }

    if (token && !unAuthItem) getcart();
  }, []);

  useEffect(() => {
    if (!cartItems) return;
    // if (userData)
    // console.log('userData', userData);
    // console.log('cartItems', cartItems);
    // Extract course IDs from userData.courses
    const userCourseIds = userData?.courses?.map(course => course.course._id);

    // Filter cartItems into included and not included courses
    const included = cartItems?.filter(item =>
      userCourseIds?.includes(item._id)
    );
    // console.log('included----------------------', included);
    setIncludedCourses(included);
  }, [userData, cartItems]);

  useEffect(() => {
    if (!includedCourses) return;
    if (includedCourses.length > 0) {
      includedCourses.forEach(course => {
        handeCartlDelete(course._id);
      });
    }
  }, [includedCourses]);

  useEffect(() => {
    // console.log('set items hit');
    if (!token) setCartItems('');
  }, [token]);
  useEffect(() => {
    if (token) handleUserData();
  }, []);
  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <>
      {location.pathname !== '/video_page' &&
        location.pathname !== '/login' &&
        location.pathname !== '/sign_up' &&
        location.pathname !== '/dashboard' &&
        location.pathname !== '/loading_page/:merchantID' &&
        location.pathname !== '/successful_payment_page' &&
        location.pathname !== '/failed_payment_page' && <Header />}

      <Routes>
        {/* Routes for normal user */}
        <Route path="/" Component={Home}></Route>
        <Route path="/about" Component={About}></Route>
        <Route path="/products" Component={Course}></Route>
        <Route path="/indi_course" Component={IndiCourse}></Route>
        <Route path="/indi_course/checkout" Component={ShoppingCart}></Route>
        <Route
          path="/terms_n_conditions"
          element={
            <>
              <SubHeader heading={'Terms & Conditions'} />
              <TermsAndConditions />
            </>
          }
        ></Route>
        <Route
          path="/privacy_policy"
          element={
            <>
              <SubHeader heading={'Privacy Policy'} />
              <PrivacyPolicy />
            </>
          }
        ></Route>
        <Route
          path="/cancellation_n_refund"
          element={
            <>
              <SubHeader heading={'Cancellation & Refund'} />
              <Cancellation_n_refund />
            </>
          }
        ></Route>

        <Route
          path="/about_us"
          element={
            <>
              <SubHeader heading={' Who We Are'} />
              <AboutUs />
            </>
          }
        ></Route>
        <Route
          path="/services"
          element={
            <>
              <SubHeader heading={' Our Services'} />
              <Services />
            </>
          }
        ></Route>

        {/* Routes for all user */}
        <Route path="/loading_page/:merchantID" element={<LoadingPage />}></Route>
        <Route
          path="/successful_payment_page"
          element={<SuccessfulPaymentPage />}
        ></Route>
        <Route
          path="/processing_payment_page"
          element={<ProcessinngPayment />}
        ></Route>
        <Route
          path="/failed_payment_page"
          element={<PaymentFailedPage />}
        ></Route>
        <Route path="/cart" element={<ShoppingCart />}></Route>
        <Route path="/wishList" element={<WishList />}></Route>
        <Route path="/contact_us" Component={ContactUsPage}></Route>
        <Route path="/login" Component={SignIn}></Route>
        <Route path="/sign_up" Component={SignUp}></Route>
        <Route path="/generate_otp" Component={GenerateOTP}></Route>
        <Route path="/validate_otp" Component={ValidateOTP}></Route>
        <Route path="/change_password" Component={ChangePassword}></Route>
        <Route path="/subscription_plans" Component={SubscriptionPlans}></Route>
        <Route
          path="/subscription_plans/checkout"
          Component={ShoppingCart}
        ></Route>
        <Route path="/renew/checkout" Component={ShoppingCart}></Route>
        {/* Routes for User with login authentication */}
        <Route
          path="/dashboard"
          element={<ProtectedRoute element={<UserDashboard />} />}
        />
        <Route path="/bio" element={<ProtectedRoute element={<Bio />} />} />
        <Route path="/bioDetails" element={<BioDetails />} />
        <Route path="/all_transactions" element={<AllTransactions />} />

        <Route
          path={'/video_page'}
          element={
            <>
              <VideoPage />
              {/* <ProtectedRoute element={<VideoPage />} /> */}
            </>
          }
        />

        {/* Routes for admin */}
        <Route
          path="/admin/home"
          element={
            <>
              <AdminSubHeader heading={'Admin Home'} />
              <ProtectedRoute element={<AdminHome />} />
            </>
          }
        />

        <Route
          path="/admin/add_course"
          element={
            <>
              <AdminSubHeader heading={'Add Course'} />
              <ProtectedRoute element={<CourseForm />} />
            </>
          }
        />
        <Route
          path="/admin/update_course"
          element={
            <>
              <AdminSubHeader heading={'Update Course'} />
              <ProtectedRoute element={<UpdateCourse />} />
            </>
          }
        />

        <Route
          path="/admin/courses"
          element={
            <>
              <AdminSubHeader heading={'All Courses'} />
              <ProtectedRoute element={<CourseHome />} />
            </>
          }
        />
        <Route
          path="/admin/manage_courses"
          element={
            <>
              <AdminSubHeader heading={'Manage Courses'} />
              <ProtectedRoute element={<HandleCourse />} />
            </>
          }
        />
        <Route
          path="/admin/manage_subscriptions"
          element={
            <>
              <AdminSubHeader heading={'Manage Subscription'} />
              <ProtectedRoute element={<HandleSubscription />} />
            </>
          }
        />
        <Route
          path="/admin/manage_reviews"
          element={
            <>
              <AdminSubHeader heading={'Manage Contents'} />
              {courseData && (
                <ProtectedRoute
                  element={
                    <Review courseData={courseData} isAdminViewing={true} />
                  }
                />
              )}
            </>
          }
        />
        <Route
          path="/admin/manage_content"
          element={
            <>
              <AdminSubHeader heading={'Manage Contents'} />
              <ProtectedRoute element={<ManageContent />} />
            </>
          }
        />
        <Route
          path="/admin/manage_projects"
          element={
            <>
              <AdminSubHeader heading={'Manage Projects'} />
              <ProtectedRoute element={<ManageProjects />} />
            </>
          }
        />
        <Route
          path="/admin/submited_projects"
          element={
            <>
              <AdminSubHeader heading={'Submitted Projects'} />
              <ProtectedRoute element={<SubmittedProject />} />
            </>
          }
        />

        <Route
          path="/admin/manage_syllabus"
          element={
            <>
              <AdminSubHeader heading={'Manage Syllabus'} />
              <ProtectedRoute element={<ManageSyllabus />} />
            </>
          }
        />

        <Route
          path="/admin/manage_assignment"
          element={
            <>
              <AdminSubHeader heading={'Manage Assignments'} />
              <ProtectedRoute element={<ManageAssignments />} />
            </>
          }
        />

        <Route
          path="/admin/update_mentor"
          element={
            <>
              <AdminSubHeader heading={'Mentors'} />
              <ProtectedRoute element={<UpdateMentorList />} />
            </>
          }
        />

        <Route
          path="/admin/coupon"
          element={
            <>
              <AdminSubHeader heading={'Coupon'} />
              <ProtectedRoute element={<Coupon />} />
            </>
          }
        />

        <Route
          path="/admin/subscription_plan"
          element={
            <>
              <AdminSubHeader heading={'Subscription Plans'} />
              <ProtectedRoute element={<SubscriptionPlan />} />
            </>
          }
        />
        <Route
          path="/admin/site_manager"
          element={
            <>
              <AdminSubHeader heading={'Site Manager'} />
              <ProtectedRoute element={<SiteManager />} />
            </>
          }
        />
        <Route
          path="/admin/all_messages"
          element={
            <>
              <AdminSubHeader heading={'Messages'} />
              <ProtectedRoute element={<AllMessages />} />
            </>
          }
        />
        <Route
          path="/admin/transaction_details"
          element={
            <>
              <AdminSubHeader heading={'Transaction Details'} />
              <ProtectedRoute element={<TransactionDetails />} />
            </>
          }
        />
      </Routes>
      {role !== 'admin' &&
        location.pathname !== '/video_page' &&
        location.pathname !== '/login' &&
        location.pathname !== '/sign_up' &&
        location.pathname !== '/generate_otp' &&
        location.pathname !== '/validate_otp' &&
        location.pathname !== '/change_password' && <Footer />}
    </>
  );
};
