import { useContext, createContext, useEffect, useState } from 'react';
import EnvContext from './EnvContext';
import axios from 'axios';
// import toast from 'react-hot-toast';
import ContentContext from './ContentContext';
import { useToast } from '@chakra-ui/react';

const CourseContext = createContext();

export const CourseContextProvider = ({ children }) => {
  const toast = useToast();

  const { backendUrl } = useContext(EnvContext);
  const [token, setToken] = useState(null);
  const [courses, setCourse] = useState([]);
  const [topCourses, setTopCourses] = useState([]);
  const [courseId, setCourseId] = useState(() =>
    localStorage.getItem('courseId')
  );
  const [courseData, setCourseData] = useState({});

  useEffect(() => {
    if (courseId) {
      // Ensure courseId is not null or undefined
      localStorage.setItem('courseId', courseId);
    }
  }, [courseId]);

  useEffect(() => {
    if (courseData) {
      console.log('courseData', courseData);
    }
  }, [courseData]);

  useEffect(() => {
    if (courses) console.log('courses', courses);
  }, [courses]);

  const fetchData = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(`${backendUrl}/course/all`, { headers: headers })
        .then(res => {
          console.log('AllCourseres', res.data.courses);
          setCourse(res.data.courses);
        })
        .catch(err => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };
  const handleCourseDelete = async id => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const deleteCoursePromise = axios.delete(`${backendUrl}/course?courseId=${id}`, {
      headers: headers,
    });

    toast.promise(deleteCoursePromise, {
      loading: {
        title: 'Deleting the course...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Course deleted successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to delete course!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await deleteCoursePromise;
      console.log(res);
      fetchData();
    } catch (err) {
      console.error(err);

    }
  };
  const getCourse = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(`${backendUrl}/course/specific?courseId=${courseId}`, {
          headers: headers,
        })
        .then(res => {
          console.log('Course Data', res.data);
          setCourseData(res.data.course);
        })
        .catch(err => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };
  const getNoAuthCourse = async () => {
    setCourseData({});
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(`${backendUrl}/course?courseId=${courseId}`, {
          headers: headers,
        })
        .then(res => {
          console.log('Course Data', res.data);
          setCourseData(res.data.course);
        })
        .catch(err => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const getComplitionCertificate = async () => {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const getCertificatePromise = axios.get(
      `${backendUrl}/user/rs/generate_completion_certificate?userId=${userId}&courseId=${courseId}`,
      { headers: headers }
    );

    toast.promise(getCertificatePromise, {
      loading: {
        title: 'Generating your certificate...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Certificate generated successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to generate certificate!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await getCertificatePromise;
      console.log('getcompletioncertificateres', res.data);
      const pdfUrl = `${backendUrl}${res.data.data.certificateLink}`;
      window.open(pdfUrl, '_blank');

    } catch (err) {
      console.error(err);

    }
  };
  const getExcellenceCertificate = async () => {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log(
      'getComplitionCertificateuserid:',
      userId,
      'getComplitionCertificatecourseId:',
      courseId
    );

    const certificatePromise = axios.get(
      `${backendUrl}/user/rs/generate_excellence_certificate?userId=${userId}&courseId=${courseId}`,
      { headers: headers }
    );

    toast.promise(certificatePromise, {
      loading: {
        title: 'Generating your certificate...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
      success: {
        title: 'Certificate generated successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to generate certificate!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
    });

    try {
      const res = await certificatePromise;
      console.log('getcompletioncertificateres', res.data);

      // setScoreCard(res.data.data)
      // const pdfBlob = new Blob([res.data.data.certificateLink], { type: 'application/pdf' });
      const pdfUrl = `${backendUrl}${res.data.data.certificateLink}`;
      // const pdfUrl = URL.createObjectURL(pdfBlob);
      console.log('pdfUrl', pdfUrl);
      window.open(pdfUrl, '_blank');
    } catch (err) {
      console.error(err);
      // The error toast is already handled by toast.promise
    }
  };


  return (
    <CourseContext.Provider
      value={{
        courseData,
        courses,
        getCourse,
        handleCourseDelete,
        fetchData,
        setToken,
        setCourseId,
        setCourseData,
        courseId,
        getComplitionCertificate,
        getExcellenceCertificate,
        getNoAuthCourse,
        setTopCourses,
        topCourses,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

export default CourseContext;

export const useCourse = () => useContext(CourseContext);
