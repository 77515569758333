import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  Text,
  Avatar,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import SyllabusForm from '../forms/SyllabusForm';
import MentorForm from '../forms/MentorForm';
import { MdOutlineLibraryAdd } from 'react-icons/md';
import { CiSearch } from 'react-icons/ci';
import axios from 'axios';
import EnvContext from '../../context/EnvContext';
import toast, { Toaster } from 'react-hot-toast';
import MentorContext from '../../context/MentorContext';
import { get } from 'react-scroll/modules/mixins/scroller';

export const MentorList = ({ children, setSelectedMentor }) => {
  const { backendUrl } = useContext(EnvContext);
  const { fetchMentor, setToken, setMentorName, setMentorId, mentorId } = useContext(MentorContext);
  const { mentors } = useContext(MentorContext);
  const token = localStorage.getItem('token');
  const [chaptersJSON, setChaptersJSON] = useState('');
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { isOpen, onClose, onOpen } = useDisclosure();
  useEffect(() => {
    if (mentors) console.log('mentors', mentors)
  }, [mentors])
  useEffect(() => {
    if (token) setToken(token)
  }, [token])
  const handleChaptersJSONChange = json => {
    setChaptersJSON(json);
  };
  useEffect(() => {
    if (token) fetchMentor();
  }, [token]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        fetchMentor();
      }
    }, 1000); // Adjust debounce/throttle time as needed

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleInputChange = event => {
    setMentorName(event.target.value)

    setSearchTerm(event.target.value);
  };
  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        isCentered
        isOpen={isOpen}
        size={'full'}
        scrollBehavior={'inside'}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Mentor List</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack spacing={10} alignItems={'flex-start'}>
              <VStack w={'50%'} alignItems={'flex-start'}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <CiSearch color="gray.300" size={20} />
                  </InputLeftElement>
                  <Input
                    type="text"
                    placeholder="Mentor Name"
                    value={searchTerm}
                    onChange={handleInputChange}
                  />
                </InputGroup>
                {mentors && mentors.length > 0 ? (
                  mentors.map((item, index) => (
                    <>
                      <HStack pl={20} cursor={'pointer'} key={index} onClick={() => {
                        setMentorId(item._id);
                        setSelectedMentor(item.name);
                        onClose();
                      }}>
                        <Avatar
                          size="lg"
                          name="Prosper Otemuyiwa"
                          src={`${backendUrl}${item.dp}`}
                        />
                        <Text>{item.name}</Text>
                      </HStack>
                    </>
                  ))
                ) : (
                  <Text>No Such Mentor </Text>
                )}
              </VStack>
              <VStack w={'50%'}>
                {show ? (
                  <MentorForm />
                ) : (
                  <Button colorScheme="blue" onClick={() => setShow(true)}>
                    Add mentor
                  </Button>
                )}
              </VStack>
            </HStack>
          </ModalBody>
        </ModalContent>
        <Toaster />
      </Modal>
    </>
  );
};
