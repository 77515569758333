import React from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { FaStar } from "react-icons/fa";

export const StarRating = ({ rating }) => {
    return (
        <Box display="flex" gap={1} mb={5} mt={2}>
            {[...Array(5)].map((_, index) => (
                <FaStar
                    key={index}
                    aria-label={`Rated ${index + 1} stars`}
                    color={index < rating ? '#feb236' : '#b2b2b2'}
                    size="15px" // Adjust size as needed
                />
            ))}

        </Box>
    );
};


