import {
  Box,
  HStack,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { FiGift } from 'react-icons/fi';
import EnvContext from '../../context/EnvContext';
import { useLocation } from 'react-router-dom';

export const CartProductMeta = props => {
  const { backendUrl } = useContext(EnvContext);
  const location = useLocation();
  const {
    image,
    logo,
    name,
    description,
    isSubscription,
    offerPrice,
    originalPrice,
    planDuration,
    courseName,
    renewalPrice,
  } = props;
  console.log('CartProductMeta--------------------', props);
  return (
    <Stack
      direction="row"
      spacing="5"
      width="full"
      height={
        location.pathname === '/subscription_plans/checkout' ||
        location.pathname === '/renew/checkout'
          ? '10vh'
          : '20vh'
      }
      pb={2}
      alignItems={'center'}
    >
      {logo && (
        <Image
          rounded="lg"
          height="100%"
          fit="contain"
          src={`${backendUrl}${logo}`}
          alt={name}
          draggable="false"
          loading="lazy"
        />
      )}

      <Box pt="4">
        <Stack spacing="0.5">
          <Text fontWeight="medium">{name || courseName}</Text>
          <Text color={'gray.600'} fontSize="sm">
            {description || ` Plan Duration: ${planDuration} months`}
          </Text>
        </Stack>
      </Box>
    </Stack>
  );
};
