import {
  Button,
  Drawer,
  DrawerBody,
  useDisclosure,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Box,
  VStack,
  Text,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../App.css';
import { useSiteManager } from '../../context/SiteManagerContext';
import AssignmentContext from '../../context/AssignmentContext';
import { evaluateQuestionStatus } from '../../Utils/function';
import { useContent } from '../../context/ContentContext';

const QuestionCard = ({ question, i, status, isActive, onClick }) => {
  const borderColor = {
    R: 'green.500',
    W: 'red.500',
    NA: 'gray.300',
  }[status];
  return (
    <Box
      p={2}
      borderWidth="1px"
      borderRadius="lg"
      cursor="pointer"
      bg={isActive ? 'blue.500' : 'white'}
      color={isActive ? 'white' : 'black'}
      _hover={isActive ? '' : { bg: 'blue.100' }}
      onClick={() => {
        onClick();
      }}
      overflow="hidden" // Hide overflow content
      width={'100%'}
      border={'2px'}
      //   borderStyle={'dashed'}
      borderColor={borderColor} // Set border color based on status
    >
      <Text
        fontSize="md"
        whiteSpace="nowrap" // Prevent text from wrapping to the next line
        overflow="hidden" // Hide overflow content
        textOverflow="ellipsis" // Show ellipsis when text overflows
      >{`Q${i + 1})  ${question.text}`}</Text>
    </Box>
  );
};
export const QuestionsDrawer = ({ children }) => {
  const {
    getAllQuestions,
    questions,
    setQuesId,
    prevAttempts,
    previousAttempts,
    setCurrentIndex,
  } = useContext(AssignmentContext);
  const { scorecard } = useContent();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const btnRef = React.useRef();
  const { setUpdate } = useSiteManager();
  const [activeQuestionId, setActiveQuestionId] = useState(null);

  useEffect(() => {
    if (scorecard) console.log('scorecard====================', scorecard);
  }, [scorecard]);
  return (
    <Box className="admin-sidebar">
      <span onClick={onOpen}>{children}</span>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader marginBottom={5}>All Assignments :</DrawerHeader>

          <DrawerBody>
            <VStack alignItems={'flex-start'}>
              {questions.map((q, i) => (
                <QuestionCard
                  key={q._id}
                  i={i}
                  question={q.question}
                  status={evaluateQuestionStatus(q, scorecard?.assignment)}
                  isActive={q._id === activeQuestionId}
                  onClick={() => {
                    console.log('q._id==================', q._id);
                    setActiveQuestionId(q._id);
                    setQuesId(q._id);
                    previousAttempts(q._id);
                    onClose();
                    setCurrentIndex(i);
                  }}
                />
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
