const courseServises = [

    {
      id: 1,
      description: "Programers focus on IT and soft skills development."
    },
    {
      id: 2,
      description: "Courses designed to meet modern job market demands."
    },
    {
      id: 3,
      description: "Aim to equip individuals with career-enhancing knowledge and skills."
    },
    {
      id: 4,
      description: "Training programs offer valuable course certificates upon successful completion."
    },
    {
      id: 5,
      description: "Certificates attest to newly acquired expertise."
    },    {
        id:6,
        description:" Training programs cater to both technical skills in Information Technology and soft skills."
    },
    {
        id:7,
        description:" Soft skills include communication, teamwork, and leadership."
    },
    {
        id:8,
        description:" Expert instructors with real-world experience"
    },
    {
        id:9,
        description:" Practical, hands-on knowledge"
    },
    {
        id:10,
        description:"Immediate application in professional lives"
    },    {
        id:11,
        description:" Commitment to excellence"
    },    {
        id:12,
        description:" Focus on helping individuals reach their full potential"
    },    {
        id:13,
        description:"Gateway to a brighter and more successful future in IT and beyond"
    },
    
  ];

  export default courseServises