import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import CartContext from '../../context/CartContext';

const ProcessinngPayment = () => {
  const { handeCartlDelete } = useContext(CartContext);
  const [countdown, setCountdown] = useState(3);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const transaction = location.state;
    if (!transaction) return;
    if (transaction?.courses?.length > 0) {
      transaction?.courses?.forEach(course => {
        handeCartlDelete(course._id);
      });
    }
  }, [location]);
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    if (countdown === 0) {
      navigate('/all_transactions',{state:1});
    }

    return () => clearInterval(timer);
  }, [countdown, navigate]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      backgroundColor="gray.100"
    >
      <VStack spacing={4} textAlign="center">
        <Heading as="h1" size="2xl" color="green.500">
          Payment is under process
        </Heading>
        <Text fontSize="xl" color="gray.700">
          Thank you for your purchase.
        </Text>
        <Text fontSize="md" color="gray.500">
          Redirecting to your transaction page in {countdown} seconds...
        </Text>
        <Button colorScheme="blue" onClick={() => navigate('/all_transactions')}>
          Go to Transaction
        </Button>
      </VStack>
    </Box>
  );
};

export default ProcessinngPayment;
