import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    useDisclosure,
  } from '@chakra-ui/react';
  import React, { useContext } from 'react';
  import CourseContext from '../../context/CourseListContext';
  import axios from 'axios';
  import EnvContext from '../../context/EnvContext';
  import toast from 'react-hot-toast';
  import MentorContext from '../../context/MentorContext';
import CouponContext from '../../context/CouponContext';
  
  export const DeleteCoupon = ({ children, couponId }) => {
    
      const{deleteCoupon,getCoupon}=useContext(CouponContext)
      const token = localStorage.getItem('token');
    const { backendUrl } = useContext(EnvContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    
  
    return (
      <>
        <span onClick={onOpen}>{children}</span>
  
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Content
              </AlertDialogHeader>
  
              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>
  
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    onClose();
                    deleteCoupon(couponId);
                    getCoupon();
                  }}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  };
  