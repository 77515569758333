import {
  Avatar,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Tag,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  HStack,
} from '@chakra-ui/react';
import React, { useContext, useState, useEffect } from 'react';
import EnvContext from '../../../context/EnvContext';
import { FaCartShopping } from 'react-icons/fa6';
import { FaHeart } from 'react-icons/fa';
import { AdminCartModal } from '../../../components/modals/AdminCartModal';
import CartContext from '../../../context/CartContext';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { MdDelete } from 'react-icons/md';
import { FaLock } from 'react-icons/fa';
import { FaLockOpen } from 'react-icons/fa';
import AuthContext from '../../../context/AuthContext';
import ProjectContext from '../../../context/ProjectContext';
import { AiFillProject } from 'react-icons/ai';
import CourseContext from '../../../context/CourseListContext';
import { IoBookSharp } from 'react-icons/io5';
import { BsSubstack } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { AdminWishLIstModal } from '../../../components/modals/AdminWishLIstModal';
import WishListContext from '../../../context/WishListContext';

export const UserList = ({ users, loading }) => {
  const navigate = useNavigate();
  const { fetchData, courses } = useContext(CourseContext);
  const { backendUrl } = useContext(EnvContext);
  const { setUserID } = useContext(CartContext);
  const { getProjectSubmission } = useContext(ProjectContext);
  const { getWishList, setAdminWishList } = useContext(WishListContext);
  const { blockUser, unBlock, handleDelete, getSpecificUsers, handleUserData } =
    useContext(AuthContext);
  // State to manage modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [index, setIndex] = useState(null);
  // useEffect(()=>{
  //   adminGetAllWIshList()
  // },[])

  useEffect(() => {
    getSpecificUsers();
    fetchData();
  }, []);
  useEffect(() => {
    if (Array.isArray(courses)) {
      console.log('fetchDatacourses', courses);
    }
  }, [courses]);
  // Functions to open/close modal
  const openModal = userId => {
    setSelectedUserId(userId);
    setIsModalOpen(true);
    setUserID(userId);
  };

  const closeModal = () => {
    setSelectedUserId(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log('usersusersusersusers', users);
  }, [users]);
  return (
    <>
      {loading ? (
        <>
          <HStack w={'50%'} mb={3}>
            <Text>Filter user by Courses</Text>
            <Menu w={'25%'}>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Select Course
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    getSpecificUsers();
                  }}
                >
                  All Users{' '}
                </MenuItem>
                {courses &&
                  Array.isArray(courses) &&
                  courses.map((el, i) => (
                    <>
                      <MenuItem
                        key={i}
                        onClick={() => {
                          getSpecificUsers(el._id);
                        }}
                      >
                        {el.courseName}
                      </MenuItem>
                    </>
                  ))}
              </MenuList>
            </Menu>
          </HStack>
          {users && users.length > 0 ? (
            <TableContainer maxWidth={'90%'}>
              <Table variant="simple">
                {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                <Thead bg="whatsapp.100">
                  <Tr>
                    <Th w={'0.5rem'}>Sl. No.</Th>
                    <Th w={'5rem'}>Active</Th>
                    <Th w={'10rem'}>Name</Th>
                    <Th w={'10rem'}>Email</Th>
                    <Th w={'15rem'}>Phone No.</Th>
                    <Th w={'5rem'}>Menu</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {users.map((user, index) => (
                    <Tr key={index}>
                      <Td w={'0.5rem'}>{index + 1}</Td>
                      {/* <Td><FaLock />
                    </Td> */}

                      <Td>
                        {user.isBLOCKED ? (
                          <FaLock
                            cursor={'pointer'}
                            size={20}
                            onClick={() => unBlock(user._id)}
                          />
                        ) : (
                          <FaLockOpen
                            cursor={'pointer'}
                            size={20}
                            onClick={() => blockUser(user._id)}
                          />
                        )}
                      </Td>
                      <Td
                        w={'10rem'}
                        display={'flex'}
                        alignItems="center"
                        gap={5}
                      >
                        <Avatar
                          size="sm"
                          name={user.name}
                          src={`${backendUrl}${user.dp}`}
                        />
                        <Text>{user.name}</Text>
                      </Td>
                      <Td w={'15rem'}>{user.email}</Td>
                      <Td w={'15rem'}>{user.phone}</Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                          ></MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                navigate('/admin/manage_courses');
                                handleUserData(user._id);
                              }}
                            >
                              <HStack alignItems={'center'} spacing={4}>
                                <IoBookSharp />
                                <p>Handle Courses</p>
                              </HStack>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                navigate('/admin/manage_subscriptions');
                                handleUserData(user._id);
                              }}
                            >
                              <HStack alignItems={'center'} spacing={4}>
                                <BsSubstack />
                                <p>Handle Subscription</p>
                              </HStack>
                            </MenuItem>
                            <MenuItem>
                              <HStack
                                alignItems={'center'}
                                spacing={4}
                                onClick={() => {
                                  localStorage.removeItem('CourseID');
                                  localStorage.removeItem('ProjectId');
                                  localStorage.setItem(
                                    'history',
                                    '/admin/home'
                                  );
                                  localStorage.setItem('UserId', user._id);
                                  getProjectSubmission(
                                    undefined,
                                    undefined,
                                    user._id
                                  );
                                }}
                              >
                                <AiFillProject size={20} />
                                <p>Project Submissions</p>
                              </HStack>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setIndex(index);
                                openModal(user._id);
                              }}
                            >
                              <HStack alignItems={'center'} spacing={4}>
                                <FaCartShopping />
                                <p>Cart List</p>
                              </HStack>
                            </MenuItem>
                            <AdminWishLIstModal>
                              <MenuItem
                                onClick={() => {
                                  console.log('WishListuser._id', user._id);
                                  getWishList(user._id);
                                }}
                              >
                                <HStack alignItems={'center'} spacing={4}>
                                  <FaHeart />
                                  <p>WishList</p>
                                </HStack>
                              </MenuItem>
                            </AdminWishLIstModal>

                            <MenuItem>
                              <HStack
                                alignItems={'center'}
                                spacing={4}
                                onClick={() => handleDelete(user._id)}
                              >
                                <MdDelete size={20} />
                                <p>Delete</p>
                              </HStack>
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <Text>You have not assigned any Course</Text>
          )}
        </>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}

      <AdminCartModal
        isOpen={isModalOpen}
        onClose={closeModal}
        users={users[index]}
      />
      {/* <AdminWishLIstModal isOpen={isModalOpen} onClose={closeModal}/> */}
    </>
  );
};
