import React, { useContext } from 'react';
import ContentContext from '../../../context/ContentContext';
import { Button, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import EnvContext from '../../../context/EnvContext';
import { NotesOpener } from '../../../components/modals/NotesOpener';
import { AssignmentOpener } from '../../../components/modals/AssignmentOpener';

export const AboutContent = () => {
  const { content } = useContext(ContentContext);
  const { backendUrl } = useContext(EnvContext);
  return (
    <>
    {
      content.title ? (
        <VStack alignItems={'flex-start'} spacing={3} p={4} width={'100%'}>
        <HStack>
          <Text fontWeight={600}>Title :</Text>
          <Text>{content?.title}</Text>
        </HStack>

        <HStack>
          <Text fontWeight={600}>Description :</Text>
          <Text>{content?.description}</Text>
        </HStack>

        <HStack width={'100%'}>
          <Text fontWeight={600}>Attached Notes :</Text>
          {content.notes ? (
            <>
              <NotesOpener notes={content.notes}>
                <Button colorScheme="teal" size={'xs'}>
                  Open the pdf
                </Button>
              </NotesOpener>
            </>
          ) : (
            <Text>Nothing Attached Here</Text>
          )}
        </HStack>

        <HStack>
          <Text fontWeight={600}>Likes :</Text>
          <Text>{ content?.likes &&  content?.likes.length}</Text>
        </HStack>

        <HStack>
          <Text fontWeight={600}>No of Assignments :</Text>
          <HStack spacing={10}>
            <Text>{content?.assignment && content?.assignment.length}</Text>
            {content?.assignment && content?.assignment.length > 0 && (
              <>
                <AssignmentOpener questions={content?.assignment}>
                  <Button colorScheme="teal" size={'xs'}>
                    Access all assignments
                  </Button>
                </AssignmentOpener>
              </>
            )}
          </HStack>
        </HStack>
      </VStack>
      ):(
<Text fontWeight={700}>First select any content</Text>
      )
    }
    
    </>
  );
};
