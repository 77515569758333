import React, { useState, useEffect, useContext } from 'react';
import {
  ChakraProvider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Spinner,
  IconButton,
  Flex,
} from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import EnvContext from '../../../context/EnvContext';
import { FaCheck } from 'react-icons/fa';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import toast from 'react-hot-toast';

const ContactTable = () => {
  const { backendUrl } = useContext(EnvContext);
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [contacted, setContacted] = useState(false);

  const fetchContacts = async (page, contacted) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      if (page === 1) {
        setContacts([]);
        setTotalPages(1);
      }

      loadingToast = toast.loading(
        `Fetching ${contacted ? 'contacted' : 'all'} messages`,
        {
          position: 'top-right',
          style: { marginTop: '62px' },
        }
      );

      const response = await axios.get(
        `${backendUrl}/contact?page=${page}&limit=11&isContacted=${contacted}`,
        { headers }
      );

      const { contactRequests, totalPages } = response.data;
      setContacts(prevContacts =>
        page === 1 ? contactRequests : [...prevContacts, ...contactRequests]
      );
      setCurrentPage(page);
      setTotalPages(totalPages);
      setHasMore(page < totalPages);

      toast.success(response.data.message, {
        position: 'top-right',
        style: { marginTop: '62px' },
      });
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || 'Something went wrong';
      toast.error(errorMessage, {
        position: 'top-right',
        style: { marginTop: '62px' },
      });
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };
  useEffect(() => {
    fetchContacts(1, contacted);
  }, [contacted]);

  const personContacted = async (id, contacted) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const postData = { isContacted: contacted };

    try {
      await axios
        .put(`${backendUrl}/contact?contactId=${id}`, postData, {
          headers,
        })
        .then(res => console.log(res));
      setCurrentPage(1);
      setHasMore(true);
      fetchContacts(1, false); // Reset the fetch after updating
    } catch (error) {
      console.error('Error updating contact:', error);
    }
  };

  useEffect(() => {
    if (contacts.length > 0) console.log('contacts', contacts);
  }, [contacts]);
  return (
    <ChakraProvider>
      <Flex width={'100%'} justifyContent={'center'}>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Handle Messages
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                setCurrentPage(1);
                setTotalPages(1);
                setContacts([]);
                setContacted(false);
              }}
            >
              All Messages
            </MenuItem>
            <MenuItem
              onClick={() => {
                setCurrentPage(1);
                setTotalPages(1);
                setContacts([]);
                setContacted(true);
              }}
            >
              Alreday Contacted
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      <Box p={4} mx={'10vh'}>
        <InfiniteScroll
          dataLength={contacts.length}
          next={() => fetchContacts(currentPage + 1, contacted)}
          hasMore={hasMore}
          loader={<Spinner />}
          endMessage={<p>No more contacts to display</p>}
        >
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Sl. No.</Th>
                <Th>Name</Th>
                <Th>Phone</Th>
                <Th>Email</Th>
                <Th>Message</Th>
                <Th>Contacted</Th>
              </Tr>
            </Thead>
            <Tbody>
              {contacts?.map((contact, i) => (
                <Tr key={i}>
                  <Td>{i + 1}</Td>
                  <Td>{contact?.name}</Td>
                  <Td>{contact?.phone}</Td>
                  <Td>{contact?.email}</Td>
                  <Td maxW={'20rem'}>
                    <Box
                      overflowY={'scroll'}
                      overflowX={'hidden'}
                      minH={'2rem'}
                      maxH={'8rem'}
                    >
                      {contact?.message}
                    </Box>
                  </Td>
                  <Td>
                    {!contact?.isContacted && (
                      <IconButton
                        onClick={() => personContacted(contact?._id, true)}
                        colorScheme="blue"
                        aria-label="Search database"
                        icon={<FaCheck />}
                      />
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </InfiniteScroll>
      </Box>
    </ChakraProvider>
  );
};

export default ContactTable;
