import { Box, Heading, Input, Text, Flex, Button } from '@chakra-ui/react';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import EnvContext from '../../context/EnvContext';
import AuthContext from '../../context/AuthContext';

function UserDelete() {
  const [firstName, setFirstName] = useState(null);
  const [confirmation, setConfirmation] = useState('');
  const { backendUrl } = useContext(EnvContext);
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const userId =localStorage.getItem('userId')
  const {errorHandleLogout} =useContext(AuthContext);
  

  useEffect(() => {
    const storedName = localStorage.getItem('name');

    if (storedName) {
      const spaceIndex = storedName.indexOf(' ');
      setFirstName(
        spaceIndex !== -1 ? storedName.slice(0, spaceIndex) : storedName
      );
    }
  }, []);

  const handleConfirmationChange = event => {
    setConfirmation(event.target.value);
  };
  const handleDelete = async () => {
    if (confirmation === `${firstName}-delete-me`) {
      console.log('deleteing profile');
      errorHandleLogout()
    } else {
      alert('Confirmation does not match. Profile not deleted.');
    }
    try {
      await axios.delete(`${backendUrl}/user/temp`, { headers: headers })
      .then((res)=>{
        console.log('res', res);
      })
      .catch((err)=>{
console.log(err)
      })
    } catch (err) {
      console.log(err);
    }

  
  };
  const handleCancel = () => {};
  return (
    <Box>
      <Heading size={'lg'} mb={5}>
        Delete Profile
      </Heading>

      <Heading as={'h3'} fontSize={'xl'}>
        Are you sure you want to Delete?
      </Heading>
      <Text>
        To conform type<strong> "{firstName}-delete-me"</strong> in the box
        bellow
      </Text>
      <Input
        variant="outline"
        borderColor="red.300"
        value={confirmation}
        onChange={handleConfirmationChange}
      />
      <Flex mt={4}>
        <Button colorScheme="red" mr={3} onClick={handleDelete}>
          Delete Account
        </Button>
      </Flex>
    </Box>
  );
}

export default UserDelete;
