import {
  Button,
  Drawer,
  DrawerBody,
  useDisclosure,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Box,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import '../../App.css';
import { useSiteManager } from '../../context/SiteManagerContext';

export const AdminSidebar = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { setUpdate } = useSiteManager();

  return (
    <Box className="admin-sidebar">
      <span onClick={onOpen}>{children}</span>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader marginBottom={5}></DrawerHeader>

          <DrawerBody>
            <VStack spacing={5} width={'100%'}>
              <NavLink

                to={'/admin/home'}
                textDecoration={'none'}
                className="dashboardNavlink"
                style={{ fontSize: '1rem', fontWeight: 600, width: "100%" }}
                onClick={onClose}
              >
                Home
              </NavLink>

              <NavLink

                to={'/admin/courses'}
                textDecoration={'none'}
                className="dashboardNavlink"
                style={{ fontSize: '1rem', fontWeight: 600, width: "100%" }}
                onClick={onClose}
              >
                Courses
              </NavLink>

              <NavLink

                to={'/admin/add_course'}
                textDecoration={'none'}
                className="dashboardNavlink"
                style={{ fontSize: '1rem', fontWeight: 600, width: "100%" }}
                onClick={onClose}
              >
                Add Course
              </NavLink>



              <NavLink
                to={'/admin/update_mentor'}
                textDecoration={'none'}
                className="dashboardNavlink"
                style={{ fontSize: '1rem', fontWeight: 600, width: "100%" }}
                onClick={onClose}
              >
                Mentors
              </NavLink>

              <NavLink
                to={'/admin/coupon'}
                textDecoration={'none'}
                className="dashboardNavlink"
                style={{ fontSize: '1rem', fontWeight: 600, width: "100%" }}
                onClick={onClose}
              >
                Coupon
              </NavLink>

              <NavLink
                to={'/admin/subscription_plan'}
                textDecoration={'none'}
                className="dashboardNavlink"
                style={{ fontSize: '1rem', fontWeight: 600, width: "100%" }}
                onClick={onClose}
              >
                Subscription Plan
              </NavLink>

              <NavLink
                to={'/admin/site_manager'}
                textDecoration={'none'}
                className="dashboardNavlink"
                style={{ fontSize: '1rem', fontWeight: 600, width: "100%" }}
                onClick={() => {
                  onClose();
                  setUpdate(true)
                }
                }
              >
                Site Manager
              </NavLink>

              <NavLink
                to={'/admin/transaction_details'}
                textDecoration={'none'}
                className="dashboardNavlink"
                style={{ fontSize: '1rem', fontWeight: 600, width: "100%" }}
                onClick={onClose}
              >
                Transaction Details
              </NavLink>

              <NavLink
                to={'/admin/all_messages'}
                textDecoration={'none'}
                className="dashboardNavlink"
                style={{ fontSize: '1rem', fontWeight: 600, width: "100%" }}
                onClick={onClose}
              >
                All Messages
              </NavLink>

            </VStack>
          </DrawerBody>

          {/* <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue">Save</Button>
          </DrawerFooter> */}
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
