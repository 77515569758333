import {
  HStack,
  VStack,
  InputGroup,
  InputLeftElement,
  Input,
  Avatar,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Divider,
  IconButton,
  Box,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import MentorContext from '../../../context/MentorContext';
import { CiSearch } from 'react-icons/ci';
import EnvContext from '../../../context/EnvContext';
import UpdateMentor from '../../../components/forms/UpdateMentor';
import MentorForm from '../../../components/forms/MentorForm';
import { DeleteContent } from '../../../components/alert/DeleteContent';
import { DeleteIcon } from '@chakra-ui/icons';
import AboutMentor from '../../../components/forms/AboutMentor';
import { DeleteMentor } from '../../../components/alert/DeleteMentor';
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';


function UpdateMentorList() {
  const { backendUrl } = useContext(EnvContext);

  const {
    fetchMentor,
    setToken,
    setMentorName,
    setMentorId,
    mentorId,
    mentors,
    getMentor,
  } = useContext(MentorContext);
  const [index, setIndex] = useState(0);
  const token = localStorage.getItem('token');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);

  const role = localStorage.getItem('role');

  const navigate = useNavigate();

  useEffect(() => {
    if (role !== "admin") navigate('/dashboard');
  }, [role]);
  useEffect(() => {
    if (token) setToken(token);
  }, [token]);
  useEffect(() => {
    fetchMentor();
  }, []);
  useEffect(() => {
    if (mentorId) getMentor();
  }, [mentorId]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchMentor();
    }, 1000); // Adjust debounce/throttle time as needed

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);
  const handleInputChange = event => {
    setMentorName(event.target.value);
    setSearchTerm(event.target.value);
  };
  const handleButtonClick = (value) => {
    setSelectedTab(value); // Index 2 corresponds to the third tab
  };

  return (
    <HStack w={'100%'} h={'100vh'} alignItems={'flex-start'} p={3}>
      <VStack w={'40%'} alignItems={'flex-start'}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            {/* <PhoneIcon color='gray.300' /> */}
            <CiSearch color="gray.300" size={20} />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Mentor Name"
            value={searchTerm}
            onChange={handleInputChange}
          />
        </InputGroup>
        {mentors && mentors.length > 0 ? (
          mentors.map((item, i) => (
            <>
              <Box bg={i === index && 'blue.100'} p={1} w={'100%'}>
                <HStack
                  justifyContent={'space-between'}
                  w={'95%'}
                  cursor={'pointer'}
                  key={i}
                  onClick={() => {
                    setMentorId(item._id);
                    setIndex(i);
                  }}
                >
                  <HStack onClick={()=>handleButtonClick(0)} w={'100%'}>
                    <Avatar
                      size="lg"
                      name="Prosper Otemuyiwa"
                      src={`${backendUrl}${item.dp}`}
                    />
                    <Text>{item.name}</Text>
                  </HStack>
                  <IconButton
                      icon={<FiEdit2 />}
                      aria-label="Edit"
                      colorScheme="gray"
                      ml={2}
                      onClick={()=>handleButtonClick(2)}
                    />
                  <DeleteMentor mentorId={item._id}>
                    <IconButton
                      icon={<DeleteIcon />}
                      aria-label="Delete"
                      colorScheme="red"
                      ml={2}
                    />
                  </DeleteMentor>
                </HStack>
              </Box>
              {i !== mentors.length - 1 && <Divider orientation="horizontal" />}
            </>
          ))
        ) : (
          <Text>No Such Mentor </Text>
        )}
      </VStack>
      <Divider orientation="vertical" />
      <VStack w={'60%'} alignItems={'flex-start'}>
        <Tabs index={selectedTab} onChange={index => setSelectedTab(index)}>
          <TabList>
            <Tab>About Mentor</Tab>
            <Tab>Add Mentor</Tab>
            <Tab>Update Mentor</Tab>
          </TabList>

          <TabPanels>
          <TabPanel>
              <AboutMentor />
            </TabPanel>
            <TabPanel>
              <MentorForm />
            </TabPanel>
            <TabPanel>
              <UpdateMentor />
            </TabPanel>
            
          </TabPanels>
        </Tabs>
      </VStack>
    </HStack>
  );
}

export default UpdateMentorList;
