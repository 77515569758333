import { HStack, Heading, Text, VStack,List,ListItem,ListIcon } from '@chakra-ui/react'
import React from 'react'
import { dummyAbout } from '../assets/images'
import { MdOutlineDoubleArrow } from 'react-icons/md';
import courseFeatures from '../data/about_us';
import courseServises from '../data/servises';
import Lottie from 'react-lottie';
import servicesAnnimation from "../assets/annimation/AnnimationServices.json";
function Services() {
  const getDefaultOptions = animationData => {
    let defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return defaultOptions;
  };
  return (
    <>
      <HStack justifyContent={'space-around'} w={'100%'} p={10} alignItems={'flex-start'} flexDirection={{
        base:'column-reverse',
        sm:'column-reverse',
        md:'column-reverse',
        lg:'row',
      }} >
        <VStack
        gap={"1.5rem"}
         w={{
          
          base:'100%',
          sm:'100%',
          md:"100%",
          lg:"60%",
          }} alignItems={'flex-start'}>
          <Heading>
        We offers a wide range of training programs
          </Heading>
          <List lineHeight ={'1.8rem'}>
          {
            courseServises && courseServises.map((item,i)=>(
              <ListItem key={i}>
          <ListIcon as={MdOutlineDoubleArrow} color="blue.500" />
          {item.description}
          </ListItem>
            ))
          }
          </List>
        </VStack>
        <VStack w={{
          
          base:'100%',
          sm:'100%',
          md:"100%",
          lg:"40%",
          }}>
          <Lottie options={getDefaultOptions(servicesAnnimation)} />
        </VStack>
      </HStack>
    </>
  )
}

export default Services