import React from 'react'
import {
  Flex,
  Text,
  Box,
} from '@chakra-ui/react';

function SubHeader({ heading }) {
  return (
    <Box p={5}>
      <Flex
        width={'100%'}
        height={'10vh'}
        bg={'rgb(66 153 225 / 23%)'}
        rounded={10}
        justifyContent={'center'}
        alignItems={'center'}

      >
        <Text fontFamily={'none'} fontSize={{
          base: "xx-large",
          sm: "xx-large",
          md: 'xxx-large',
          lg: 'xxx-large',
        }}>
          {heading}
        </Text>
      </Flex>
    </Box>
  )
}

export default SubHeader