import {
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
    useDisclosure,
  } from '@chakra-ui/react';
  import React, { useState } from 'react';
  import { Button } from 'react-scroll';
  import SyllabusForm from '../forms/SyllabusForm';
import { UpdateSyllabus } from '../../pages/admin/syllabus/UpdateSyllabus';
  
  export const UpdateSyllabusModal = ({ children ,courseName }) => {
    const [chaptersJSON, setChaptersJSON] = useState('');
    const { isOpen, onClose, onOpen } = useDisclosure();
    const handleChaptersJSONChange = json => {
      setChaptersJSON(json);
    };
    return (
      <>
        <span onClick={onOpen}>{children}</span>
        <Modal
          isCentered
          isOpen={isOpen}
          size={'xl'}
          scrollBehavior={'inside'}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Syllabus</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
             <UpdateSyllabus/>
            </ModalBody>
  
            
          </ModalContent>
        </Modal>
      </>
    );
  };
  