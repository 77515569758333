import { HStack, VStack, Text, Divider, Tabs, TabList, TabPanels, Tab, TabPanel, Box, IconButton ,Button} from '@chakra-ui/react';
import React, { useContext, useEffect,useState } from 'react';
import AddCoupon from '../../../components/forms/AddCoupon';
import UpdateCoupon from '../../../components/forms/UpdateCoupon';
import CouponContext from '../../../context/CouponContext';
import { DeleteCoupon } from '../../../components/alert/DeleteCoupon';
import { DeleteIcon } from '@chakra-ui/icons';
import AboutCoupon from './AboutCoupon';
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
function Coupon() {
  const { coupons, getCoupon, setCouponId, couponId, getAboutCoupon } = useContext(CouponContext)
  const [selectedTab, setSelectedTab] = useState(0);

  const handleButtonClick = (value) => {
    setSelectedTab(value); // Index 2 corresponds to the third tab
  };

  useEffect(() => {
    getCoupon()
  }, [])
  const role = localStorage.getItem('role');

  const navigate = useNavigate();

  useEffect(() => {
    if (role !== "admin") navigate('/dashboard');
  }, [role]);

  useEffect(() => {
    if (couponId) {
      console.log('couponId', couponId)
    }
  }, [couponId])
  return (
    <HStack w={'100%'} minH={'100vh'} alignItems={'flex-start'} px={8}>
      <VStack w={'30%'}>
        {
          coupons && coupons.length > 0 ? coupons.map((el, i) => (
            <>
              <Box bg={el._id === couponId && 'blue.100'} p={1} w={'100%'}>
                <HStack
                  justifyContent={'space-between'}
                  w={'95%'}
                  cursor={'pointer'}
                  key={i}
                  onClick={() => {
                    setCouponId(el._id);
                    getAboutCoupon(el.code)
                    
                  }}
                >
                  <HStack onClick={()=>handleButtonClick(0)} w={'100%'}>
                    <Text >{i + 1}.</Text>
                    <Text >{el.code}</Text>
                  </HStack>
                  <IconButton
                      icon={<FiEdit2 />}
                      aria-label="Edit"
                      colorScheme="gray"
                      ml={2}
                      onClick={()=>handleButtonClick(2)}
                    />
                  <DeleteCoupon couponId={el._id}>
                    <IconButton
                      icon={<DeleteIcon />}
                      aria-label="Delete"
                      colorScheme="red"
                      ml={2}
                    />
                  </DeleteCoupon>
                </HStack>
              </Box>

            </>

          )
          ) : (
            <Text>no coupon </Text>
          )
        }
      </VStack>
      <Divider orientation="vertical" />
      <VStack w={'70%'}>
        <Tabs index={selectedTab} onChange={index => setSelectedTab(index)}>
          <TabList>
            <Tab>About Coupon</Tab>
            <Tab>Add Coupon</Tab>
            <Tab>Update Coupon</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <AboutCoupon />
            </TabPanel>
            <TabPanel>
              <AddCoupon />
            </TabPanel>
            <TabPanel>
              <UpdateCoupon />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </HStack>
  );
}

export default Coupon;
