import React, { useContext, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Flex,
  InputGroup,
  InputRightElement,
  IconButton,
  FormErrorMessage,
  Avatar,
  SkeletonCircle,
  SkeletonText,
  Text,
  Center,
  Heading,
} from '@chakra-ui/react';

import { useState } from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { logo } from '../../assets/images';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import AuthContext from '../../context/AuthContext';
import axios from 'axios';
import toast from 'react-hot-toast';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
});

export const UserUpdateProfile = () => {
  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    phone: '',
  });


  const { userData, handleUserData } = useContext(AuthContext);
  const [showForm, setShowForm] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePath, setImagePath] = useState({});
  const fileInputDocRef = useRef(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  useEffect(() => {
    console.log('functiom is called');
    handleUserData().then(() => {
      setShowForm(true);
    });
  }, []);
  useEffect(() => {
    if (userData) {
      setShowForm(true);
      setInitialValues({
        ...initialValues,
        name: userData.name,
        email: userData.email,
        phone: userData.phone,

      });
    }
  }, [userData]);

  const handleFileSelection = e => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      setImagePath(selectedImage);
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };
  const handelButton = () => {
    fileInputDocRef.current.click();
  };
  let loadingToast;
  const handleSubmit = async (values, { setSubmitting }) => {
    console.log(values)
    const formData = new FormData();
    formData.append("name", values.name)
    formData.append("email", values.email)
    formData.append("phone", values.phone)
    formData.append("password", values.password)
    formData.append("dp", imagePath)


    try {
      loadingToast = toast.loading("Updating Profile ...", {
        position: 'top-right',
        style: {
          marginTop: '62px',
        }
      });

      axios
        .put(`${BACKEND_URL}/user/update`, formData, {
          headers: headers,
        })
        .then(res => {
          console.log('res', res.data.message);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            }
          })
          setShowForm(false);
          handleUserData().then(() => {
            setShowForm(true);
          });
        })
    }  catch (err) {
      if(err.response.data.message)
      toast.error(`${err.response.data.message}`,{
        position: 'top-right',
        style: {
          marginTop: '62px',
        }
      })
      else toast.error('Something went wrong',{
        position: 'top-right',
        style: {
          marginTop: '62px',
        }
      })
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }

  };
  return (
    // <Flex p={10} top={10} alignItems={'center'} direction="column">
    <Box>
    <Heading size={'lg'} mb={5} >Your Account</Heading>
      {showForm ? (
        <Formik
          initialValues={{
            name: userData?.name || '',
            email: userData?.email || '',
            phone: userData?.phone || '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form className="userupdateprofileform">
              <Flex
                alignItems={'Center'}
                justifyContent={'space-between'}
                mb={'25px'}
              >
                <Avatar
                  size="2xl"
                  name={userData?.name}
                  src={image ? image : `${BACKEND_URL}${userData?.dp}`}
                />

                <Text fontSize={'20px'} fontWeight={'700'}>
                  update your profile Photo
                </Text>

                <Button type="button" onClick={handelButton}>
                  Upload photo
                  <input
                    ref={fileInputDocRef}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileSelection}
                  />
                </Button>
              </Flex>
              <hr />

              <Field name="name">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.name && form.touched.name}
                  >
                    <FormLabel>Name</FormLabel>
                    <Input {...field} placeholder="name" />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="email">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <FormLabel>Email</FormLabel>
                    <Input {...field} placeholder="Email" />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="phone">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.phone && form.touched.phone}
                  >
                    <FormLabel>Phone number</FormLabel>
                    <Input {...field} placeholder="Phone number" />
                    <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              {/* <Field name="password">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.password && form.touched.password}
                  >
                    <FormLabel>Password</FormLabel>
                    <InputGroup>
                      <InputRightElement>
                        <IconButton
                          variant="text"
                          aria-label={
                            showPassword ? 'Hide password' : 'Reveal password'
                          }
                          icon={showPassword ? <HiEyeOff /> : <HiEye />}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </InputRightElement>
                      <Input
                        {...field}
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        placeholder="Password"
                      />
                    </InputGroup>
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="confirmPassword">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.confirmPassword &&
                      form.touched.confirmPassword
                    }
                  >
                    <FormLabel>Confirm Password</FormLabel>
                    <InputGroup>
                      <InputRightElement>
                        <IconButton
                          variant="text"
                          aria-label={
                            showConfirmPassword
                              ? 'Hide password'
                              : 'Reveal password'
                          }
                          icon={showConfirmPassword ? <HiEyeOff /> : <HiEye />}
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        />
                      </InputRightElement>
                      <Input
                        {...field}
                        type={showConfirmPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        placeholder="Confirm Password"
                      />
                    </InputGroup>
                    <FormErrorMessage>
                      {form.errors.confirmPassword}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field> */}
              <Stack spacing="6" mt={5}>
                <Button type="submit" isLoading={isSubmitting}>
                  Submit
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      ) : (
        <Box padding="6" boxShadow="lg" bg="white">
          <SkeletonCircle size="10" />
          <SkeletonText mt="4" noOfLines={4} spacing="4" skeletonHeight="2" />
        </Box>
      )}
    </Box>
    // </Flex>
  );
};
