import React from 'react';
import { useContext } from 'react';
import MentorContext from '../../context/MentorContext';
import { Text } from '@chakra-ui/react';
import EnvContext from '../../context/EnvContext';
import { Label } from 'recharts';

function AboutMentor() {
  const { mentorData } = useContext(MentorContext);
  const { backendUrl } = useContext(EnvContext);

  return (
    <>
      {mentorData.name ? (
        <>
            <Text><strong> Name :   </strong> {mentorData.name} </Text>
          <Text><strong>Designation:   </strong> {mentorData.designation} </Text>
          <Text> <strong>Experience:   </strong> {mentorData.exp} </Text>
          <Text><strong>About:   </strong>{mentorData.about} </Text>
          <Text> <strong>Signature :</strong>
          <img width={'45%'} style={{border:"1px solid"}} src={backendUrl + mentorData?.sign}>
          </img>
          </Text>
        
        </>
      ) : (
        <Text> Select any mentor </Text>
      )}
    </>
  );
}

export default AboutMentor;
