import {
  HStack,
  VStack,
  Text,
  Image,
  Divider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
  IconButton,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import EnvContext from '../../../context/EnvContext';
import AddContentForm from '../../../components/forms/AddContentForm';
import { Toaster } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { DeleteCourse } from '../../../components/alert/DeleteCourse';
import { DeleteIcon } from '@chakra-ui/icons';
import CourseShowcase from '../course/CourseShowcase';
import CourseContext from '../../../context/CourseListContext';
import SyllabusContext from '../../../context/SyllabusContext';

export const CourseHome = () => {
  const { backendUrl } = useContext(EnvContext);
  const { courses, fetchData, setToken, setCourseId, courseId, getCourse,courseData } =
    useContext(CourseContext);
    const {getSyllabus}=useContext(SyllabusContext)
  const token = localStorage.getItem('token');
  const [index, setIndex] = useState(null);
  useEffect(() => {
    if (token) setToken(token);
  }, [token]);

  useEffect(() => {
    if (courseId) {
      getCourse();
    }
  }, [courseId]);
  useEffect(() => {
    if (courseData.syllabus) {
      getSyllabus(courseData.syllabus._id);
    }
  }, [courseData]);
  useEffect(() => {
    fetchData();
  }, []);

  const role = localStorage.getItem('role');

  const navigate = useNavigate();

  useEffect(() => {
    if (role !== "admin") navigate('/dashboard');
  }, [role]);
  return (
    <HStack w={'100%'} h={'100vh'} alignItems={'flex-start'} px={'5rem'}>
      <VStack
        w={'60%'}
        alignItems={'center'}
        justifyContent={'center'}
        spacing={2}
      >
        {courses && courses.length > 0 ? (
          courses.map((course, i) => (
            <>
              <HStack
                p={1}
                justifyContent={'space-between'}
                key={course._id}
                onClick={() => {
                  setCourseId(course._id);
                  setIndex(i);
                }}
                cursor={'pointer'}
                bg={i === index && 'blue.100'}
                w={'100%'}
              >
                <HStack key={course.courseName}>
                  <Image
                    boxSize="100px"
                    objectFit="cover"
                    src={`${backendUrl}${course.banner}`}
                    alt="Dan Abramov"
                  />
                  <p>{course.courseName}</p>
                </HStack>
                <HStack spacing={3}   key={i}>
                  <DeleteCourse courseId={course._id}>
                    <IconButton
                      icon={<DeleteIcon />}
                      aria-label="Delete"
                      colorScheme="red"
                      ml={2}
                    />
                  </DeleteCourse>
                  <Button
                    colorScheme="orange"
                    onClick={() => setCourseId(course._id)}
                  >
                    <Link to="/admin/manage_content">Manage Contents</Link>
                  </Button>
                  <Button
                    colorScheme="orange"
                    onClick={() => setCourseId(course._id)}
                  >
                    <Link to="/admin/manage_projects">Manage Projects</Link>
                  </Button>
                </HStack>
              </HStack>
              {i !== courses.length - 1 && <Divider orientation="horizontal" />}
            </>
          ))
        ) : (
          <h1> Courses are loading</h1>
        )}
      </VStack>
      <Divider orientation="vertical" />
      <VStack w={'40%'} alignItems={'flex-start'}>
        <Tabs w={'100%'}>
          <TabList>
            <Tab>About Course</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <CourseShowcase index={index} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
      <Toaster />
    </HStack>
  );
};
