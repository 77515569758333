import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Center,
  CloseButton,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import EnvContext from '../../context/EnvContext';

function PlayVideo({children,videoUrl}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { backendUrl } = useContext(EnvContext);

  return (
    <>
       <span onClick={onOpen}>{children}</span>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
         
          <ModalCloseButton />

          <ModalBody px={4} py={8}>
            <iframe
              width="100%"
              height="500px"
              src={`${backendUrl}${videoUrl}`}
              title="Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PlayVideo;
