import {
  HStack,
  Text,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  Avatar,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-scroll';
import SyllabusForm from '../forms/SyllabusForm';
import MentorForm from '../forms/MentorForm';
import EnvContext from '../../context/EnvContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import { CartItem } from '../others/CartItem';
import CartContext from '../../context/CartContext';
import { Course } from '../../pages/user/regular';

export const AdminCartModal = ({ isOpen, onClose, users }) => {
  const { backendUrl } = useContext(EnvContext);
  const { userId, adminCart, getAdminCart } = useContext(CartContext)
  // console.log('i li=ove you');

  useEffect(() => {
    if (users) {
      console.log("usersusersusersusers", users)
    } else console.log("usernot found")
  }, [users])

  useEffect(() => {
    if (adminCart) {
      console.log("adminCartadminCart", adminCart)
    } else console.log("admincart not  found")
  }, [adminCart])

  useEffect(() => {
    if (userId) {
      getAdminCart()
    }
  }, [userId])

  return (
    <>
      <Modal
        isCentered
        isOpen={isOpen}
        size={'full'}
        scrollBehavior={'inside'}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cart Handle</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack w={'100%'} alignItems={'flex-start'}>
              <VStack w={'50%'}>
                <HStack p={4} bgColor={'blue.100'} spacing={10} alignItems={'center'} w={'100%'}>
                  <Avatar size='md' name='Ryan Florence' src={backendUrl + users?.dp} />{' '}
                  <Text>{users?.name}</Text>
                  <Text>{users?.email}</Text>
                </HStack>
                {adminCart &&
                  adminCart.length > 0 &&
                  adminCart.map(item => <CartItem key={item.id} {...item} />)}
              </VStack>
              <Divider orientation="vertical" />
              <VStack w={'50%'} overflowY={'scroll'} maxH={'97vh'}>
                <Course />
              </VStack>
            </HStack>
          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  );
};
