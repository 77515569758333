import React from 'react';
import {
  Box,
  Text,
  Heading,
  List,
  ListItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  VStack,
  Flex,
  ListIcon,
} from '@chakra-ui/react';
import { MdOutlineDoubleArrow } from 'react-icons/md';
import tableOfContents, { termsData } from '../../data/cancellation_n_refund';

const Cancellation_n_refund = () => {
  return (
    <Box p={5}>
      <Text mb={4}>
        Please read these Cancellation & Refund policy carefully before using the "Steps
        of Learning Process" website. By using the Website, you agree to comply
        with and be bound by these Terms.
      </Text>
      <Box mx={{
        base: 0,
        sm: 0,
        md: '3rem',
        lg: '5rem'
      }}>
        {termsData.map((section, sectionIndex) => (
          <React.Fragment key={sectionIndex}>
            <Heading as="h4" size="md" id={section.id} mb={2}>
              {section.heading}
            </Heading>
            {section.content.map((contentItem, contentIndex) => (
              <Box key={contentIndex} ml={10}>
                {contentItem.subheading && (
                  <Heading as="h5" size="sm" mb={2}>
                    {contentItem.subheading}
                  </Heading>
                )}
                <List spacing={2} mb={4}>
                  {contentItem.items.map((item, itemIndex) => (
                    <ListItem key={itemIndex}>
                      <ListIcon as={MdOutlineDoubleArrow} color="blue.500" />
                      {item}
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </React.Fragment>
        ))}
      </Box>

      <Text>
        By using the "Steps of Learning Process" Website, you acknowledge that
        you have read, understood, and agree to be bound by these Terms and our
        Privacy Policy. If you do not agree with these Terms, please refrain
        from using the Website.
      </Text>
    </Box>
  );
};

export default Cancellation_n_refund;
