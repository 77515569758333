import {
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
    useDisclosure,
  } from '@chakra-ui/react';
  import React, { useContext, useState } from 'react';
  import { Button } from 'react-scroll';
  import SyllabusForm from '../forms/SyllabusForm';
import MentorForm from '../forms/MentorForm';
import EnvContext from '../../context/EnvContext';
  
  export const NotesOpener = ({ children,notes }) => {
    const { backendUrl } = useContext(EnvContext);
    const { isOpen, onClose, onOpen } = useDisclosure();

    return (
      <>
        <span onClick={onOpen}>{children}</span>
        <Modal
          isCentered
          isOpen={isOpen}
         size={'full'}
          scrollBehavior={'inside'}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
          <ModalHeader>Attached Notes</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <embed src={`${backendUrl}${notes}`} type="application/pdf" width="100%" height="550px" />
            </ModalBody>
  
            
          </ModalContent>
        </Modal>
      </>
    );
  };

