const termsAndConditions = [
    {
      id: "introduction",
      heading: "1. Introduction",
      list: [
        "\"Steps of Learning Process\" hereinafter referred to as (\"we,\" \"us,\" or \"our\") is deeply committed to safeguarding the privacy and security of your data. These Terms have been established to provide a comprehensive overview of our policies concerning the responsible handling of user data.",
        "The term \"User\" or \"you\" refers to any individual or entity accesses or utilize our Website."
      ]
    },
    {
      id: "data",
      heading: "2. Data Collection and Use",
      list: [
        "Our data collection and utilization practices strictly adhere to our Privacy Policy, accessible on our Website. Any improper handling of data, including unauthorized access, disclosure, or sharing of user information, is considered a violation.",
        "You agree not to misuse any data obtained from the Website for any unlawful or malicious purpose."
      ]
    },
    {
      id: "security",
      heading: "3. Security Measures",
      list: [
        "We have implemented appropriate security measures to safeguard user data against misuse. Any effort to circumvent or compromise these security measures will be deemed a violation of our Terms."
      ]
    },
    {
      id: "unauthorized",
      heading: "4. Unauthorized Access",
      list: [
        "Any attempt to gain unauthorized access to user accounts, databases, or any other systems associated with the Website is expressly prohibited and will be treated as a violation of these Terms."
      ]
    },
    {
      id: "data_sharing",
      heading: "5. Data Sharing",
      list: [
        "Under no circumstances should user data collected from the website be shared or distributed without obtaining explicit consent from the user."
      ]
    },
    {
      id: "reporting",
      heading: "6. Reporting Misuse",
      list: [
        "If you suspect or discover any instances of data misuse in connection with the Website, please promptly report it to us using the contact information provided on the Website."
      ]
    },
    {
      id: "consequence",
      heading: "7. Consequences of Misuse",
      list: [
        "Any breach of these Terms may lead to legal consequences, encompassing civil and criminal penalties, among other potential actions."
      ]
    },
    {
      id: "indemnification",
      heading: "8. Indemnification",
      list: [
        "You commit to providing indemnification and safeguarding \"Steps of Learning Process\" against all claims, losses, damages, liabilities, and expenses (including legal fees) that may arise due to or be connected with your improper handling of user data."
      ]
    },
    {
      id: "termination",
      heading: "9. Termination",
      list: [
        "We retain the authority to revoke your access to the Website and seek legal recourse if we possess valid grounds to suspect misuse of user data on your part."
      ]
    },
    {
      id: "modification",
      heading: "10. Modifications",
      list: [
        "We reserve the right to update or amend these Terms at any time. Any revisions will take effect immediately upon being posted on the Website. It is your responsibility to regularly review these Terms for any changes."
      ]
    },
    {
      id: "governing",
      heading: "11. Governing Law",
      list: [
        "These Terms are subject to the laws of Jurisdiction, and any dispute arising from or related to these Terms shall be exclusively resolved in the courts of Jurisdiction."
      ]
    }
  ];

  export const tableOfContents = [
    { section: 1, title: "Introduction", href: "#introduction" },
    { section: 2, title: "Data Collection and Use", href: "#data" },
    { section: 3, title: "Security Measures", href: "#security" },
    { section: 4, title: "Unauthorized Access", href: "#unauthorized" },
    { section: 5, title: "Data Sharing", href: "#data_sharing" },
    { section: 6, title: "Reporting Misuse", href: "#reporting" },
    { section: 7, title: "Consequences of Misuse", href: "#consequence" },
    { section: 8, title: "Indemnification", href: "#indemnification" },
    { section: 9, title: "Termination", href: "#termination" },
    { section: 10, title: "Modifications", href: "#modification" },
    { section: 11, title: "Governing Law", href: "#governing" },
  ];
  
  export default termsAndConditions;
  