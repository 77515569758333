import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Text,
  Button,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import AssignmentContext from '../../../context/AssignmentContext';
import { Quizes } from '../../../pages/user/authentication/quiz/Quizes';
import { GiHamburgerMenu } from 'react-icons/gi';
import { QuestionsDrawer } from '../../drawer/QuestionsDrawer';
import { FaLongArrowAltLeft } from 'react-icons/fa';

export const QuizModal = ({ children, courseContentId }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    getAllQuestions,
    questions,
    prevAttempts,
    previousAttempts,
    setCurrentIndex,
    currentIndex,
    setQuesId,
    setSelectedAnswers,
  } = useContext(AssignmentContext);

  const goToNextQuestion = () => {
    setCurrentIndex(prevIndex => prevIndex + 1);
  };

  const goToPreviousQuestion = () => {
    setCurrentIndex(prevIndex => prevIndex - 1);
  };

  useEffect(() => {
    if (!questions || questions?.length === 0) return;
    console.log('questions', questions);

    previousAttempts(questions[0]._id);
  }, [questions]);

  useEffect(() => {
    if (courseContentId) {
      getAllQuestions(courseContentId);
      console.log('courseContentId', courseContentId);
    }
  }, [courseContentId]);
  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        isCentered
        isOpen={isOpen}
        size={{ base: 'xl', md: 'full', lg: 'full' }}
        // height={"60vh"} width={"80%"}
        scrollBehavior={'inside'}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack>
              <QuestionsDrawer>
                <GiHamburgerMenu />
              </QuestionsDrawer>

              <Text>
                {' '}
                Assignments{' '}
                <span style={{ fontSize: '13px', color: 'gray' }}>
                  (Total no. of Questions: {questions && questions.length})
                </span>
              </Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY={'none'} py={0}>
            <HStack spacing={10} w={'100%'}>
              <Box w={'100%'} position={'relative'}>
                <Tabs
                  height={'calc(100vh - 105px)'}
                  w={'100%'}
                  defaultIndex={0}
                >
                  <TabList>
                    <Tab>Quiz</Tab>
                    <Tab>Submissions</Tab>
                    <HStack
                      gap={8}
                      style={{ right: 0, top: '4px' }}
                      position={'absolute'}
                      zIndex={'100000'}
                    >
                      {currentIndex === 0 ? (
                        ''
                      ) : (
                        <Button
                          colorScheme="teal"
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            if (currentIndex > 0) {
                              goToPreviousQuestion();
                              setSelectedAnswers([]);
                              setQuesId(questions[currentIndex - 1]._id);
                              const questionId =
                                questions[currentIndex - 1]._id;
                              previousAttempts(questionId);
                            }
                          }}
                          disabled={currentIndex === 0}
                          style={{ cursor: 'pointer' }}
                        >
                          <FaLongArrowAltLeft size={40} />
                        </Button>
                      )}

                      {currentIndex === questions.length - 1 ? (
                        ''
                      ) : (
                        <Button
                          colorScheme="teal"
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            if (currentIndex < questions.length - 1) {
                              goToNextQuestion();
                              setSelectedAnswers([]);
                              setQuesId(questions[currentIndex + 1]._id);
                              console.log(
                                'questions[currentIndex + 1]._id',
                                questions[currentIndex + 1]._id
                              );
                              const questionId =
                                questions[currentIndex + 1]._id;
                              previousAttempts(questionId);
                            }
                          }}
                          disabled={currentIndex === questions.length - 1}
                          style={{ cursor: 'pointer' }}
                        >
                          <FaLongArrowAltLeft
                            size={40}
                            style={{ rotate: ' 180deg' }}
                          />
                        </Button>
                      )}
                    </HStack>
                  </TabList>

                  <TabPanels height={'100%'}>
                    <TabPanel height={'100%'}>
                      <Quizes />
                    </TabPanel>
                    <TabPanel height={'100%'}>
                      {/* {Array.isArray(prevAttempts) &&
                        prevAttempts.map((el, i) => (
                          <>
                            {Array.isArray(el.answer) &&
                              el.answer.length > 0 &&
                              el.answer.map((el, i) =>
                              
                               <li key={i}>{el}</li>)}
                            <hr />
                          </>
                        ))} */}
                      <Table size="md">
                        <Thead bgColor={'blue.100'}>
                          <Tr>
                            <Th>Answer</Th>
                            <Th>Submission Time</Th>
                            <Th>Result</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {Array.isArray(prevAttempts) &&
                            prevAttempts.map((item, index) => (
                              <Tr key={index}>
                                <Td>
                                  <ul>
                                    {item.answer.map((ans, idx) => (
                                      <li key={idx}>{ans}</li>
                                    ))}
                                  </ul>
                                </Td>
                                <Td>
                                  {new Date(item.createdAt).toLocaleString()}
                                </Td>
                                <Td>
                                  <Badge
                                    py={1}
                                    px={2}
                                    borderRadius={5}
                                    colorScheme={
                                      item.isCorrectAnswer ? 'green' : 'red'
                                    }
                                  >
                                    {item.isCorrectAnswer ? 'True' : 'False'}
                                  </Badge>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
