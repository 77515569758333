import { useState,useContext } from 'react'
import {
  Container,
  Box,
  Flex,
  Input,
  Button,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  InputGroup,
  InputRightElement,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import axios from 'axios';
import { HiEye, HiEyeOff } from 'react-icons/hi'
import EnvContext from '../context/EnvContext';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  password: Yup.string().min(6, 'Password must be at least 6 characters long').required('Password is required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Please confirm your password'),
});

function ChangePassword() {
  const [showPassword, setShowPassword] = useState(false);
  const { backendUrl } = useContext(EnvContext);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const genOtpEmail = localStorage.getItem('genOtpEmail');
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    const postData={
        email:genOtpEmail,
      password:values.password,
        
    }
    let loadingToast;
    try {
      loadingToast = toast.loading('Updating Password', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });

      await axios.post(`${backendUrl}/change_password`,postData).then(res => {
          console.log("res",res)
        toast.success(res.data.message,{
            position: 'top-right',
            style: {
              marginTop: '62px',
            }
          })
          navigate('/login')
      });

    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };
const handleBack = ()=>{
  navigate('/generate_otp')
}
  
  return (
    <Container
      maxW="lg"
      py={{
        base: '2',
        md: '5',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
    <Stack textAlign="center">
        <Heading fontSize={'3xl'}> Change your Password</Heading>
      </Stack>
      <Box
        w={'100%'}
        py={{
          base: '0',
          sm: '8',
        }}
        px={{
          base: '4',
          sm: '10',
        }}
        bg={{
          base: 'transparent',
          sm: 'bg.surface',
        }}
        boxShadow={{
          base: 'md',
          sm: 'md',
        }}
        borderRadius={{
          base: 'none',
          sm: 'xl',
        }}
      >
        <Stack spacing="6">
          <Stack spacing="5" width={'90%'}>
            <Formik
              initialValues={{
                otp: '',
                password: '',
                confirmPassword: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Text><strong>Email :</strong> {genOtpEmail}</Text>
                  <Field name="password">
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={
                          form.errors.password && form.touched.password
                        }
                      >
                        <FormLabel>Password</FormLabel>
                        <InputGroup>
                          <InputRightElement>
                            <IconButton
                              variant="text"
                              aria-label={
                                showPassword
                                  ? 'Hide password'
                                  : 'Reveal password'
                              }
                              icon={showPassword ? <HiEyeOff /> : <HiEye />}
                              onClick={() => setShowPassword(!showPassword)}
                            />
                          </InputRightElement>
                          <Input
                            {...field}
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="new-password"
                            placeholder="Password"
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.password}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="confirmPassword">
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={
                          form.errors.confirmPassword &&
                          form.touched.confirmPassword
                        }
                      >
                        <FormLabel>Confirm Password</FormLabel>
                        <InputGroup>
                          <InputRightElement>
                            <IconButton
                              variant="text"
                              aria-label={
                                showConfirmPassword
                                  ? 'Hide password'
                                  : 'Reveal password'
                              }
                              icon={
                                showConfirmPassword ? <HiEyeOff /> : <HiEye />
                              }
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                            />
                          </InputRightElement>
                          <Input
                            {...field}
                            type={showConfirmPassword ? 'text' : 'password'}
                            autoComplete="new-password"
                            placeholder="Confirm Password"
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.confirmPassword}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Stack spacing="6" mt={4}>
                  <HStack>
                  <Button type='button' onClick={handleBack}> Back </Button>
                    <Button type="submit" colorScheme='blue' isLoading={isSubmitting}>
                      Submit
                    </Button>
                    </HStack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
}

export default ChangePassword;
