import React, { useContext, useEffect } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  Image,
  Flex,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
  IconButton,
  FormErrorMessage,
} from '@chakra-ui/react'
import { logo } from '../assets/images'
import { useState } from 'react'
import { HiEye, HiEyeOff } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import AuthContext from '../context/AuthContext'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  // password: Yup.string().min(6, 'Password must be at least 6 characters long').required('Password is required'),
});


export const SignIn = () => {

  const {setUser,isChecked,setIsChecked} = useContext(AuthContext)
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
const [initialValues,setInitialValues] = useState({
  email: '',
  password: '',
})
const lastRoute = localStorage.getItem("lastRoute")
const token = localStorage.getItem("token");
useEffect(()=>{
if(token  && lastRoute){
  navigate(lastRoute);
}
},[])


  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };



  const handleSubmit = (values, { setSubmitting }) => {
    setUser(values);
    console.log("isChecked",isChecked)
    setSubmitting(false)
  };

  const handleForgot = ()=>{
    navigate('/generate_otp')
  }
  const rEmail = localStorage.getItem('rememberedEmail');
  const rPassword = localStorage.getItem('rememberedPassword');
useEffect(()=>{
if(rEmail && rPassword ){
  setInitialValues({
    ...initialValues,
    email:rEmail,
    password:rPassword,
  })
}
},[])

  return (
    // TODO: Design need to be changed
    <Container
      maxW="lg"
      py={{
        base: '2',
        md: '5',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          {/* <Logo /> */}
          <Flex justifyContent={'center'}>
            <Image w={'4rem'} src={logo} alignItems={'center'}></Image>
          </Flex>

          <Stack
            spacing={{
              base: '2',
              md: '3',
            }}
            textAlign="center"
          >
            <Heading

              fontSize={'3xl'}
            >
              Log in to your account
            </Heading>
            <Text color="fg.muted" fontSize={'xl'}>
              Don't have an account? <Link color='blue' onClick={() => navigate('/sign_up')}>Sign up</Link>
            </Text>
          </Stack>
        </Stack>
        <Box
          py={{
            base: '0',
            sm: '8',
          }}
          px={{
            base: '4',
            sm: '10',
          }}
          bg={{
            base: 'transparent',
            sm: 'bg.surface',
          }}
          boxShadow={{
            base: 'md',
            sm: 'md',
          }}
          borderRadius={{
            base: 'none',
            sm: 'xl',
          }}
        >
          <Stack spacing="6">
            <Stack spacing="5">

              <Formik
                initialValues={ initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Field name='email'>
                      {({ field, form }) => (
                        <FormControl isRequired isInvalid={form.errors.email && form.touched.email}>
                          <FormLabel>Email</FormLabel>
                          <Input {...field} placeholder='Email' />
                          <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='password'>
                      {({ field, form }) => (
                        <FormControl isRequired isInvalid={form.errors.password && form.touched.password}>
                          <FormLabel>Password</FormLabel>
                          <InputGroup>
                            <InputRightElement>
                              <IconButton
                                variant="text"
                                aria-label={showPassword ? 'Hide password' : 'Reveal password'}
                                icon={showPassword ? <HiEyeOff /> : <HiEye />}
                                onClick={() => setShowPassword(!showPassword)}
                              />
                            </InputRightElement>
                            <Input {...field} type={showPassword ? 'text' : 'password'}
                              autoComplete="new-password" placeholder='Password' />
                          </InputGroup>
                          <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <HStack justify="space-between">
                      <Checkbox isChecked={isChecked} onChange={handleCheckboxChange}>Remember me</Checkbox>
                      <Button variant="text" size="sm" onClick={handleForgot} >
                        Forgot password?
                      </Button>
                    </HStack>
                    <Stack spacing="6">
                      <Button type='submit' isLoading={isSubmitting}>Sign in</Button>

                    </Stack>
                  </Form>
                )}
                </Formik>
            </Stack>


          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}
