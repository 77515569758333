import React, { useState,useContext, useEffect } from 'react';
import { Button, FormControl, FormLabel, IconButton, Input, VStack, Stack, List, ListItem, Box, HStack, Text, Divider,Tabs,TabList,Tab,TabPanels,TabPanel, useEditable } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { DeleteIcon } from '@chakra-ui/icons';
import AddSubscription from '../../../components/forms/AddSubscription';
import CartContext from '../../../context/CartContext';
import axios from 'axios';
import EnvContext from '../../../context/EnvContext';
import AboutSubscription from './AboutSubscription';
import { FiEdit2 } from 'react-icons/fi';
import {DeleteSubscription} from '../../../components/alert/DeleteSubscription';
import UpdateSubscription from './UpdateSubscription';
import { useNavigate } from 'react-router-dom';


const SubscriptionPlan = () => {
  const {subscriptions,aboutSubscription,getAllSubscription,getAboutSubscription}=useContext(CartContext);
  const [selectedTab, setSelectedTab] = useState(0);
const [subscriptionId,setSubscriptionId]=useState("")

useEffect(()=>{
if(subscriptionId){
  console.log('subscriptionId',subscriptionId)
}else{
  console.log("no subscription")
}
},[subscriptionId])

useEffect(()=>{
  getAllSubscription()
},[])
const role = localStorage.getItem('role');

const navigate = useNavigate();

useEffect(() => {
  if (role !== "admin") navigate('/dashboard');
}, [role]);
useEffect(()=>{
  if(subscriptionId)
  getAboutSubscription(subscriptionId)
},[subscriptionId])
const handleButtonClick = (value) => {
  setSelectedTab(value); // Index 2 corresponds to the third tab
};


  return (
    <>
      <HStack alignItems={'centers'} p={5} minH={'100vh'} h={'100vh'}>
        <VStack w={'40%'} alignItems={'centers'}>
        
        {
          subscriptions && Array.isArray(subscriptions) && subscriptions.length>0 ? 
          subscriptions.map((el,i)=>(
            <>
            <Box bg={el._id === subscriptionId && 'blue.100'} p={1} w={'100%'}>
                <HStack
                  justifyContent={'space-between'}
                  w={'95%'}
                  cursor={'pointer'}
                  key={i}
                  onClick={() => {
                    setSubscriptionId(el._id);
                    // getAboutCoupon(el.code)
                    
                  }}
                >
                  <HStack onClick={()=>handleButtonClick(0)} w={'100%'}>
                    <Text >{i + 1}.</Text>
                    <Text >{el.name}</Text>
                  </HStack>
                  <IconButton
                      icon={<FiEdit2 />}
                      aria-label="Edit"
                      colorScheme="gray"
                      ml={2}
                      onClick={()=>handleButtonClick(2)}
                    />
                  <DeleteSubscription subscriptionId={el._id}>
                    <IconButton
                      icon={<DeleteIcon />}
                      aria-label="Delete"
                      colorScheme="red"
                      ml={2}
                    />
                  </DeleteSubscription>
                </HStack>
              </Box>
            </>
          ))
          :
          (
            <Text> No Subscription plan </Text>
          )
        }
        
        </VStack>
        <Divider orientation="vertical" />
        <VStack w={'60%'} alignItems={'centers'}>
        <Tabs index={selectedTab} onChange={index => setSelectedTab(index)}>
            <TabList>
            <Tab>About Subscription</Tab>
              <Tab>Add Subscription</Tab>
              <Tab>Update Subscription</Tab>
            </TabList>
            <TabPanels>
            <TabPanel>
              <AboutSubscription aboutSubscription ={aboutSubscription}/>
              </TabPanel>
              <TabPanel>
            <AddSubscription />
              </TabPanel>
              <TabPanel>
                <UpdateSubscription subscriptionId={subscriptionId} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </HStack>

    </>

  );
};

export default SubscriptionPlan;
