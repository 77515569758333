import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Box,
  Input,
  Button,
  VStack,
  Text,
  Textarea,
  Flex,
} from '@chakra-ui/react';
import toast from 'react-hot-toast';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import EnvContext from '../../../context/EnvContext';

const ChatBot1 = () => {
  const { backendUrl } = useContext(EnvContext);
  const [showContent, setShowContent] = useState(false);
  const [messages, setMessages] = useState([
    { sender: 'bot', text: 'Hello! How can I help you today?' },
  ]);
  const [input, setInput] = useState('');
  const [step, setStep] = useState(1);
  const [data, setData] = useState({});
  const [isConversationEnded, setIsConversationEnded] = useState(false);

  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const messageRef = useRef(null);
  const nameRef = useRef(null);

  const validationSchemas = [
    Yup.object().shape({
      input: Yup.string().required('Name is required'),
    }),
    Yup.object().shape({
      input: Yup.string().email('Invalid email').required('Email is required'),
    }),
    Yup.object().shape({
      input: Yup.string()
        .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
        .required('Phone number is required'),
    }),
    Yup.object().shape({
      input: Yup.string().required('Message is required'),
    }),
  ];

  useEffect(() => {
    setShowContent(true);
    setTimeout(() => {
      setMessages(prev => [
        ...prev,
        { sender: 'bot', text: 'Enter your name' },
      ]);
      setStep(2);
    }, 1000);
  }, []);

  useEffect(() => {
    switch (step) {
      case 2:
        nameRef.current?.focus();
        break;
      case 3:
        emailRef.current?.focus();
        break;
      case 4:
        phoneRef.current?.focus();
        break;
      case 5:
        messageRef.current?.focus();
        break;
      default:
        break;
    }
  }, [step]);

  const handleContact = async PostData => {
    let loadingToast;
    try {
      loadingToast = toast.loading('Adding contact', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios.post(`${backendUrl}/contact`, PostData).then(res => {
        toast.success(res.data.message, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      });
    } catch (error) {
      toast.error(error.response?.data?.message || 'Something went wrong', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
    } finally {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const handleSend = (values, actions) => {
    const input = values.input;
    if (!input) return;
    const newMessages = [...messages, { sender: 'user', text: input }];

    if (step < 5) {
      const nextMessage = getNextMessage(step + 1);
      setMessages([...newMessages, { sender: 'bot', text: nextMessage }]);
      setStep(step + 1);
    } else {
      setMessages([
        ...newMessages,
        {
          sender: 'bot',
          text: 'Thank you for reaching out to us. You will be contacted ASAP.',
        },
      ]);
      setIsConversationEnded(true);
      const finalData = { ...data, message: input };
      handleContact(finalData);
    }

    actions.resetForm();
    updateData(step, input);
  };

  const updateData = (step, value) => {
    switch (step) {
      case 2:
        setData(prevData => ({ ...prevData, name: value }));
        break;
      case 3:
        setData(prevData => ({ ...prevData, email: value }));
        break;
      case 4:
        setData(prevData => ({ ...prevData, phone: value }));
        break;
      case 5:
        setData(prevData => ({ ...prevData, message: value }));
        break;
      default:
        break;
    }
  };

  const getNextMessage = step => {
    switch (step) {
      case 2:
        return 'Enter your name';
      case 3:
        return 'Enter your email address';
      case 4:
        return 'Enter your mobile number';
      case 5:
        return 'Enter your message';
      default:
        return null;
    }
  };

  return (
    <Flex
      borderWidth="1px"
      borderRadius="lg"
      p={4}
      maxW={['100%', '100%', '400px']}
      mx="auto"
      maxH={['100%', '100%', '25rem']}
      w={['100%', '100%', '28rem']}
      h={['100%', '100%', '25rem']}
      flexDirection="column-reverse"
      boxShadow="dark-lg"
      alignItems="center"
      justifyContent="flex-start"
      overflowY="scroll"
      mt="3rem"
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
      }}
    >
      <VStack spacing={4} p="10px" width="100%">
        {messages.map((msg, index) => (
          <Box
            key={index}
            alignSelf={msg.sender === 'bot' ? 'flex-start' : 'flex-end'}
            bg={msg.sender === 'bot' ? '#ade3ad' : '#80808063'}
            p={2}
            rounded="20px"
            fontSize={msg.text.length > 20 ? 'sm' : 'md'}
            maxW="15rem"
          >
            <Text width="100%" textAlign={msg.sender !== 'bot' && 'center'}>
              {msg.text}
            </Text>
          </Box>
        ))}
        {!isConversationEnded && step <= 5 && (
          <Formik
            initialValues={{ input: '' }}
            validationSchema={validationSchemas[step - 2]}
            onSubmit={handleSend}
          >
            {({ errors, touched }) => (
              <Form style={{ width: '100%' }}>
                {step === 2 ? (
                  <Field name="input">
                    {({ field }) => (
                      <Input
                        {...field}
                        ref={nameRef}
                        type="text"
                        placeholder="Type your name"
                        isInvalid={errors.input && touched.input}
                      />
                    )}
                  </Field>
                ) : step === 3 ? (
                  <Field name="input">
                    {({ field }) => (
                      <Input
                        {...field}
                        ref={emailRef}
                        type="email"
                        placeholder="Type your email"
                        isInvalid={errors.input && touched.input}
                      />
                    )}
                  </Field>
                ) : step === 4 ? (
                  <Field name="input">
                    {({ field }) => (
                      <Input
                        {...field}
                        ref={phoneRef}
                        type="text"
                        placeholder="Type your phone number"
                        isInvalid={errors.input && touched.input}
                      />
                    )}
                  </Field>
                ) : step === 5 ? (
                  <Field name="input">
                    {({ field }) => (
                      <Textarea
                        {...field}
                        ref={messageRef}
                        placeholder="Type your message"
                        isInvalid={errors.input && touched.input}
                      />
                    )}
                  </Field>
                ) : null}
                {errors.input && touched.input && (
                  <Text color="red.500">{errors.input}</Text>
                )}
                <Button mt={2} type="submit">
                  Send
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </VStack>
    </Flex>
  );
};

export default ChatBot1;
