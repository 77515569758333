import React, { useEffect, useContext } from 'react';

import {
  HStack,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Image,
} from '@chakra-ui/react';
import AddSiteManager from './AddSiteManager';
import { useSiteManager } from '../../../context/SiteManagerContext';
import EnvContext from '../../../context/EnvContext';
import { useNavigate } from 'react-router-dom';

const SiteManager = () => {
  const { backendUrl } = useContext(EnvContext);
  const { getSiteManager, data,update } = useSiteManager();
  const navigate = useNavigate();
  useEffect(() => {
    getSiteManager();
  }, []);
  const role = localStorage.getItem('role');
  useEffect(() => {
    if (role !== "admin") navigate('/dashboard');
  }, [role]);
  useEffect(() => {
    if (data?.name && !update) navigate('/admin/home');
  }, [data]);

  return (
    <HStack alignItems={'flex-start'}>
      <VStack
        width={'40%'}
        borderRight={'1px solid'}
        borderColor={'gray.100'}
        px={'1rem'}
      >
        <Text fontWeight={700} fontSize={'large'}>
          Site Managers
        </Text>
        {data?.name ? (
          <VStack alignItems={'flex-start'} mt={'2rem'}>
            <Text>
              <Text fontWeight={600} fontSize={'large'} as={'span'}>
                Name:{' '}
              </Text>
              {data?.name}
            </Text>
            <Image src={backendUrl + data?.sign} width={'18rem'}></Image>
          </VStack>
        ) : (
          <Text fontWeight={500} color={'red'}>
            Add details on your right panel
          </Text>
        )}
      </VStack>
      <VStack w={'60%'}>
        {data?.name ? (
          <Text fontWeight={700} fontSize={'large'}>
            Update Site Manager
          </Text>
        ) : (
          <Text fontWeight={700} fontSize={'large'}>
            Manage Site Manager
          </Text>
        )}


        <AddSiteManager />
      </VStack>
    </HStack>
  );
};

export default SiteManager;
