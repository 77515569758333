import {
    HStack,
    Text,
    Divider,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
    useDisclosure,
  } from '@chakra-ui/react';
  import React, { useContext, useEffect, useState } from 'react';
  import { Button } from 'react-scroll';
  import SyllabusForm from '../forms/SyllabusForm';
  import MentorForm from '../forms/MentorForm';
  import EnvContext from '../../context/EnvContext';
  import axios from 'axios';
  import toast from 'react-hot-toast';
  import { CartItem } from '../others/CartItem';
  import CartContext from '../../context/CartContext';
  import { Course } from '../../pages/user/regular';
import WishListContext from '../../context/WishListContext';
  
  export const AdminWishLIstModal = ({ children}) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
  const {userId,getAdminCart,wishListData} =useContext(WishListContext);

  useEffect(()=>{
    if(userId){
        getAdminCart()
    }
      },[userId])
    return (
      <>
      <span onClick={onOpen}>{children}</span>
        <Modal
          isCentered
          isOpen={isOpen}
          size={'xl'}
          scrollBehavior={'inside'}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>WishList Handle</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            {
                wishListData && Array.isArray(wishListData)
                 && wishListData.length > 0 &&
                 wishListData.map((el,i)=>(
                    <HStack key={i} alignItems={'flex-start'} p={5} minH={"20vh"}>
                    <HStack><Text>{i+1} .</Text></HStack>
                    <VStack>
                        <Text><strong>Course Name</strong>{el.courseName}</Text>
                        <Text><strong>Original Price</strong>{el.originalPrice}</Text>
                        <Text><strong>Offer Price</strong>{el.offerPrice}</Text>
                        </VStack>
                    </HStack>
                 ))
            }
            </ModalBody>
        
          </ModalContent>
        </Modal>
      </>
    );
  };
  