import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  Text,
  IconButton,
  Divider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import { ContentList } from './ContentList';
import { AboutContent } from './AboutContent';
import UpdateContentForm from '../../../components/forms/UpdateContentForm';
import ContentContext from '../../../context/ContentContext';
import InsertContentForm from '../../../components/forms/InsertContentForm';
import AddContentForm from '../../../components/forms/AddContentForm';
import { useNavigate } from 'react-router-dom';
export const ManageContent = () => {
  const {
    fetchAllContent,
    contents,
    setToken,
    setContentId,
    contentId,
    fetchContent,
  } = useContext(ContentContext);

  const token = localStorage.getItem('token');
  useEffect(() => {
    if (token) setToken(token);
  }, [token]);
  const role = localStorage.getItem('role');

  const navigate = useNavigate();

  useEffect(() => {
    if (role !== "admin") navigate('/dashboard');
  }, [role]);

  useEffect(() => {
    fetchAllContent();
  }, []);
  return (
    <Box px={'5rem'}>
      <HStack w={'100%'} p={4} spacing={10} alignItems={'flex-start'}>
        <VStack
          width={'40%'}
          boxShadow={'lg'}
          rounded={'md'}
          alignItems={'center'}
          justifyContent={'center'}
          spacing={2}
        >
          <ContentList />
        </VStack>

        <VStack w={'50%'}>
          <Tabs w={'100%'}>
            <TabList>
              <Tab>Content Details</Tab>
              <Tab>Update Content</Tab>
              <Tab>Add Content</Tab>
              <Tab>Insert New Content</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <AboutContent />
              </TabPanel>
              <TabPanel>
                <UpdateContentForm />
              </TabPanel>
              <TabPanel>
              <AddContentForm />
              </TabPanel>
              <TabPanel>
                <InsertContentForm />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </HStack>
    </Box>
  );
};
