import React, { useContext, useEffect ,useState} from 'react';
import { Formik, Form, Field } from 'formik';
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Box,
  Textarea,
  VStack,
  Text,
  Link,
  HStack,
  Image,
} from '@chakra-ui/react';
import MentorContext from '../../context/MentorContext';
import { dayasirphoto, dayasirphotoPNG, demoOrginalSign, demoPngSign, dummyProfile, dummyProfile1 } from '../../assets/images';

const UpdateMentor = () => {
  const [imagePath,setImagePath]=useState({})
  const [signPath,setSignPath]=useState({})
  const {mentorData,updateMentor} =useContext(MentorContext);
  const [initialValues, setInitialValues] = useState({
    name: '',
    designation: '',
    about: '',
    exp: '',
  });
  const hendleSubmit =(values, actions) => {
    console.log(values);
    const formData=new FormData();
    formData.append('name',values.name);
    formData.append('designation',values.designation);
    formData.append('about',values.about);
    formData.append('exp',values.exp);
    formData.append('dp',imagePath);
    formData.append('sign',signPath);
    updateMentor(formData)
    actions.setSubmitting(false);
  }
  
  useEffect(()=>{
    if(mentorData) {
      setInitialValues({
        ...initialValues,
        name: mentorData.name,
        designation: mentorData.designation,
        exp:mentorData.exp,
        about:mentorData.about
      })
    }
  },[mentorData])
  return (
    <Box width={'100%'} p={2}>
      <Formik
          initialValues={initialValues}
          onSubmit={hendleSubmit}
        enableReinitialize={true}

      >
        {({ errors, touched, isSubmitting, setFieldValue }) => (
          <Form>
            <Field name="name">
              {({ field }) => (
                <FormControl isInvalid={errors.name && touched.name}>
                  <FormLabel htmlFor="name">Name</FormLabel>
                  <Input {...field} id="name" placeholder="Enter your name" />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="designation">
              {({ field }) => (
                <FormControl
                  isInvalid={errors.designation && touched.designation}
                >
                  <FormLabel htmlFor="designation">Designation</FormLabel>
                  <Input
                    {...field}
                    id="designation"
                    placeholder="Enter your designation"
                  />
                  <FormErrorMessage>{errors.designation}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="about">
              {({ field }) => (
                <FormControl isInvalid={errors.about && touched.about}>
                  <FormLabel htmlFor="about">About</FormLabel>
                  <Textarea
                    {...field}
                    id="about"
                    placeholder="Tell us about yourself"
                  />
                  <FormErrorMessage>{errors.about}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="exp">
              {({ field }) => (
                <FormControl isInvalid={errors.exp && touched.exp}>
                  <FormLabel htmlFor="exp">Experience</FormLabel>
                  <Input
                    {...field}
                    id="exp"
                    placeholder="Enter your experience"
                  />
                  <FormErrorMessage>{errors.exp}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <VStack  width={'100%'}>
            <Text fontWeight={700} fontSize={"larger"}>
              {' '}
              First change your photo to no-background photo then upload
            </Text>
            <Text>
              Follow this Link --{'>'}{' '}
              <Link
           color={'blue'}
           fontWeight={700}
           textDecor={'underline'}
                href="https://imagecropper.stepsoflearningprocess.com/"
                target="_blanck"
              >
                {' '}
                Image Cropper & Enhancer
              </Link>
            </Text>
            <HStack alignItems={'flex-start'} gap={10} width={'10rem'}>
              <Image src={dummyProfile}></Image>
              <Image src={dummyProfile1}></Image>
            </HStack>
          </VStack>

          <Field name="dp">
            {({ field }) => (
              <FormControl isInvalid={errors.dp && touched.dp}>
                <FormLabel htmlFor="dp">Profile Picture</FormLabel>
                <Input
                  type="file"
                  id="dp"
                  onChange={(event) => {
                    setImagePath(event.target.files[0]);
                  }}
                />
                <FormErrorMessage>{errors.dp}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <VStack  width={'100%'}>
            <Text fontWeight={700} fontSize={"larger"}>
              {' '}
              First change your normal signature to E-sign then upload
            </Text>
            <Text>
              Follow this Link --{'>'}{' '}
              <Link
           color={'blue'}
           fontWeight={700}
           textDecor={'underline'}
                href="https://imagecropper.stepsoflearningprocess.com/"
                target="_blanck"
              >
                {' '}
                Image Cropper & Enhancer
              </Link>
            </Text>
            <HStack alignItems={'flex-start'} gap={10} width={'18rem'}>
              <Image src={demoOrginalSign}></Image>
              <Image src={demoPngSign}></Image>
            </HStack>
          </VStack>
          <Field name="sign ">
            {({ field }) => (
              <FormControl isInvalid={errors.sign  && touched.sign }>
                <FormLabel htmlFor="sign ">E-signeture</FormLabel>
                <Input
                  type="file"
                  id="sign "
                  onChange={(event) => {
                    setSignPath(event.target.files[0]);
                  }}
                />
                <FormErrorMessage>{errors.sign }</FormErrorMessage>
              </FormControl>
            )}
          </Field>

            <Button
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              type="submit"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default UpdateMentor;
