import React from 'react';
import { Box,Text, VStack } from '@chakra-ui/react';
import { PieChart, Pie, Tooltip, Legend, Cell,ResponsiveContainer} from 'recharts';


const PieChartComponent = ({data,COLORS,text}) => {
  return (
    <Box maxW="500px" mx="auto">
    {text && <Text textAlign="center" fontSize="lg" fontWeight="bold" mb={4}>{text}</Text>}
    <ResponsiveContainer height={370} width={300}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          label
        >
          {data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
        </Pie>
        <Tooltip />
        <Legend layout="vertical" />
      </PieChart>
    </ResponsiveContainer>
  </Box>
  );
};

export default PieChartComponent;
