import React, { useState } from 'react';
import '../../../../allcss/rating.css';

export const Rating = ({
  selectedStars,
  setSelectedStars,
  interactive = true,
  fontSize = '20px',
  lineHeight = '15px',
  margin = 0,
}) => {
  // Function to handle star selection
  const handleStarClick = value => {
    if (interactive) {
      // console.log('selected stars: ', value);
      setSelectedStars(value);
    }
  };

  // console.log('selectedStars', selectedStars);
  // Helper function to generate star icons
  const renderStars = count => {
    const stars = [];
    for (let i = 0; i < count; i++) {
      stars.push(
        <span key={i} className="icon">
          ★
        </span>
      );
    }
    return stars;
  };

  return (
    <div
      style={{
        position: 'relative',
        width: 'fit-content',
        padding: '5px',
        margin: margin,
      }}
    >
      <form
        className="rating"
        style={{
          lineHeight: lineHeight,
          fontSize: fontSize,
        }}
      >
        {[1, 2, 3, 4, 5].map(value => (
          <label key={value}>
            <input
              type="radio"
              name="stars"
              value={value}
              checked={
                interactive ? value === selectedStars : value <= selectedStars
              }
              onChange={() => handleStarClick(value)}
            />

            {renderStars(value)}
          </label>
        ))}
      </form>

      {!interactive && (
        <div
          style={{
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            position: 'absolute',
            zIndex: 99999,
          }}
        ></div>
      )}
    </div>
  );
};
