import React, { useState, useContext, useEffect } from 'react';
import {
  Button,
  VStack,
  Heading,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Checkbox,
  HStack,
  Box,
  Text,
  Stack,
} from '@chakra-ui/react';
import EnvContext from '../../../../context/EnvContext';
import AssignmentContext from '../../../../context/AssignmentContext';
import ContentContext from '../../../../context/ContentContext';

export const QuizCard = ({ quiz, index }) => {
  const { backendUrl } = useContext(EnvContext);
  const [backGround, setBackGround] = useState(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const {
    selectedAnswers,
    setSelectedAnswers,
    submitAnswer,
    queResult,
    setQueResult,
    quesId,
    previousAttempts,
    prevAttempts,
  } = useContext(AssignmentContext);

  const { assign } = useContext(ContentContext);
  useEffect(() => {
    // Check if quesId is within an array
    if (Array.isArray(assign)) {
      const isQuesIdAnswered = assign.some(
        answer => answer.question._id === quesId
      );

      setIsAnswered(isQuesIdAnswered);
      if (isQuesIdAnswered) {
        const answers = assign
          .filter(answer => answer.question._id === quesId)
          .map(item => item.answer);
        setSelectedAnswers(answers.flat());
      } else {
        setSelectedAnswers([]);
      }
    }
  }, [quesId]);
  useEffect(() => {
    if (selectedAnswers) {
      console.log('selectedAnswers', selectedAnswers);
      console.log('quiz', quiz);
    }
  }, [selectedAnswers]);
  useEffect(() => {
    if (queResult) setBackGround();
  }, [queResult]);

  useEffect(() => {
    if (backGround) console.log('backGround', backGround);
  }, [backGround]);
  function trimAndReturnFileName(filePath) {
    // Find the index of the first hyphen
    const hyphenIndex = filePath.indexOf('-');

    // Extract the substring starting from the character after the hyphen
    const fileName = filePath.substring(hyphenIndex + 1);

    return fileName;
  }
  const handleCheckboxChange = value => {
    if (selectedAnswers.includes(value)) {
      setSelectedAnswers(selectedAnswers.filter(el => el !== value));
    } else {
      setSelectedAnswers([...selectedAnswers, value]);
    }
  };
  useEffect(() => {
    if (!prevAttempts || prevAttempts?.length === 0) return;
    setSelectedAnswers(prevAttempts[0]?.answer);
  }, [prevAttempts]);
  const handleRadioChange = value => {
    setSelectedAnswers([value]);
  };
  const handleSubmitAnswer = async () => {
    console.log('selectedAnswers', selectedAnswers);
    const formData = new FormData();
    formData.append('answer', selectedAnswers);
    const postData = {
      answer: selectedAnswers,
    };
    await submitAnswer(selectedAnswers);
    previousAttempts(quesId);
  };
  return (
    <HStack
      height={'100%'}
      spacing={4}
      alignItems="stretch"
      width={'100%'}
      flexDirection={{
        base: 'column',
        sm: 'column',
        lg: 'row',
      }}
    >
      <VStack
        maxH={'37em'}
        overflowY={'auto'}
        w={{
          base: '65%',
          md: '100%',
          lg: '40%',
        }}
        alignItems={'flex-start'}
      >
        <Text size="md">
          Q{index + 1}) {quiz.question.text}
        </Text>
        {quiz?.question?.image ? (
          <Box w={'100%'}>
            <img src={backendUrl + quiz.question.image}></img>
          </Box>
        ) : (
          ' '
        )}
      </VStack>

      <VStack
        w={{
          base: '75%',
          md: '100%',
          lg: '60%',
        }}
        bg={'gray.100'}
        height={'100%'}
        p={5}
      >
        <FormControl as="fieldset" maxH={'55vh'} overflowY={'scroll'}>
          <HStack justifyContent={'space-between'}>
            <FormLabel as="legend">
              Choose {quiz.questionType === 'multiple' ? 'Multiple' : 'One'}:
            </FormLabel>
            <Text fontWeight={600}>
              Attempt No:{' '}
              <strong>
                {prevAttempts?.length}/{quiz?.noOfAttempts}
              </strong>
            </Text>
          </HStack>
          <RadioGroup width={'100%'}>
            {quiz.questionType === 'multiple' ? (
              <>
                <VStack
                  spacing={2}
                  pl={10}
                  alignItems={'flex-start'}
                  bg={
                    prevAttempts?.length > 0
                      ? prevAttempts[0]?.isCorrectAnswer
                        ? 'green.100'
                        : 'red.100'
                      : 'none'
                  }
                >
                  {Array.isArray(quiz.options.text) &&
                    quiz.options.text.map((el, i) => (
                      <Checkbox
                        borderColor="blue.500"
                        key={el}
                        value={el}
                        isChecked={selectedAnswers.includes(el)}
                        onChange={() => handleCheckboxChange(el)}
                        // bg={!queResult.isCorrectAnswer || (queResult.isCorrectAnswer && selectedAnswers.includes(el)) ? 'black' : 'red'}
                      >
                        {el}
                      </Checkbox>
                    ))}
                </VStack>
                <HStack
                  spacing={2}
                  pl={10}
                  flexWrap={'wrap'}
                  bg={
                    prevAttempts?.length > 0
                      ? prevAttempts[0]?.isCorrectAnswer
                        ? 'green.100'
                        : 'red.100'
                      : 'none'
                  }
                >
                  {Array.isArray(quiz.options.image) &&
                    quiz.options.image.map((el, i) => (
                      <Checkbox
                        mt={2}
                        borderColor="blue.500"
                        key={el}
                        value={el}
                        isChecked={selectedAnswers.includes(
                          trimAndReturnFileName(el)
                        )}
                        onChange={() =>
                          handleCheckboxChange(trimAndReturnFileName(el))
                        }
                      >
                        <Box width={'100%'}>
                          <img src={backendUrl + el}></img>
                        </Box>
                      </Checkbox>
                    ))}
                </HStack>
              </>
            ) : (
              <>
                <HStack
                  spacing={2}
                  pl={10}
                  flexWrap={'wrap'}
                  bg={
                    prevAttempts?.length > 0
                      ? prevAttempts[0]?.isCorrectAnswer
                        ? 'green.100'
                        : 'red.100'
                      : 'none'
                  }
                >
                  {Array.isArray(quiz.options.image) &&
                    quiz.options.image.map((el, i) => (
                      <Radio
                        mt={2}
                        key={el}
                        value={el}
                        isChecked={selectedAnswers.includes(
                          trimAndReturnFileName(el)
                        )}
                        onChange={() =>
                          handleRadioChange(trimAndReturnFileName(el))
                        }
                        borderColor="blue.500"
                      >
                        <Box width={'100%'}>
                          <img
                            src={backendUrl + el}
                            style={{ maxWidth: '100%' }}
                          ></img>
                        </Box>
                      </Radio>
                    ))}
                </HStack>
                <VStack
                  spacing={2}
                  pl={10}
                  flexWrap={'wrap'}
                  alignContent={'flex-start'}
                  alignItems={'flex-start'}
                  bg={
                    prevAttempts?.length > 0
                      ? prevAttempts[0]?.isCorrectAnswer
                        ? 'green.100'
                        : 'red.100'
                      : 'none'
                  }
                >
                  <RadioGroup
                    onChange={handleRadioChange}
                    value={selectedAnswers[0]}
                  >
                    <Stack direction="column">
                      {' '}
                      {/* Adjust direction as needed */}
                      {Array.isArray(quiz.options.text) &&
                        quiz.options.text.map((el, i) => (
                          <Radio key={i} borderColor="blue.500" value={el}>
                            {el}
                          </Radio>
                        ))}
                    </Stack>
                  </RadioGroup>
                </VStack>
              </>
            )}
          </RadioGroup>
        </FormControl>
        <HStack justifyContent={'center'}>
          {quesId === prevAttempts[0]?.question ? (
            prevAttempts[0]?.isCorrectAnswer ? (
              <VStack alignItems={'flex-start'}>
                <p>Already answered and correct!</p>
                {prevAttempts[0]?.correctAnsDesc && (
                  <p>
                    <strong>Description:</strong>{' '}
                    {prevAttempts[0]?.correctAnsDesc}{' '}
                  </p>
                )}
              </VStack>
            ) : prevAttempts?.length === quiz?.noOfAttempts ? (
              <VStack alignItems={'flex-start'}>
                <p>" No Attempts Left "</p>
                {prevAttempts[0]?.correctAnsDesc && (
                  <p>
                    <strong>Description:</strong>{' '}
                    {prevAttempts[0]?.correctAnsDesc}{' '}
                  </p>
                )}
              </VStack>
            ) : (
              <Button
                onClick={() => {
                  handleSubmitAnswer();
                }}
                colorScheme="red"
                width={'20rem'}
              >
                Retry
              </Button>
            )
          ) : (
            <Button
              onClick={handleSubmitAnswer}
              colorScheme="blue"
              style={{ width: '20rem' }}
            >
              Submit Answer
            </Button>
          )}
          {/* {quesId === queResult?.answer?.question ? (
            prevAttempts[0]?.isCorrectAnswer ? (
              isAnswered ? (
                // If quesId is already answered, show the text
                <p>Already answered and correct!</p>
              ) : (
                // If quesId is not answered, show the button
                <Button
                  onClick={handleSubmitAnswer}
                  colorScheme="blue"
                  style={{ width: '20rem' }}
                >
                  Submit Answer
                </Button>
              )
            ) : (
              <Button
                onClick={() => {
                  setSelectedAnswers([]);
                  setQueResult({});
                }}
                colorScheme="red"
                width={'20rem'}
              >
                Retry
              </Button>
            )
          ) : isAnswered ? (
            // If quesId is already answered, show the text
            <p>Already answered the question correctly!</p>
          ) : (
            // If quesId is not answered, show the button
            <Button
              onClick={handleSubmitAnswer}
              colorScheme="blue"
              style={{ width: '20rem' }}
            >
              Submit Answer
            </Button>
          )} */}
        </HStack>
      </VStack>
    </HStack>
  );
};
