import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { Button } from 'react-scroll';
import SyllabusForm from '../forms/SyllabusForm';
import MentorForm from '../forms/MentorForm';
import EnvContext from '../../context/EnvContext';
import LeaderBoard from '../../pages/user/authentication/video/LeaderBoard';


export const LeaderBoardOpener = ({ children }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal
        isCentered
        isOpen={isOpen}
        size={'xl'}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>

          <ModalCloseButton />
          <ModalBody>
            <LeaderBoard />
          </ModalBody>


        </ModalContent>
      </Modal>
    </>
  );
};

