import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Stack,
  Input,
  List,
  ListItem,
  Button,
  Box,
  VStack,
  FormControl,
  FormLabel,
  IconButton,
  HStack,
  Text,
} from '@chakra-ui/react';
import React, { useContext, useState, useEffect } from 'react';
import CourseContext from '../../context/CourseListContext';
import axios from 'axios';
import EnvContext from '../../context/EnvContext';
import toast from 'react-hot-toast';
import { IoMdClose } from 'react-icons/io';
import CartContext from '../../context/CartContext';

function AddSubscription() {
  const { fetchData, courses } = useContext(CourseContext);
  const { getAllSubscription } = useContext(CartContext);
  const { backendUrl } = useContext(EnvContext);
  const [name, setName] = useState('');
  const [plans, setPlans] = useState([
    {
      duration: '',
      originalPrice: '',
      offerPrice: '',
      renewalPrice: '',
      bufferDays: '',
    },
  ]);

  const handleAddPlan = () => {
    if (plans.length >= 3) {
      alert('You cannot add more plans');
      return;
    }
    setPlans([
      ...plans,
      {
        duration: '',
        originalPrice: '',
        offerPrice: '',
        renewalPrice: '',
        bufferDays: '',
      },
    ]);
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (Array.isArray(courses)) {
      console.log('fetchDatacourses', courses);
    }
  }, [courses]);
  const handleDeletePlan = index => {
    const updatedPlans = [...plans];
    updatedPlans.splice(index, 1);
    setPlans(updatedPlans);
  };

  const handlePlanChange = (index, field, value) => {
    const updatedPlans = [...plans];
    updatedPlans[index][field] = value;
    setPlans(updatedPlans);
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const handleSelectItem = item => {
    // Check if the item is already selected
    const selectedIndex = selectedItems.findIndex(
      selectedItem => selectedItem._id === item._id
    );

    if (selectedIndex !== -1) {
      // If the item is already selected, remove it from the selected items
      const updatedSelectedItems = [...selectedItems];
      updatedSelectedItems.splice(selectedIndex, 1);
      setSelectedItems(updatedSelectedItems);
    } else {
      // If the item is not selected, add it to the selected items
      setSelectedItems([...selectedItems, item]);
    }
  };

  // Function to handle deletion of selected items
  const handleDelete = () => {
    setSelectedItems([]);
  };

  // Function to handle deletion of a specific selected item
  const handleDeleteItem = item => {
    const updatedSelectedItems = selectedItems.filter(
      selectedItem => selectedItem._id !== item._id
    );
    setSelectedItems(updatedSelectedItems);
  };

  const filteredData = courses.filter(item =>
    item.courseName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (selectedItems) {
      console.log('Selected items:', selectedItems);
    }
  }, [selectedItems]);

  const handleSave = async () => {
    const ids = selectedItems.map(item => item._id);
    const postData = {
      name: name,
      courses: ids,
      plan: plans,
    };
    console.log('name:', name, 'courses:', ids, 'plan:', plans);
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    try {
      loadingToast = toast.loading('Adding Subscription', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .post(`${backendUrl}/subscription`, postData, { headers: headers })
        .then(res => {
          console.log('handleSaveres', res);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          getAllSubscription();
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  return (
    <Box p={4} w="100%">
      <FormControl w="40%" mb={4}>
        <FormLabel>Subscription pack :</FormLabel>
        <Input
          type="text"
          placeholder="Enter pack name"
          onChange={e => setName(e.target.value)}
        />
      </FormControl>

      <Stack spacing={4}>
        <FormControl w="40%">
          <FormLabel>Add Course :</FormLabel>
          <Input
            placeholder="Search for a course..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </FormControl>
        <List overflowY="auto" maxH="18vh" w="40%">
          {filteredData.map(item => (
            <ListItem key={item._id}>
              <Button
                onClick={() => handleSelectItem(item)}
                bg={
                  selectedItems.some(
                    selectedItem => selectedItem._id === item._id
                  )
                    ? 'blue.100'
                    : 'inherit'
                }
              >
                {item.courseName}
              </Button>
            </ListItem>
          ))}
        </List>
        {selectedItems.length > 0 && (
          <HStack alignItems="flex-start">
            <VStack spacing={4} alignItems="flex-start">
              <FormLabel>Selected Courses :</FormLabel>
              <HStack flexWrap="wrap">
                {selectedItems.map(selectedItem => (
                  <HStack
                    key={selectedItem._id}
                    bg="gray.100"
                    p={2}
                    borderRadius="10px"
                    spacing={2}
                  >
                    <Text>{selectedItem.courseName}</Text>
                    <IconButton
                      icon={<IoMdClose />}
                      size="sm"
                      variant="ghost"
                      onClick={() => handleDeleteItem(selectedItem)}
                      aria-label="Remove course"
                    />
                  </HStack>
                ))}
              </HStack>
            </VStack>
            <Box>
              <Text
                onClick={handleDelete}
                color="red.500"
                textDecoration="underline"
                cursor="pointer"
              >
                Delete All
              </Text>
            </Box>
          </HStack>
        )}
      </Stack>

      <VStack alignItems="flex-start" p={3}>
        <Text textDecoration="underline">Plans for the course</Text>
        {plans.map((plan, index) => (
          <VStack key={index} spacing={4} alignItems="flex-start" w="100%">
            <FormControl w="100%">
              <FormLabel>Duration :</FormLabel>
              <Input
                type="number"
                value={plan.duration}
                onChange={e =>
                  handlePlanChange(index, 'duration', e.target.value)
                }
                placeholder="Duration"
              />
            </FormControl>
            <FormControl w="100%">
              <FormLabel>Original Price :</FormLabel>
              <Input
                type="number"
                value={plan.originalPrice}
                onChange={e =>
                  handlePlanChange(index, 'originalPrice', e.target.value)
                }
                placeholder="Original Price"
              />
            </FormControl>
            <FormControl w="100%">
              <FormLabel>Offer Price :</FormLabel>
              <Input
                type="number"
                value={plan.offerPrice}
                onChange={e =>
                  handlePlanChange(index, 'offerPrice', e.target.value)
                }
                placeholder="Offer Price"
              />
            </FormControl>
            <FormControl w="100%">
              <FormLabel>Renewal Price :</FormLabel>
              <Input
                type="number"
                value={plan.renewalPrice}
                onChange={e =>
                  handlePlanChange(index, 'renewalPrice', e.target.value)
                }
                placeholder="Renewal Price"
              />
            </FormControl>
            <FormControl w="100%">
              <FormLabel>Buffer Days :</FormLabel>
              <Input
                type="number"
                value={plan.bufferDays}
                onChange={e =>
                  handlePlanChange(index, 'bufferDays', e.target.value)
                }
                placeholder="Buffer Days"
              />
            </FormControl>
            <IconButton
              icon={<CloseIcon />}
              aria-label="Delete Plan"
              onClick={() => handleDeletePlan(index)}
              colorScheme="red"
              variant="ghost"
            />
          </VStack>
        ))}
        <Button
          onClick={handleAddPlan}
          leftIcon={<AddIcon />}
          colorScheme="blue"
          variant="solid"
        >
          Add Plan
        </Button>
        <Button onClick={handleSave} colorScheme="blue" variant="outline">
          Submit Plans
        </Button>
      </VStack>
    </Box>
  );
}

export default AddSubscription;
