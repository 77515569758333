import React from 'react';
import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const PaymentFailedPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      backgroundColor="red.100"
    >
      <VStack spacing={4} textAlign="center">
        <Heading as="h1" size="2xl" color="red.500">
          Payment Failed
        </Heading>
        <Text fontSize="xl" color="gray.700">
          Unfortunately, we were unable to process your payment.
        </Text>
        <Text fontSize="md" color="gray.500">
          Please try again or contact support if the problem persists.
        </Text>

        <Button colorScheme="gray" onClick={() => navigate('/')}>
          Go to Home
        </Button>
      </VStack>
    </Box>
  );
};

export default PaymentFailedPage;
