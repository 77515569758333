import React, { useContext, useEffect, useState } from 'react';
import ContentContext from '../../../context/ContentContext';
import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  Text,
  IconButton,
  Divider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import PlayVideo from '../../../components/modals/PlayVideo';
import EnvContext from '../../../context/EnvContext';
import { DeleteContent } from '../../../components/alert/DeleteContent';
import { NotesOpener } from '../../../components/modals/NotesOpener';
import { openDoc } from '../../../assets/images';
import { Link, useNavigate } from 'react-router-dom';

export const ContentList = () => {
  const navigate = useNavigate();
  const {
    fetchAllContent,
    contents,
    setToken,
    setContentId,
    contentId,
    fetchContent,
  } = useContext(ContentContext);
  const [index, setIndex] = useState(0);
  const { backendUrl } = useContext(EnvContext);
  useEffect(() => {
    if (contentId) fetchContent();
  }, [contentId]);
  useEffect(() => {
    if (contents) console.log('CONTENTS', contents);
  }, [contents]);
  return (
    <>
      {contents && contents.length > 0 ? (
        contents.map((content, i) => (
          <>
            <Box
              bg={content._id === contentId && 'blue.100'}
              p={1}
              w={'100%'}
              key={i}
            >
              <HStack
                key={i}
                onClick={() => {
                  setIndex(i);
                  setContentId(content._id);
                }}
                cursor={'pointer'}
                spacing={3}
                w={'100%'}
                justifyContent={'space-between'}
              >
                <Text fontWeight={500}>{content.sl_no}</Text>
                <HStack spacing={3} w={'100%'}>
                  {content.video ? (
                    <PlayVideo videoUrl={content.video}>
                      <video
                        style={{ width: '8rem', height: '4rem' }}
                        src={`${backendUrl}${content.video}`}
                        alt="banner"
                      />
                    </PlayVideo>
                  ) : (
                    <NotesOpener notes={content.docContent}>
                      <img
                        style={{ width: '8rem', height: '4rem' }}
                        src={openDoc}
                        alt="banner"
                      />
                    </NotesOpener>
                  )}

                  <Text>{content.title}</Text>
                </HStack>
                <DeleteContent contentId={content._id}>
                  <IconButton
                    icon={<DeleteIcon />}
                    aria-label="Delete"
                    colorScheme="red"
                    ml={2}
                  />
                </DeleteContent>
                <Button
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  colorScheme="orange"
                  onClick={() => {
                    setContentId(content._id);
                    navigate('/admin/manage_assignment');
                  }}
                >
                  <span>Manage</span>
                  <span>Assignment</span>
                </Button>
              </HStack>
            </Box>

            {i !== contents.length - 1 && <Divider orientation="horizontal" />}
          </>
        ))
      ) : (
        <p>Nothing to show here. No contents available</p>
      )}
    </>
  );
};
