import { createContext, useEffect, useState, useContext } from 'react';
import EnvContext from './EnvContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useToast } from '@chakra-ui/react';

const WishListContext = createContext();

export const WishListContextProvider = ({ children }) => {
  const { backendUrl } = useContext(EnvContext);
  const [wishListData, seWishListData] = useState([]);
  const userId = localStorage.getItem('UserId');
  const toast = useToast();

  useEffect(() => {
    if (wishListData) {
      console.log('wishListData', wishListData);
    }
  }, [wishListData]);

  const handelWishlist = async id => {
    console.log('add to wishList  api hit');
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let loadingToast;
    try {

      await axios
        .put(
          `${backendUrl}/user/wishlist/add_item?courseId=${id}`,
          null,

          { headers: headers }
        )
        .then(res => {
          console.log('res.dataWishList', res.data);
          getWishList(userId);

        });
    } catch (err) {
      console.error(err);

    }

  };

  const getWishList = async id => {
    console.log('adminGetAllWIshListId', id);
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let apiUrl = `${backendUrl}/user/wishlist?`;
    if (id) {
      apiUrl += `userId=${id}`;
    }
    let loadingToast;
    try {
      await axios
        .get(apiUrl, { headers: headers })
        .then(res => {
          console.log(res.data.wishlist);
          seWishListData(res.data.wishlist);

        })
    } catch (err) {
      console.error(err);
    }
  };

  const handleWishListlDelete = async id => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const deleteWishListPromise = axios.put(
      `${backendUrl}/user/wishlist/remove_item?courseId=${id}`,
      null,
      { headers: headers }
    );

    toast.promise(deleteWishListPromise, {
      loading: {
        title: 'Deleting wishlist...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Wishlist item deleted successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to delete wishlist item!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await deleteWishListPromise;
      console.log(res);
      getWishList();
      toast.success(res.data.message, {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handelAllWishListDelete = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const deleteWishlistPromise = axios.put(`${backendUrl}/user/wishlist/remove_all`, null, {
      headers: headers,
    });

    toast.promise(deleteWishlistPromise, {
      loading: {
        title: 'Deleting all wishlist...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'All wishlist items deleted successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to delete all wishlist items!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await deleteWishlistPromise;
      console.log(res);
      getWishList();
      toast.success(res.data.message, {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
    } catch (err) {
      console.error(err);
    }
  };


  return (
    <WishListContext.Provider
      value={{
        handelWishlist,
        getWishList,
        wishListData,
        handleWishListlDelete,
        handelAllWishListDelete,
      }}
    >
      {children}
    </WishListContext.Provider>
  );
};

export default WishListContext;
