import React,{useContext} from 'react'
import MentorContext from '../../../context/MentorContext';
import {
  HStack,
  VStack,
  Text,
  Image,
  Divider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { DeleteCourse } from '../../../components/alert/DeleteCourse';
import { DeleteIcon } from '@chakra-ui/icons';
import EnvContext from '../../../context/EnvContext';
import ProjectContext from '../../../context/ProjectContext';
import { DeleteProject } from '../../../components/alert/DeleteProject';


export const AllProjects = () => {
  const { backendUrl } = useContext(EnvContext);
  const {projects,setProjectId,projectId,getProject}=useContext(ProjectContext);

  return (
    <>
      {
        projects && projects.length>0 ?(
          projects.map((el,i)=>(
            <>
              <HStack
                p={1}
                justifyContent={'space-between'}
                key={i}
                onClick={() => {setProjectId(el._id);
                  getProject(el._id);
                  }}
                cursor={'pointer'}
                bg={el._id === projectId && 'blue.100'}
                w={'100%'}
              >
                <HStack>
                <p> {i+1}.</p>
                  <p>{el.name}</p>
                </HStack>
               <HStack spacing={3}>
               <DeleteProject projectId={el._id}  >
                  <IconButton
                    icon={<DeleteIcon />}
                    aria-label="Delete"
                    colorScheme="red"
                    ml={2}
                  />
                </DeleteProject>
                  
               </HStack>
              </HStack>
              {i !== projects.length - 1 && <Divider orientation="horizontal" />}
            </>
          ))
        ):(
          <p>Nothing to load</p>
        )
      }
    </>
  )
}
