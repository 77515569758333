import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  Image,
  VStack,
  Text,
  Link,
} from '@chakra-ui/react';
import React, { useRef, useState, useEffect, useContext } from 'react';
import { demoOrginalSign, demoPngSign } from '../../../assets/images';
import { useSiteManager } from '../../../context/SiteManagerContext';
import EnvContext from '../../../context/EnvContext';
function AddSiteManager() {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const { backendUrl } = useContext(EnvContext);

  const { handleAddSiteManager, data, updateSiteManager } = useSiteManager();

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleImageChange = event => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedImage = event.target.files[0];
      setImage(selectedImage);
      const url = URL.createObjectURL(selectedImage);
      setImageUrl(url);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    // console.log("name",name,"image",image);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('sign', image);
    if (data && data?.name) {
      updateSiteManager(formData);
    } else {
      handleAddSiteManager(formData).then(() => {
        setName('');
        setImage(null);
      });
    }
  };

  useEffect(() => {
    if (data?.name) {
      setName(data?.name);
    }
  }, [data]);
  return (
    <VStack width={'100%'} alignItems={'flex-start'}>
      <form onSubmit={handleSubmit}>
        <VStack alignItems={'flex-start'} gap={'1rem'}>
          <Box>
            <FormControl>
              <FormLabel htmlFor="name">Name:</FormLabel>
              <Input
                type="text"
                id="name"
                value={name}
                onChange={handleNameChange}
              />
            </FormControl>
          </Box>
          {data?.sign ? (
            <>
              <HStack alignItems={'flex-start'} gap={10} width={'18rem'}>
                <Image
                  src={backendUrl + data?.sign}
                  alt="old sign"
                  border={'2px solid'}
                ></Image>
                {imageUrl && (
                  <Image
                    src={imageUrl}
                    border={'2px solid'}
                    alt="Selected"
                  ></Image>
                )}
              </HStack>
              <Text>
                Follow this Link --{'>'}{' '}
                <Link
                  color={'blue'}
                  fontWeight={700}
                  textDecor={'underline'}
                  href="https://imagecropper.stepsoflearningprocess.com/"
                  target="_blanck"
                >
                  {' '}
                  Image Cropper & Enhancer
                </Link>
              </Text>
            </>
          ) : (
            <VStack width={'100%'} alignItems={'flex-start'}>
              <Text fontWeight={700} fontSize={'larger'}>
                {' '}
                First change your normal signature to E-sign then upload
              </Text>
              <Text>
                Follow this Link --{'>'}{' '}
                <Link
                  color={'blue'}
                  fontWeight={700}
                  textDecor={'underline'}
                  href="https://imagecropper.stepsoflearningprocess.com/"
                  target="_blanck"
                >
                  {' '}
                  Image Cropper & Enhancer
                </Link>
              </Text>
              {imageUrl ? (
                <Image
                  src={imageUrl}
                  border={'2px solid'}
                  width={'18rem'}
                  alt="Selected"
                ></Image>
              ) : (
                <HStack alignItems={'flex-start'} gap={10} width={'18rem'}>
                  <Image src={demoOrginalSign}></Image>
                  <Image src={demoPngSign}></Image>
                </HStack>
              )}
            </VStack>
          )}

          <Box>
            <FormControl>
              <FormLabel htmlFor="image">Image:</FormLabel>
              <Input
                type="file"
                id="image"
                accept="image/*"
                onChange={handleImageChange}
              />
            </FormControl>
          </Box>
          <Button colorScheme="teal" type="submit" variant="solid">
            Submit
          </Button>
        </VStack>
      </form>
    </VStack>
  );
}

export default AddSiteManager;
