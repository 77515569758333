import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import CourseContext from '../../context/CourseListContext';
import axios from 'axios';
import EnvContext from '../../context/EnvContext';
import toast from 'react-hot-toast';
import MentorContext from '../../context/MentorContext';

export const DeleteMentor = ({ children, mentorId }) => {
    const {fetchMentor}=useContext(MentorContext)
    const token = localStorage.getItem('token');
  const { backendUrl } = useContext(EnvContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  let loadingToast;
  const handelMentorDelete = async () => {
    console.log('mentorId', mentorId);
    try {
      loadingToast = toast.loading('Mentor is deleting', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      await axios
        .delete(`${backendUrl}/course/mentor?mentorId=${mentorId}`, {
          headers: headers,
        })
        .then(res => {
            console.log('res.data.data', res.data.data);
            toast.success(res.data.message, {
              position: 'top-right',
              style: {
                marginTop: '62px',
              },
            });
            fetchMentor()
          })
      } catch (err) {
        console.log("mentor error", err)
        if (err.response.data.message)
          toast.error(`${err.response.data.message}`, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
        else
          toast.error('Something went wrong', {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
      } finally {
        // Close the loading state
        if (loadingToast) {
          toast.dismiss(loadingToast);
        }
      }
  };

  return (
    <>
      <span onClick={onOpen}>{children}</span>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Content
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  onClose();
                  handelMentorDelete();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
