import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  useDisclosure,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  ModalHeader,
  ModalFooter,
  Flex,
  Box,
  Avatar,
  Text,
  Divider,
  Link
} from '@chakra-ui/react';
import { FiUser } from 'react-icons/fi';
import {  NavLink } from 'react-router-dom';
import { FiHome } from 'react-icons/fi';
import { UserUpdateProfile } from '../others/UserUpdateProfile';
import { MdAccountCircle } from "react-icons/md";
import { FaUnlockKeyhole } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import UserPassword from '../others/UserPassword';
import UserDelete from '../others/UserDelete';


export const UserSetting = ({ children }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();  
  const [account,setAccount]=useState(true);
  const [password,setPassword]=useState(false);
  const [remove,setRemove]=useState(false);
  const dp = localStorage.getItem('dp');
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const handelAccount =()=>{
  setAccount(true)
  setPassword(false)
  setRemove(false)

}
const handlePassword= ()=>{
  setPassword(true)
  setRemove(false)
  setAccount(false)
}
const handleDelete=()=>{
  setRemove(true)
  setPassword(false)
  setAccount(false)
}

  return (
    <>
      <span onClick={onOpen}>{children}</span>

      <Modal onClose={onClose} isOpen={isOpen} isCentered >
        <ModalOverlay />
        <ModalContent style={{ maxWidth: '60rem' }}>
          <ModalHeader>Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={'block'} style={{  maxHeight:"77vh" }}>
            <Flex overflow={'hidden'} style={{ flex: '1 1 0%' }}>
              <Flex
                display={{
                  md: 'flex',
                }}
                width={{
                  md: '200px',
                }}
                flexDirection={'column'}
                overflowY={'auto'}
                flexShrink={0}
                gap={2}
                paddingInlineStart={5}
                paddingInlineEnd={5}
              >
                <Flex textAlign={'center'} gap={2} mb={2} alignItems={'center'}>
                  <Avatar
                    size="sm"
                    name={localStorage.getItem('name')}
                    src={`${BACKEND_URL}${dp}`}
                  />
                  <Text overflow={'hidden'}>{localStorage.getItem('name')}</Text>
                </Flex>
                <Box minHeight={'50px'}  >
                  <Link
                  bg={account && 'cyan.100'}
                    to={'/dashboard'}
                    textDecoration={'none'}
                    className="dashboardNavlink"
                    style={{ fontSize: '1rem', fontWeight: 600 }}
                    onClick={handelAccount}
                  >
                    <MdAccountCircle size={20} style={{ marginRight: '10px' }} />
                  Account
                  </Link>
                </Box>
                <Box minHeight={'100px'} >
                  <Link
                  bg={password && 'cyan.100'}
                    to={'/dashboard'}
                    textDecoration={'none'}
                    className="dashboardNavlink"
                    style={{ fontSize: '1rem', fontWeight: 600 }}
                    onClick={handlePassword}
                  >
                    <FaUnlockKeyhole size={20}  style={{ marginRight: '10px' }} />
                  Password
                  </Link>
                </Box>
                <Box minHeight={'50px'}>
                  <Link
                  bg={remove &&  'red.100'}
                    to={'/dashboard'}
                    textDecoration={'none'}
                    className="dashboardNavlink"
                    style={{ fontSize: '1rem', fontWeight: 600 }}
                    onClick={handleDelete}
                  >
                    <MdDelete size={20} style={{ marginRight: '10px' }} />
                Delete
                  </Link>
                </Box>
              </Flex>

              <Divider
                orientation="vertical"
                borderColor={'gray'}
                h={'80vh'}
              />

              <Flex
                flexGrow={1}
                display={{ md: 'flex' }}
                overflowY={'auto'}
                ml={'10px'}
                px={'10px'}
              >
              {account &&   <UserUpdateProfile />}
              {password && <UserPassword/>}
              {remove && <UserDelete/>}
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            {/* <Button onClick={onClose}>Close</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
