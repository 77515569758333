import { Box, Divider, HStack, Stack, Text, VStack, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import CourseContext from '../../../context/CourseListContext';
import UpdateProject from '../../../components/forms/UpdateProject';
import AddProject from '../../../components/forms/AddProject';
import MentorContext from '../../../context/MentorContext';
import { AllProjects } from './AllProjects';
import ProjectContext from '../../../context/ProjectContext';
import ProjectDetails from './ProjectDetails';
import { useNavigate } from 'react-router-dom';

export const ManageProjects = () => {
  const { courseId } = useContext(CourseContext);
  const { fetchAllProjects, setToken } = useContext(ProjectContext);
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (courseId) {
      console.log('addcourseIdd', courseId);
    }
    else {
      console.log("courseid not found")
    }
  }, [courseId]);
  useEffect(() => {
    if (token) {
      setToken(token);
      fetchAllProjects();
    }
  }, [token]);
  const role = localStorage.getItem('role');

  const navigate = useNavigate();

  useEffect(() => {
    if (role !== "admin") navigate('/dashboard');
  }, [role]);

  return (
    <Box px={'5rem'} >
      <Stack>
        <HStack w={"100%"} h={'100vh'} alignItems={'flex-start'}>
          <VStack w={"40%"} alignItems={'flex-start'}>
            <AllProjects />
          </VStack>
          <Divider orientation="vertical" />
          <VStack w={'60%'} alignItems={'flex-start'}>
            <Tabs w={'100%'}>
              <TabList>
                <Tab>Add Project</Tab>
                <Tab>Update Project</Tab>
                <Tab>Project Details</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <AddProject />
                </TabPanel>
                <TabPanel>
                  <UpdateProject />
                </TabPanel>
                <TabPanel>
                  <ProjectDetails />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </HStack>
      </Stack>
    </Box>
  );
};
