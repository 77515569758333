import { HamburgerIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Heading } from '@chakra-ui/react';
import React from 'react';
import { AdminSidebar } from '../drawer/AdminSidebar';

export const AdminSubHeader = ({heading}) => {
  return (
    <>
      <HStack height={'2rem'} mx={'20%'} marginBottom={5}>
        <AdminSidebar>
          <HamburgerIcon boxSize={6} cursor={'pointer'} />
        </AdminSidebar>
        <Flex width={'100%'} justifyContent={'center'}>
          <Heading>{heading}</Heading>
        </Flex>
      </HStack>
    </>
  );
};
