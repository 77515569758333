import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Flex,
  Heading,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { HiTrophy } from 'react-icons/hi2';
import toast from 'react-hot-toast';
import EnvContext from '../../../../context/EnvContext';
import axios from 'axios';
import CourseContext from '../../../../context/CourseListContext';
import gold_medal from '../../../../assets/images/gold_medal.png';
import silver_medal from '../../../../assets/images/silver_medal.png';
import bronze_medal from '../../../../assets/images/bronze_medal.png';
import { useContent } from '../../../../context/ContentContext';

const LeaderBoard = () => {
  const { courseId } = useContext(CourseContext);
  const { backendUrl } = useContext(EnvContext);
  const { leaderBoard, fetchLeaderBoard, rank } = useContent();
  const token = localStorage.getItem('token');
  // console.log('leaderBoard', leaderBoard);

  useEffect(() => {
    fetchLeaderBoard();
  }, [backendUrl, courseId, token]);

  // Function to determine medal image based on rank
  const getMedalImage = rank => {
    switch (rank) {
      case 1:
        return gold_medal;
      case 2:
        return silver_medal;
      case 3:
        return bronze_medal;
      default:
        return null; // Return null for ranks beyond 1st, 2nd, 3rd
    }
  };

  // Convert milliseconds to years, months, days, hours, minutes, and seconds
  function convertMillisecondsToDuration(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);
    const totalDays = Math.floor(totalHours / 24);
    const totalYears = Math.floor(totalDays / 365);
    const totalMonths = Math.floor((totalDays % 365) / 30); // Assuming a month is 30 days for simplicity

    const years = totalYears;
    const months = totalMonths;
    const days = totalDays % 30;
    const hours = totalHours % 24;
    const minutes = totalMinutes % 60;
    const seconds = totalSeconds % 60;
    const millisecondsRemaining = milliseconds % 1000;

    // Constructing the duration string
    let durationString = '';
    if (years > 0) {
      durationString += `${years} year${years !== 1 ? 's' : ''}, `;
    }
    if (months > 0) {
      durationString += `${months} month${months !== 1 ? 's' : ''}, `;
    }
    if (days > 0) {
      durationString += `${days} day${days !== 1 ? 's' : ''}, `;
    }
    if (hours > 0) {
      durationString += `${hours} hour${hours !== 1 ? 's' : ''}, `;
    }
    if (minutes > 0) {
      durationString += `${minutes} minute${minutes !== 1 ? 's' : ''}, `;
    }
    if (seconds > 0) {
      durationString += `${seconds} second${seconds !== 1 ? 's' : ''}, `;
    }
    if (millisecondsRemaining > 0) {
      durationString += `${millisecondsRemaining} millisecond${
        millisecondsRemaining !== 1 ? 's' : ''
      }`;
    }

    // Remove trailing comma and space if present
    durationString = durationString.trim().replace(/,\s*$/, '');

    return durationString;
  }

  const renderTooltipContent = score => (
    <div>
      {score && (
        <>
          <p>Candidate Score for:</p>
          <hr />
          <ul>
            <li>
              Watching Contents: (
              {score?.contentScore
                ? Number(score.contentScore.toFixed(2))
                : '0'}{' '}
              / 50)%
            </li>
            <li>
              Submitting Correct Answers: (
              {score?.assignmentScore
                ? Number(score.assignmentScore.toFixed(2))
                : '0'}{' '}
              / 10)%
            </li>

            <li>
              Submitting Projects: (
              {score?.projectScore
                ? Number(score.projectScore.toFixed(2))
                : '0'}{' '}
              / 40)%
            </li>

            <li>
              Total project Completion Time:{' '}
              {score.projectCompletionTime === 0
                ? 0
                : convertMillisecondsToDuration(score.projectCompletionTime)}
            </li>
          </ul>
        </>
      )}
    </div>
  );

  return (
    <Flex
      width={'100%'}
      height={'100%'}
      overflowY={'scroll'}
      direction={'column'}
      justifyItems={'center'}
      style={{
        boxShadow:
          '-2px 0px 6px rgba(0, 0, 0, 0.04), -2px 0px 15px rgba(0, 0, 0, 0.04)',
        borderRadius: '15px',
        gap: '10px',
      }}
    >
      <div
        style={{
          position: 'sticky',
          top: 0,
          background: 'white',
          zIndex: '999',
          padding: '15px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Flex
          gap={3}
          alignItems={'center'}
          justifyContent={'center'}
          style={{ position: 'relative' }}
          px={4}
        >
          <Heading as="h4" size="md" align={'center'}>
            Leader Board
          </Heading>
          <HiTrophy />
          {localStorage.getItem('role') !== 'admin' && (
            <Flex
              style={{
                position: 'absolute',
                right: '10px',
                minWidth: '200px',
                background: 'pink',
              }}
              justifyContent={'center'}
              px={5}
              py={1}
              borderRadius={20}
              gap={2}
            >
              <b>Your Rank: </b> {rank}
            </Flex>
          )}
        </Flex>
        <hr style={{ border: '1px solid gray' }} />
      </div>
      <Table variant="simple">
        <Thead
          style={{
            position: 'sticky',
            top: '60px',
            zIndex: 1000,
            background: 'white',
          }}
        >
          <Tr>
            <Th width="20%">Rank</Th>
            <Th width="50%">Student</Th>
            <Th width="30%">Score</Th>
          </Tr>
        </Thead>
        <Tbody>
          {leaderBoard.slice(0, 10).map((scoreCard, index) => (
            <Tr key={index}>
              <Td width="20%">
                {/* Render medal image based on rank */}
                {index + 1 === 1 && (
                  <div style={{ position: 'relative' }}>
                    <Image height={12} src={gold_medal} alt="Gold Medal" />
                    <div
                      style={{
                        position: 'absolute',
                        left: '8px',
                        bottom: '5px',
                        fontWeight: '700',
                        fontSize: '20px',
                        textShadow:
                          '0 0 5px white, 0 0 10px white, 0 0 30px white, 0 0 40px #F6E59F, 0 0 50px #F6E59F, 0 0 60px #F6E59F, 0 0 70px #F6E59F',
                      }}
                    >
                      {index + 1}
                    </div>
                  </div>
                )}
                {index + 1 === 2 && (
                  <div style={{ position: 'relative' }}>
                    <Image height={12} src={silver_medal} alt="Silver Medal" />
                    <div
                      style={{
                        position: 'absolute',
                        left: '8px',
                        bottom: '5px',
                        fontWeight: '700',
                        fontSize: '20px',
                        textShadow:
                          '0 0 5px white, 0 0 10px white, 0 0 30px white, 0 0 40px #D2D0D1, 0 0 50px #D2D0D1, 0 0 60px #D2D0D1, 0 0 70px #D2D0D1',
                      }}
                    >
                      {index + 1}
                    </div>
                  </div>
                )}
                {index + 1 === 3 && (
                  <div style={{ position: 'relative' }}>
                    <Image height={12} src={bronze_medal} alt="Bronze Medal" />
                    <div
                      style={{
                        position: 'absolute',
                        left: '8px',
                        bottom: '5px',
                        fontWeight: '700',
                        fontSize: '20px',
                        textShadow:
                          '0 0 5px white, 0 0 10px white, 0 0 30px white, 0 0 40px #EACAB4, 0 0 50px #EACAB4, 0 0 60px #EACAB4, 0 0 70px #EACAB4',
                      }}
                    >
                      {index + 1}
                    </div>
                  </div>
                )}
                {index + 1 > 3 && (
                  <Text
                    style={{
                      fontWeight: '700',
                      fontSize: '20px',
                      paddingLeft: '8px',
                    }}
                  >
                    {index + 1}
                  </Text>
                )}
              </Td>
              <Td width="50%">
                <Flex alignItems="center">
                  <Avatar
                    size="md"
                    name={scoreCard.user.name}
                    src={`${backendUrl}${scoreCard.user.dp}`}
                  />
                  <Flex direction="column" ml={2}>
                    <Text>{scoreCard.user.name}</Text>
                  </Flex>
                </Flex>
              </Td>
              <Td width="30%">
                <Tooltip
                  hasArrow
                  label={
                    scoreCard ? renderTooltipContent(scoreCard.score) : null
                  }
                  placement="right"
                >
                  {scoreCard && scoreCard?.score && scoreCard?.score?.totalScore
                    ? `${Number(scoreCard.score.totalScore.toFixed(2))} %`
                    : '0 %'}
                </Tooltip>
              </Td>
            </Tr>
          ))}

          {/* Render user's scorecard if rank is beyond the top 10 */}
          {rank > 10 && (
            <Tr key={rank - 1}>
              <Td width="20%">{rank}</Td>
              <Td width="50%">
                <Flex alignItems="center">
                  <Avatar
                    size="md"
                    name={leaderBoard[rank - 1]?.user.name}
                    src={`${backendUrl}${leaderBoard[rank - 1]?.user.dp}`}
                  />
                  <Flex direction="column" ml={2}>
                    <Text>{leaderBoard[rank - 1]?.user.name}</Text>
                  </Flex>
                </Flex>
              </Td>
              <Td width="30%">
                <Tooltip
                  hasArrow
                  label={renderTooltipContent(leaderBoard[rank - 1]?.score)}
                  placement="right"
                >
                  {`${Number(
                    leaderBoard[rank - 1]?.score.totalScore.toFixed(2)
                  )} % `}
                </Tooltip>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Flex>
  );
};
export default LeaderBoard;
