import React, { useContext, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Checkbox,
  Button,
  Stack,
  IconButton,
  InputGroup,
  InputRightElement,
  HStack,
  VStack,
  CircularProgress,
  CircularProgressLabel,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import EnvContext from '../../context/EnvContext';
import CourseContext from '../../context/CourseListContext';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import ContentContext from '../../context/ContentContext';

const validationSchema = Yup.object().shape({
  video: Yup.string().required('Video is required'),
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  notes: Yup.string().required('Notes are required'),
  sl_no: Yup.number().required('Serial Number is required'),
});

function InsertContentForm() {
  const [isDoc, setIsDoc] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoFile, setVideoFile] = useState(null);
  const [notesFile, setNotesFile] = useState(null);
  const [docFile, setDocFile] = useState(null);
  const { backendUrl } = useContext(EnvContext);
  const { courseId } = useContext(CourseContext);
  const {fetchAllContent}=useContext(ContentContext);
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
  };
const resetFormData=()=>{
  setUploadProgress(0);
  setVideoFile(null);
  setNotesFile(null);
  setDocFile(null);
}
  const handleSubmit = async (values, actions) => {
    // Your form submission logic here
    console.log(values);
    console.log('videoFile', videoFile);
    // console.log('notesFile', notesFile);
    // console.log('docFile',docFile)
    actions.setSubmitting(false);
    const formData = new FormData();
    formData.append('prevContSl', values.prevContSl);
    formData.append('postContSl', values.afterContSl);
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('video', videoFile);
    formData.append('notes', notesFile);
    formData.append('isDoc', isDoc);
    formData.append('docContent', docFile);
    for (const pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    let loadingToast;
    try {
      loadingToast = toast.loading('Adding Content...', {
        position: 'top-right',
        style: {
          marginTop: '62px',
        },
      });
      
      await axios
        .post(
          `${backendUrl}/course/content/insert?courseId=${courseId}`,
          formData,
          { headers: headers , onUploadProgress: (progressEvent) => {
            //  Calculate the upload percentage
            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentage);
          }})
        .then(res => {
          console.log(res);
          toast.success(res.data.message, {
            position: 'top-right',
            style: {
              marginTop: '62px',
            },
          });
          fetchAllContent();
          actions.resetForm();
          resetFormData()
        })
        
    } catch (err) {
      resetFormData()

      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  const handleInputChange = value => {
    if (value === 'yes') setIsDoc(true);
    else setIsDoc(false);
  };
  return (
    <Formik
      initialValues={{
        video: '',
        title: '',
        description: '',
        notes: '',
        afterContSl: '',
        prevContSl: '',
        docContent: '',
      }}
      // validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Field name="prevContSl">
            {({ field, form }) => (
              <FormControl
                isRequired
                isInvalid={form.errors.prevContSl && form.touched.prevContSl}
              >
                <FormLabel>Previous Serial Number</FormLabel>
                <Input
                  {...field}
                  type="number"
                  placeholder=" Previous Serial Number"
                />
                <FormErrorMessage>{form.errors.prevContSl}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="afterContSl">
            {({ field, form }) => (
              <FormControl
                isRequired
                isInvalid={form.errors.afterContSl && form.touched.afterContSl}
              >
                <FormLabel> After Serial Number</FormLabel>
                <Input
                  {...field}
                  type="number"
                  placeholder="After Serial Number"
                />
                <FormErrorMessage>{form.errors.afterContSl}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="title">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.title && form.touched.title}
                >
                  <FormLabel>Title</FormLabel>
                  <Input {...field} placeholder="Title" />
                  <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="description">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.description && form.touched.description
                  }
                >
                  <FormLabel>Description</FormLabel>
                  <Input {...field} placeholder="Description" />
                  <FormErrorMessage>{form.errors.description}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="notes">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.notes && form.touched.notes}
                >
                  <FormLabel>Notes (PDF)</FormLabel>
                  {/* Your file upload logic here */}
                  <input
                    type="file"
                    onChange={e => setNotesFile(e.target.files[0])}
                  />
                  <FormErrorMessage>{form.errors.notes}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <HStack justifyContent={'space-evenly'} width={'100%'} my={'12px'}>
              <VStack width={'100%'}>
                <HStack spacing={4} width={'100%'}>
                  <p>Do you want to upload a Document?</p>
                  <RadioGroup
                    onChange={handleInputChange}
                    value={isDoc ? 'yes' : 'no'}
                  >
                    <Stack direction="row">
                      <Radio value="yes">Yes</Radio>
                      <Radio value="no">No</Radio>
                    </Stack>
                  </RadioGroup>
                </HStack>
                <HStack width={'100%'}>
                  {isDoc ? (
                    <Field name="docContent">
                      {({ field, form }) => (
                        <FormControl
                          isRequired
                          isInvalid={
                            form.errors.docContent && form.touched.docContent
                          }
                        >
                          <FormLabel>Upload PDF</FormLabel>
                          {/* Your file upload logic here */}
                          <input
                            type="file"
                            onChange={e => setDocFile(e.target.files[0])}
                          />
                          <FormErrorMessage>
                            {form.errors.docContent}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  ) : (
                    <FormControl>
                      <HStack>
                        <FormLabel>Upload Video</FormLabel>
                        <input
                          type="file"
                          accept="video/mp4, video/avi, video/mov"
                          onChange={e => setVideoFile(e.target.files[0])}
                        />
                      </HStack>
                    </FormControl>
                  )}
                  <CircularProgress value={uploadProgress} color="green.400">
                    <CircularProgressLabel>{`${Math.round(
                      uploadProgress
                    )}%`}</CircularProgressLabel>
                  </CircularProgress>
                </HStack>
              </VStack>
            </HStack>

          <Stack spacing="6">
            <Button type="submit" isLoading={isSubmitting}>
              Submit
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

export default InsertContentForm;
