import axios from 'axios';
import { createContext, useEffect, useState, useContext } from 'react';
import EnvContext from './EnvContext';
import ContentContext from './ContentContext';
import CourseContext from './CourseListContext';
import { sortTransactionsByDate } from '../Utils/function';
import { useToast } from '@chakra-ui/react';

const AssignmentContext = createContext();

export const AssignmentContextProvider = ({ children }) => {
  const toast = useToast();
  const [currentIndex, setCurrentIndex] = useState(0);
  const { backendUrl } = useContext(EnvContext);
  const { contentId, fetchUserScoreboard, fetchLeaderBoard } =
    useContext(ContentContext);
  const [assignments, setAssignments] = useState([]);
  const { courseId, scorecard } = useContext(CourseContext);
  const [prevAnsw, setPrevAnsw] = useState([]);
  const [qa, setQa] = useState({});
  const [answers, setAnswers] = useState(null);
  const [quesId, setQuesId] = useState(null);
  const [token, setToken] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const userId = localStorage.getItem('userId');
  const [queResult, setQueResult] = useState({});
  const [prevAttempts, setPrevAttempts] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    if (quesId) {
      console.log('quesId', quesId);
      console.log('adsuoad', queResult?.answer?.question);
      previousAttempts(quesId);
    }
  }, [quesId]);

  // function filterByQuestionId(dataArray, questionIds) {
  //   return dataArray.filter(obj => questionIds.includes(obj.question._id));
  // }

  // useEffect(() => {
  //   if (scorecard && Array.isArray(scorecard.assignment)) {
  //     console.log("previous assignemnt",scorecard.assignment)
  //     const filteredData = filterByQuestionId(scorecard.assignment, quesId);
  //     console.log('filteredData of previous answers', filteredData);
  //   }
  // }, [scorecard, quesId]);
  const fetchAllAssignments = async value => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      // 
      await axios
        .get(`${backendUrl}/course/assignment?courseContentId=${value}`, {
          headers: headers,
        })
        .then(res => {
          console.log('res.data.data', res.data.questions);
          setAssignments(res.data.questions);

        });
    } catch (err) {
      console.log(err.response.data.message);
    }
  };

  const getQnA = async value => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {

      await axios
        .get(`${backendUrl}/course/assignment/question?questionId=${value}`, {
          headers: headers,
        })
        .then(res => {
          console.log('res.data.data', res.data);
          setQa(res.data.data);

        });
    } catch (err) {
      console.log(err.response.data.message);
    }
  };

  const addQnA = async value => {
    console.log('add qn a  api hit');
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };

    const addQnAPromise = axios.post(
      `${backendUrl}/course/assignment/question?courseContentId=${contentId}`,
      value,
      { headers: headers }
    );

    toast.promise(addQnAPromise, {
      loading: {
        title: 'Adding New Question...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Question added successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to add question!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const res = await addQnAPromise;
      console.log(res.data);
      fetchAllAssignments(contentId);
      setAnswers(null);
      setSelectedItems(null);
    } catch (err) {
      console.log(err);
      // The error toast is already handled by toast.promise
    }
  };


  const getAllQuestions = async courseContentId => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(
          `${backendUrl}/course/assignment?courseContentId=${courseContentId}`,
          {
            headers: headers,
          }
        )
        .then(res => {
          console.log('getAllQuestions fetched', res.data);
          setQuestions(res.data.questions);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const submitAnswer = async answer => {
    console.log('add qn a api hit');
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const submitAnswerPromise = axios.post(
      `${backendUrl}/user/rs/assignment_submission?questionId=${quesId}`,
      { answer }, // Pass the answer array directly here
      { headers }
    );

    toast.promise(submitAnswerPromise, {
      loading: {
        title: 'Checking answer...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      success: {
        title: 'Answer submitted successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to submit the answer!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '5rem',
        },
        duration: 1000,
      },
    });

    try {
      const response = await submitAnswerPromise;
      const data = response.data.data;
      console.log('submitAnswer===================>', data);
      setQueResult(data);
      fetchUserScoreboard(userId, courseId);
      fetchLeaderBoard();
      previousAttempts(quesId);
    } catch (error) {
      console.error(error);
      // The error toast is already handled by toast.promise
    }
  };


  const previousAttempts = async questionId => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(
          `${backendUrl}/user/rs/assignment_submission/pre_attempts?questionId=${questionId}`,
          {
            headers: headers,
          }
        )
        .then(res => {
          console.log('previousAttempts fetched', res.data.data.prevAttempts);
          setPrevAttempts(sortTransactionsByDate(res.data.data.prevAttempts));
        });
    } catch (err) {
      console.log(err);
    }
  };

  const resetAssignmentAnswers = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .delete(
          `${backendUrl}/user/rs/assignment_submission?courseId=${courseId}`,
          {
            headers: headers,
          }
        )
        .then(res => {
          console.log('res delete', res);
          fetchUserScoreboard(userId, courseId);
          fetchLeaderBoard();
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AssignmentContext.Provider
      value={{
        fetchAllAssignments,
        addQnA,
        setToken,
        assignments,
        setQuesId,
        quesId,
        getQnA,
        qa,
        setAnswers,
        answers,
        getAllQuestions,
        questions,
        submitAnswer,
        setSelectedAnswers,
        selectedAnswers,
        prevAnsw,
        queResult,
        setQueResult,
        prevAttempts,
        resetAssignmentAnswers,
        previousAttempts,
        setSelectedItems,
        selectedItems,
        setPrevAttempts,
        setCurrentIndex,
        currentIndex,
      }}
    >
      {children}
    </AssignmentContext.Provider>
  );
};

export default AssignmentContext;
