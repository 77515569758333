import React from "react";
import {Navigate } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const tokenData = localStorage.getItem("token");

  return tokenData ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
